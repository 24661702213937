import { CCallout, CFormLabel, CButton } from "@coreui/react";
import React from "react";
import { useEffect } from "react";
import { api } from "src/configs";


const SystemReports = () => {
    const [reports, setReports] = React.useState([]);
    const [desciption, setDesciption] = React.useState("");

    useEffect(() => {
        // fetch emp reports
        fetch (api + 'getEmpReports?emp_id=' + localStorage.getItem("f_emp_id"), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

            },

        })
        .then(res => res.json())
        .then(data => {
            console.log(data)
            setReports(data)
        }
        )
        .catch(err => console.log(err))

        

    }, []);

    const addReport = () => {

        if (desciption.length < 10) {
            alert("please describe your problem or suggestions in more detail")
            return
        }


        // add report post request to api addReport
        fetch (api + 'addReport', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

            },
            body: JSON.stringify({
                emp_id: localStorage.getItem("f_emp_id"),
                description: desciption
            })

        })
        .then(res => res.json())
        .then(data => {
            if(data.res == "Report Added"){
                alert("report added successfully")
                window.location.reload()
            }
        }
        )
        .catch(err => console.log(err))



    }

    const deleteReport = (id) => {
        // delete report delete request to api deleteReport
        fetch (api + 'deleteReport', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',

            },
            body: JSON.stringify({
                id: id
            })

        })
        .then(res => res.json())
        .then(data => {
            if(data.res == "Report Deleted"){
                alert("report deleted successfully")
                window.location.reload()
            }
        }
        )
    }

    return (
        <div>
            <h1>System Reports</h1><br/>
            <CCallout color="primary"> 
                Any bugs or problems you face using the system can be reported here. Please do not hesitate to report any problems so that i can fixed them as soon as possible.
            </CCallout>
            <CFormLabel>Report</CFormLabel>
            <textarea className="form-control" placeholder="describe your problem or suggestions here" id="exampleFormControlTextarea1" rows="3" onChange={(e) =>{setDesciption(e.target.value)}}></textarea>
            <br/>
            <button className="btn btn-primary" onClick={addReport}>Submit</button><br/><br/><br/><br/>


            <h4>My Reports</h4><br/>
            {
                reports.length == 0 ? <h6>No Reports</h6> : reports.map((report) => {
                    return (
                        <div key={report.id}>
                            <CCallout color="primary">
                                <small className="text-muted">{report.created_at.substring(0,10)} {report.time}</small>
                                {/* <br /> */}
                                {/* <strong className="h5">{report.title}</strong>
                                <br /> */}
                                <p className="text">{report.description} <CButton color="danger" onClick={() => {deleteReport(report.id)}} size="sm" style={{float: 'right'}}>Delete</CButton></p>
                            </CCallout>

                        </div>
                    )
                }
                )
            }

        </div>
    );
}

export default SystemReports;