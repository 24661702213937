import React, { useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable,
  CFormInput,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CListGroup,
  CListGroupItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'
import { useNavigate } from 'react-router-dom'

const columns = [
  {
    key: 'id',
    label: 'id',
    _props: { scope: 'col' },
  },
  {
    key: 'name',
    label: 'Name',
    _props: { scope: 'col' },
  },
  {
    key: 'description',
    label: 'Description',
    _props: { scope: 'col' },
  },
  {
    key: 'Client_name',
    label: 'Client Name',
    _props: { scope: 'col' },
  },
  {
    key: 'date',
    label: 'Date',
    _props: { scope: 'col' },
  },
  {
    key: 'time',
    label: 'Time',
    _props: { scope: 'col' },
  },
  {
    key: 'Action',
    label: 'Action',
    _props: { scope: 'col' },
  },
  // {
  //   key: 'Action2',
  //   label: 'Action ',
  //   _props: { scope: 'col' },
  // },
]

const Reminders = () => {
  const [items, setItems] = useState([])
  const [filter, setfilter] = useState('')

  const [visible, setVisible] = useState(false)
  const [selectedEmps, setSelectedEmps] = useState([])
  const [emps, setEmps] = useState([])

  const navigate = useNavigate()
  

  useEffect(() => {
    const fetchEmployees = async () => {
      const response = await fetch(api + 'getReminders')
      const data = await response.json()
      console.log(data)
      setItems(data)
    }

    fetch(api + 'getEmployees', {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
      },
  }).then((res) => {
      if(res.status === 200){
          const names = []
          res.json().then((data) => {
              data.forEach(element => {
                  console.log(element)
                  names.push(element.full_name)
              });
              // setEmpNames(names)
              setEmps(data)
          })
      }
  })

    fetchEmployees()
  }, [])    

  const create_table = (array) => {
    var items = []
    array.forEach((element) => {
      if (element['full_name'] === '-') {
        return;
      }

      items.push({
        id: element['id'],
        name: element['name'],
        description: element['description'] + " ",
        Client_name: element['full_name'] ? element['full_name'] : '-',
        date: element['date'].substring(0, 10),
        time: element['time'],
        Action: (
          <CButton
            color="dark"
            onClick={() => {
              localStorage.setItem('f_r_id', element['id'])
              navigate('/ReminderProfile', { state: { id: element['id'] } })
            }}
            size='sm'
          >
            Reminder
          </CButton>
        ),
        Action2: (
          <CButton
            color="dark"
            onClick={() => {
              setVisible(true)
            }}
            size='sm'
          >
           Edit Participants
          </CButton>
        ),
      })
    })
    return items
  }
  var itemlist = create_table(items)
  return (
    <CRow>
      <CCol xs={12}>
        <h1>Reminders</h1>
        <br />
        <CFormInput
          type="text"
          placeholder={'Filter'}
          aria-label="default input example"
          onChange={(event) => {
            setfilter(event.target.value)
          }}
        />
        <br />
        <br />
        <div className="table table-responsive">
          <CTable className="table table-striped table-hover" columns={columns} items={itemlist} />
        </div>
      </CCol>
    </CRow>
  )
}

export default Reminders
