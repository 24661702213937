import React, { useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CFormTextarea,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CListGroup,
  CListGroupItem,
  CFormLabel,
} from '@coreui/react'
import { api } from 'src/configs'
import moment from 'moment'
import CIcon from '@coreui/icons-react'
import { cilBell, cilPencil } from '@coreui/icons';

const LeadProfile = () => {
  const [info, setInfo] = useState([
    {
      name: '',
      description: '',
      client_id: '',
      client_name: '',
      date: '',
      time: '',
    },
  ])
  const currentDate = moment()
  const [visible, setVisible] = useState(false)
  const [emps, setEmps] = useState([])
  const [items, setItems] = useState([])
  const [usrToPass, setUsrToPass] = useState(' ')

  const [editMode, setEditMode] = useState(false);
  const [updatedName, setUpdatedName] = useState('');
  const [updatedDescription, setUpdatedDescription] = useState('');



  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const updateLead = () => {
    // Create a payload object with the updated data
    const payload = {
      id: info[0].id,
      name: updatedName,
      description: updatedDescription

    };

  
    fetch(api + 'updateReminder', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.res === 'Reminder Updated') {
          alert('Successfully updated Reminder');
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  

  useEffect(() => {
    // make a GET request to the API to get client and lead info by id
    // console.log(
    //   'emp_id',
    //   localStorage.getItem('f_l_emp_id'),
    //   'client_id',
    //   localStorage.getItem('f_client_id'),
    // )
    fetch(
      `${api}getReminder?id=${localStorage.getItem('f_r_id')}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        console.log('fetched data : ', data)
        // setClient(data.client);
        // setLead(data.lead);
        setInfo(data)
        setUpdatedName(data[0].name)
        setUpdatedDescription(data[0].description)
        

      })
      .catch((error) => {
        console.error('Error:', error)
      })

    //get users
    fetch(`${api}getUsers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        setEmps(data)
        const temp = []
        data.forEach((element) => {
          // console.log('element', element)
          if(element.emp_id){
            temp.push({ username: element.username })
          }
        })
        setItems(temp)
        // console.log('items', items)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }, [])





  const onDeleteLead = () => {
    fetch(`${api}deleteReminder?id=${info[0].id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if(data.res == "Reminder Deleted"){
          if(localStorage.getItem('f_role')){
            window.location.href = '/'
          }
          window.location.href = '/'
        }
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }


  return (
    <>
      {info && info.length > 0 ? (
        <CRow>
          
          <CCol>


            <CCard>
              <CCardHeader className="display-6">Reminder Profile
              <CButton color="secondary" onClick={toggleEditMode} className="ml-auto" style={{marginLeft: '10px'}}>
            <CIcon icon={cilPencil} />
          </CButton>
          </CCardHeader>
              <CCardBody>
                {/* <div className="form-group mb-4">
                  <label htmlFor="client-name" style={{fontWeight: "bold"}}>Name</label>
                  <p id="client-name">{info[0].full_name}</p>
                </div> */}
                <div className="form-group mb-4">
                <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Name
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={updatedName}
                    onChange={(e) => setUpdatedName(e.target.value)}
                  />
                ) : (
                  <p id="name">{info[0].name}</p>
                )}
              </div>
              <div className="form-group mb-4">
                <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Date
                </CFormLabel>
           
                  <p id="name">{moment(info[0].date).format("yyyy-MM-DD")}</p>

              </div>
              <div className="form-group mb-4">
                <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Time
                </CFormLabel>

                  <p id="name">{info[0].time}</p>

              </div>
              <div className="form-group mb-4">
                <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Client
                </CFormLabel>

                  <p id="name">{info[0].full_name}</p>

              </div>
              <div className="form-group mb-4">
                <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Reminder Description
                </CFormLabel>
                {editMode ? (
                  <CFormTextarea
                  id="exampleFormControlTextarea1"
                  value={updatedDescription}
                  rows={6}
                  contentEditable={true}
                  onChange={(e) => setUpdatedDescription(e.target.value)}
                  // text="Must be 8-20 words long."
                  
              ></CFormTextarea>
                ) : (
                  <CFormTextarea
                  id="exampleFormControlTextarea1"
                  value={info[0].description}
                  rows={6}
                  contentEditable={false}
                  // text="Must be 8-20 words long."
                  
              ></CFormTextarea>
                )}
              </div>
              {editMode && (
                <>
                  <CButton color="primary" onClick={updateLead}>
                    Update Reminder
                  </CButton>
                  <br />
                  <br />
                </>
              )}

              </CCardBody>
            </CCard>{' '}
            <br />
              <CButton color='danger' onClick={onDeleteLead}>Delete Reminder</CButton><br/><br/><br/>
              

          
          </CCol>
        </CRow>
      ) : (
        <div>Error: Unable to retrieve client and lead info</div>
      )}
    </>
  )
}

export default LeadProfile
