import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput, CForm, CFormLabel, CFormTextarea } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'



const AddEmployee = () => {
    const [fn, setfn] = useState("");
    const [email, setEmail] = useState("");
    const [jb, setjb] = useState("");
    const [phone, setPhone] = useState("");
    const [salary, setSalary] = useState("");
    const [link, setLink] = useState("");
    const [role, setRole] = useState("");
    const [description, setDescription] = useState("");
    const emp_id = localStorage.getItem("f_emp_id")


    console.log("🚀 ~ file: AddDj.js:19 ~ AddEmployee ~ emp_id:", emp_id)

    console.log(fn, email, jb, phone, salary)

    const onAddEmployeeClicked = async() =>{
            await fetch(api + 'addCatering', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({name: fn, type:jb, phone:phone,social_link:link, description:description, added_by:emp_id})
        }).then(() => {
         console.log('Data uploaded successfully!');
            alert("Catering Created")
            setfn("")
            setjb("")
            setPhone("")
            setDescription("")
            setLink("")
        }).catch((error) => {
        console.log('Error: ', error);
        });

        
    }

   


  return (
    <CRow>
      <CCol xs={12}>
        <h1>Add Catering</h1><br/>
        <CForm id="add-employee-form">
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Full Name</CFormLabel>
                    <CFormInput type="text" id="fn" placeholder="" onChange={(event)=>{setfn(event.target.value)}} value={fn}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Phone</CFormLabel>
                    <CFormInput type="text" id="phone" placeholder="" onChange={(event)=>{setPhone(event.target.value)}} value={phone}/>
                </div>
                {/* <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Email</CFormLabel>
                    <CFormInput type="text" id="ssn" placeholder="name@example.com" onChange={(event)=>{setEmail(event.target.value)}}/>
                </div> */}
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Social media link</CFormLabel>
                    <CFormInput type="text" id="jp" placeholder="" onChange={(event)=>{setLink(event.target.value)}} value={link}/>
                </div>
                <CFormTextarea
                    id="exampleFormControlTextarea1"
                    label="More Info"
                    rows={3}
                    onChange={(event)=>{setDescription(event.target.value)}}
                    value={description}
                    // text="Must be 8-20 words long."
                ></CFormTextarea>
                <br/>
                {/* <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Role</CFormLabel>
                    <CFormInput type="text" id="jp" placeholder="" onChange={(event)=>{setRole(event.target.value)}}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Salary</CFormLabel>
                    <CFormInput type="email" id="emaial" onChange={(event)=>{setSalary(event.target.value)}}/>
                </div> */}

                <CButton color="primary" variant="outline" onClick={onAddEmployeeClicked}>Add Catering</CButton>
                </CForm>
                <br></br>
      </CCol>
    </CRow>
  )
}

export default AddEmployee
