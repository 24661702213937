import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CFormGroup,
  CButton,
  CFormInput,
  CCardFooter,
  CButtonGroup
} from '@coreui/react'
import { useLocation } from 'react-router-dom'
import { CChartLine } from '@coreui/react-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils'
import CIcon from '@coreui/icons-react'
import {
  cilCloudDownload
} from '@coreui/icons'
import { api } from 'src/configs'

const EmployeeProfile = () => {
  const location = useLocation()
  const [editable, setEditable] = useState(false)

  const [newId, setNewId] = useState(' ')
  const [newFullName, setNewFullName] = useState(' ')
  const [newPhone, setNewPhone] = useState(' ')
  const [newEmail, setNewEmail] = useState(' ')
  const [newSalary, setNewSalary] = useState(' ')
  const [newJobPosition, setNewJobPosition] = useState(' ')

  const [id, setId] = useState(' ')
  const [full_name, setFullName] = useState(' ')
  const [phone, setPhone] = useState(' ')
  const [email, setEmail] = useState(' ')
  const [salary, setSalary] = useState(' ')
  const [job_position, setJobPosition] = useState(' ')
  const [created_at, setCreatedAt] = useState(' ')

  const [chartViewMode, setChartViewMode] = useState('Leads')
  const [chartTime, setChartTime] = useState('Month')

  const [receivedData, setReceivedData] = useState({})

  const [leadsByMonth, setLeadsByMonth] = useState({})
  const [leadsByDay, setLeadsByDay] = useState({})

  const [ordersByMonth, setOrdersByMonth] = useState({})
  const [ordersByDay, setOrdersByDay] = useState({})

  const [venuesByMonth, setVenuesByMonth] = useState({})
  const [venuesByDay, setVenuesByDay] = useState({})


  //   if (
  //     !location.state.employee.id ||
  //     !location.state.employee.full_name ||
  //     !location.state.employee.phone ||
  //     !location.state.employee.email ||
  //     !location.state.employee.salary ||
  //     !location.state.employee.job_position ||
  //     !location.state.employee.created_at
  //   ) {
  //     console.log('Error: EmployeeProfile.js: location.state.employee is undefined')
  //   } else {
  //     setId(location.state.employee.id)
  //     setFullName(location.state.employee.full_name)
  //     setPhone(location.state.employee.phone)
  //     setEmail(location.state.employee.email)
  //     setSalary(location.state.employee.salary)
  //     setJobPosition(location.state.employee.job_position)
  //     setCreatedAt(location.state.employee.created_at)

  //     setNewId(location.state.employee.id)
  //     setNewFullName(location.state.employee.full_name)
  //     setNewPhone(location.state.employee.phone)
  //     setNewEmail(location.state.employee.email)
  //     setNewSalary(location.state.employee.salary)
  //     setNewJobPosition(location.state.employee.job_position)
  //   }

  useEffect(() => {
    if (
      location.state.employee?.id &&
      location.state.employee?.full_name &&
      // location.state.employee?.phone &&
      // location.state.employee?.email &&
      // location.state.employee?.salary &&
      // location.state.employee?.job_position &&
      location.state.employee?.created_at
    ) {
      setId(location.state.employee.id)
      setFullName(location.state.employee.full_name)
      setPhone(location.state.employee.phone)
      setEmail(location.state.employee.email)
      setSalary(location.state.employee.salary)
      setJobPosition(location.state.employee.job_position)
      setCreatedAt(location.state.employee.created_at)

      setNewId(location.state.employee.id)
      setNewFullName(location.state.employee.full_name)
      setNewPhone(location.state.employee.phone)
      setNewEmail(location.state.employee.email)
      setNewSalary(location.state.employee.salary)
      setNewJobPosition(location.state.employee.job_position)
    } else {
      console.log('Error: EmployeeProfile.js: location.state.employee is undefined')
    }
  }, [location.state.employee])

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch(api + `getEmpStatsById?id=${location.state.employee.id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
  
        const data = await response.json();
        console.log(data);
        setReceivedData(data);
  
        const leadsByMonth = {};
        const leadsByDay = {};

        const ordersByMonth = {};
        const ordersByDay = {};

        const venuesByMonth = {};
        const venuesByDay = {};
  
        let newestYear = 2023;
        let newestMonth = 1;
  
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            console.log(key, data[key]);
  
           
  
            data[key]['clients'].forEach((element) => {

              const yearMonth = element['date'].split('-');
              const year = parseInt(yearMonth[0]) || 0;
              const month = parseInt(yearMonth[1]) || 0;

    
              if (year > newestYear || (year === newestYear && month > parseInt(newestMonth))) {
                console.log('newest', year, month);
                newestYear = year;
                newestMonth = month.toString().padStart(2, '0');
              }


              const date = element['date'];
              const day = (date.split('-')[2] || '').padStart(2, '0');
  
              if (leadsByMonth[date.substring(0, 7)]) {
                leadsByMonth[date.substring(0, 7)] += parseInt(element['count']);
              } else {
                leadsByMonth[date.substring(0, 7)] = parseInt(element['count']);
              }
              
              if (year === newestYear && month === parseInt(newestMonth)) {
                if (leadsByDay[day]) {
                  leadsByDay[day] += parseInt(element['count']);
                } else {
                  leadsByDay[day] = parseInt(element['count']);
                }
              }
            });

            data[key]['venues'].forEach((element) => {
                
                const yearMonth = element['date'].split('-');
                const year = parseInt(yearMonth[0]) || 0;
                const month = parseInt(yearMonth[1]) || 0;
  
      
                if (year > newestYear || (year === newestYear && month > parseInt(newestMonth))) {
                  console.log('newest', year, month);
                  newestYear = year;
                  newestMonth = month.toString().padStart(2, '0');
                }

                const date = element['date'];
                const day = (date.split('-')[2] || '').padStart(2, '0');

                if (venuesByMonth[date.substring(0, 7)]) {
                  venuesByMonth[date.substring(0, 7)] += parseInt(element['count']);
                }
                else {
                  venuesByMonth[date.substring(0, 7)] = parseInt(element['count']);
                }

                if (year === newestYear && month === parseInt(newestMonth)) {
                  if (venuesByDay[day]) {
                    venuesByDay[day] += parseInt(element['count']);
                  } else {
                    venuesByDay[day] = parseInt(element['count']);
                  }
                }
            });

            
            data[key]['orders'].forEach((element) => {
                  
                  const yearMonth = element['date'].split('-');
                  const year = parseInt(yearMonth[0]) || 0;
                  const month = parseInt(yearMonth[1]) || 0;
    
        
                  if (year > newestYear || (year === newestYear && month > parseInt(newestMonth))) {
                    console.log('newest', year, month);
                    newestYear = year;
                    newestMonth = month.toString().padStart(2, '0');
                  }
  
                  const date = element['date'];
                  const day = (date.split('-')[2] || '').padStart(2, '0');
  
                  if (ordersByMonth[date.substring(0, 7)]) {
                    ordersByMonth[date.substring(0, 7)] += parseInt(element['count']);
                  }
                  else {
                    ordersByMonth[date.substring(0, 7)] = parseInt(element['count']);
                  }
  
                  if (year === newestYear && month === parseInt(newestMonth)) {
                    if (ordersByDay[day]) {
                      ordersByDay[day] += parseInt(element['count']);
                    } else {
                      ordersByDay[day] = parseInt(element['count']);
                    }
                  }
              }
            );
            


          }
        }
  
        console.log(leadsByMonth);
        console.log(leadsByDay);

        console.log(venuesByMonth)
        console.log(venuesByDay)

        console.log(ordersByMonth)
        console.log(ordersByDay)
  
        setLeadsByMonth(leadsByMonth);
        setLeadsByDay(leadsByDay);

        setVenuesByMonth(venuesByMonth);
        setVenuesByDay(venuesByDay);

        setOrdersByMonth(ordersByMonth);
        setOrdersByDay(ordersByDay);
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    fetchStats();
  }, []);


  const onSaveClicked = async() => {
    await fetch(api + 'updateEmployee', {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: id, fn: newFullName, email: newEmail, phone: newPhone, salary: newSalary, jb: newJobPosition})
      }).then(() => {
      console.log('Data uploaded successfully!');
      window.location.href= 'Employees'
      }).catch((error) => {
      console.log('Error: ', error);
      });
  }


  let chartData = {}

  if (chartViewMode == 'Leads' && chartTime == 'Year') {
    chartData = {
      label: 'Number of leads',
            backgroundColor: hexToRgba(getStyle('--cui-info'), 10),
            borderColor: getStyle('--cui-info'),
            pointHoverBackgroundColor: getStyle('--cui-info'),
            borderWidth: 2,
            data: [
              leadsByMonth['2023-01'] ? leadsByMonth['2023-01'] : 0,
              leadsByMonth['2023-02'] ? leadsByMonth['2023-02'] : 0,
              leadsByMonth['2023-03'] ? leadsByMonth['2023-03'] : 0,
              leadsByMonth['2023-04'] ? leadsByMonth['2023-04'] : 0,
              leadsByMonth['2023-05'] ? leadsByMonth['2023-05'] : 0,
              leadsByMonth['2023-06'] ? leadsByMonth['2023-06'] : 0,
              leadsByMonth['2023-07'] ? leadsByMonth['2023-07'] : 0,
              leadsByMonth['2023-08'] ? leadsByMonth['2023-08'] : 0,
              leadsByMonth['2023-09'] ? leadsByMonth['2023-09'] : 0,
              leadsByMonth['2023-10'] ? leadsByMonth['2023-10'] : 0,
              leadsByMonth['2023-11'] ? leadsByMonth['2023-11'] : 0,
              leadsByMonth['2023-12'] ? leadsByMonth['2023-12'] : 0,
            ],
            fill: true,
  }
  }

  if (chartViewMode == 'Leads' && chartTime == 'Month') {
    chartData = {
      label: 'Number of leads',
            backgroundColor: hexToRgba(getStyle('--cui-info'), 10),
            borderColor: getStyle('--cui-info'),
            pointHoverBackgroundColor: getStyle('--cui-info'),
            borderWidth: 2,
            data: [
              
            ],
            fill: true,
  }
  for (let i = 1; i <= 31; i++) {
    const day = i.toString().padStart(2, '0');
    chartData.data.push(leadsByDay[day] ? leadsByDay[day] : 0);
  }
  }

  if (chartViewMode == 'Venues' && chartTime == 'Year') {
    chartData = {
      label: 'Number of venues',
            backgroundColor: hexToRgba(getStyle('--cui-info'), 10),
            borderColor: getStyle('--cui-info'),
            pointHoverBackgroundColor: getStyle('--cui-info'),
            borderWidth: 2,
            data: [
              venuesByMonth['2023-01'] ? venuesByMonth['2023-01'] : 0,
              venuesByMonth['2023-02'] ? venuesByMonth['2023-02'] : 0,
              venuesByMonth['2023-03'] ? venuesByMonth['2023-03'] : 0,
              venuesByMonth['2023-04'] ? venuesByMonth['2023-04'] : 0,
              venuesByMonth['2023-05'] ? venuesByMonth['2023-05'] : 0,
              venuesByMonth['2023-06'] ? venuesByMonth['2023-06'] : 0,
              venuesByMonth['2023-07'] ? venuesByMonth['2023-07'] : 0,
              venuesByMonth['2023-08'] ? venuesByMonth['2023-08'] : 0,
              venuesByMonth['2023-09'] ? venuesByMonth['2023-09'] : 0,
              venuesByMonth['2023-10'] ? venuesByMonth['2023-10'] : 0,
              venuesByMonth['2023-11'] ? venuesByMonth['2023-11'] : 0,
              venuesByMonth['2023-12'] ? venuesByMonth['2023-12'] : 0,
            ],
            fill: true,
  }
  }

  if (chartViewMode == 'Venues' && chartTime == 'Month') {
    chartData = {
      label: 'Number of venues',
            backgroundColor: hexToRgba(getStyle('--cui-info'), 10),
            borderColor: getStyle('--cui-info'),
            pointHoverBackgroundColor: getStyle('--cui-info'),
            borderWidth: 2,
            data: [
              
            ],
            fill: true,
  }
  for (let i = 1; i <= 31; i++) {
    const day = i.toString().padStart(2, '0');
    chartData.data.push(venuesByDay[day] ? venuesByDay[day] : 0);
  }
  }


  if (chartViewMode == 'Orders' && chartTime == 'Year') {
    chartData = {
      label: 'Number of orders',
            backgroundColor: hexToRgba(getStyle('--cui-info'), 10),
            borderColor: getStyle('--cui-info'),
            pointHoverBackgroundColor: getStyle('--cui-info'),
            borderWidth: 2,
            data: [
              ordersByMonth['2023-01'] ? ordersByMonth['2023-01'] : 0,
              ordersByMonth['2023-02'] ? ordersByMonth['2023-02'] : 0,
              ordersByMonth['2023-03'] ? ordersByMonth['2023-03'] : 0,
              ordersByMonth['2023-04'] ? ordersByMonth['2023-04'] : 0,
              ordersByMonth['2023-05'] ? ordersByMonth['2023-05'] : 0,
              ordersByMonth['2023-06'] ? ordersByMonth['2023-06'] : 0,
              ordersByMonth['2023-07'] ? ordersByMonth['2023-07'] : 0,
              ordersByMonth['2023-08'] ? ordersByMonth['2023-08'] : 0,
              ordersByMonth['2023-09'] ? ordersByMonth['2023-09'] : 0,
              ordersByMonth['2023-10'] ? ordersByMonth['2023-10'] : 0,
              ordersByMonth['2023-11'] ? ordersByMonth['2023-11'] : 0,
              ordersByMonth['2023-12'] ? ordersByMonth['2023-12'] : 0,
            ],
            fill: true,
  }
  }

  if (chartViewMode == 'Orders' && chartTime == 'Month') {
    chartData = {
      label: 'Number of orders',
            backgroundColor: hexToRgba(getStyle('--cui-info'), 10),
            borderColor: getStyle('--cui-info'),
            pointHoverBackgroundColor: getStyle('--cui-info'),
            borderWidth: 2,
            data: [
              
            ],
            fill: true,
  }
  for (let i = 1; i <= 31; i++) {
    const day = i.toString().padStart(2, '0');
    chartData.data.push(ordersByDay[day] ? ordersByDay[day] : 0);
  }
  }



  return (
    <>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader style={{ fontSize: '28px' }}>
            Employee Profile
            {!editable ? (
              <CButton
                color="primary"
                style={{ position: 'absolute', right: 40 }}
                onClick={() => setEditable(!editable)}
              >
                edit
              </CButton>
            ) : null}
            {editable ? (
              <CButton
                color="danger"
                style={{ position: 'absolute', right: 100 }}
                onClick={() => {
                  {
                    setEditable(false)
                  }
                }}
              >
                Cancel
              </CButton>
            ) : null}
            {editable ? (
              <CButton
                color="primary"
                style={{ position: 'absolute', right: 40 }}
                onClick={() => {
                  onSaveClicked()
                }}
              >
                save
              </CButton>
            ) : null}
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md="3">
                <strong>Employee ID:</strong>
              </CCol>
              <CCol md="9">
                {editable ? (
                  <CFormInput value={newId} onChange={(e) => setNewId(e.target.value)} disabled />
                ) : (
                  id
                )}
              </CCol>
            </CRow>
            <CRow>
              <CCol md="3">
                <strong>Full Name:</strong>
              </CCol>
              <CCol md="9">
                {editable ? (
                  <CFormInput
                    value={newFullName}
                    onChange={(e) => setNewFullName(e.target.value)}
                  />
                ) : (
                  full_name
                )}
              </CCol>
            </CRow>
            <CRow>
              <CCol md="3">
                <strong>Phone:</strong>
              </CCol>
              <CCol md="9">
                {editable ? (
                  <CFormInput value={newPhone} onChange={(e) => setNewPhone(e.target.value)} />
                ) : (
                  phone
                )}
              </CCol>
            </CRow>
            <CRow>
              <CCol md="3">
                <strong>Email:</strong>
              </CCol>
              <CCol md="9">
                {editable ? (
                  <CFormInput value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
                ) : (
                  email
                )}
              </CCol>
            </CRow>
            <CRow>
              <CCol md="3">
                <strong>Salary:</strong>
              </CCol>
              <CCol md="9">
                {editable ? (
                  <CFormInput value={newSalary} onChange={(e) => setNewSalary(e.target.value)} />
                ) : (
                  salary
                )}
              </CCol>
            </CRow>
            <CRow>
              <CCol md="3">
                <strong>Job Position:</strong>
              </CCol>
              <CCol md="9">
                {editable ? (
                  <CFormInput
                    value={newJobPosition}
                    onChange={(e) => setNewJobPosition(e.target.value)}
                  />
                ) : (
                  job_position
                )}
              </CCol>
            </CRow>
            <CRow>
              <CCol md="3">
                <strong>Joined At:</strong>
              </CCol>
              <CCol md="9">
                {created_at.substring(0, 10)}

              </CCol>
            </CRow>
            <CRow>
              <CCol md="3">
                <strong>password:</strong>
              </CCol>
              <CCol md="9">
                <div>
                {editable ? (
                  <CFormInput
                    value={newJobPosition}
                    onChange={(e) => setNewJobPosition(e.target.value)}
                  />
                ) : (
                  job_position
                )}
                </div>
                <CButton
                  size="sm"
                  color="danger"
                  style={{ position: 'absolute', right: 20 }}
                  onClick={() => {
                    let pass = prompt('Enter root password to delete employee')
                    console.log(pass)
                  }}
                >
                  Terminate Employee
                </CButton>
              </CCol>
            </CRow>
            
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <br/><br/><br/>
    


    <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                Employee Stats
              </h4>
              <div className="small text-medium-emphasis">activity data</div>
            </CCol>
            <CCol sm={7} className=" d-md-block">
              {/* <CButton color="primary" className="float-end">
                <CIcon icon={cilCloudDownload} />
              </CButton> */}
              <CButtonGroup className="float-end me-3">
                {['Year', 'Month'].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === chartTime}
                    onClick={() => setChartTime(value)}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>
              <CButtonGroup className="float-end me-3">
                {['Leads', 'Venues', 'Orders'].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === chartViewMode}
                    onClick={() => setChartViewMode(value)}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>
            </CCol>
          </CRow>
          <CChartLine
            style={{ height: '300px', marginTop: '40px' }}
            data={{
              labels : chartTime == 'Year' ? ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', "November", "December"] : ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", '22', '23', '24', '25', '26', '27', '28', '29', '30', "31"],
              datasets: [
                chartData

              ],
            }}
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  grid: {
                    drawOnChartArea: false,
                  },
                },
                y: {
                  ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    stepSize: Math.ceil(250 / 5),
                    max: 250,
                  },
                },
              },
              elements: {
                line: {
                  tension: 0.4,
                },
                point: {
                  radius: 0,
                  hitRadius: 10,
                  hoverRadius: 4,
                  hoverBorderWidth: 3,
                },
              },
            }}
          />
        </CCardBody>
        <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 4 }} className="text-center">
            {/* {progressExample.map((item, index) => (
              <CCol className="mb-sm-2 mb-0" key={index}>
                <div className="text-medium-emphasis">{item.title}</div>
                <strong>
                  {item.value} ({item.percent}%)
                </strong>
                <CProgress thin className="mt-2" color={item.color} value={item.percent} />
              </CCol>
            ))} */}

        

          </CRow>
        </CCardFooter>
      </CCard>
    </>
  )
}

export default EmployeeProfile
