import React, { useEffect, useState } from 'react'
import { CButton,CCol, CRow, CTable, CFormInput, CDropdown, CDropdownToggle, CDropdownItem, CDropdownMenu, CModal, CModalBody, CModalHeader, CModalFooter, CModalTitle } from '@coreui/react'
import { api } from 'src/configs'

const columns = [
    {
      key: 'id',
      label: 'id',
      _props: { scope: 'col' },
    },
    {
      key: 'Name',
      label: 'Name',
      _props: { scope: 'col' },
    },
    {
      key: 'Area',
      label: 'Area',
      _props: { scope: 'col' },
    },
    {
        key: 'Location',
        label: 'Location',
        _props: { scope: 'col' },
      },
      {
        key: 'type',
        label: 'type',
        _props: { scope: 'col' },
      },
      {
        key: 'added_by',
        label: 'Added by',
        _props: { scope: 'col' },
      },
      {
        key: 'added_at',
        label: 'Added at',
        _props: { scope: 'col' },
      },
      {
        key: 'Action',
        label: 'Action',
        _props: { scope: 'col' },
      },
  ]



const Venues = () => {
    const [items, setItems] = useState([]);
    const [filter, setfilter] = useState("");

    const [sortMode, setSortMode] = useState("newest to oldest");

    const [visible, setVisible] = useState(false);

    const [filter_name, setfilter_name] = useState("");
    const [filter_date, setfilter_date] = useState("");
    const [areFiltersActive, setAreFiltersActive] = useState(false);

    useEffect(() => {
        const fetchVenues = async () => {
          const response = await fetch(api+ "getVenues");
          const data = await response.json();
          // console.log(data)
          setItems(data);
        };
    
        fetchVenues();
      }, []);

      const create_table = (array) =>{
        var items = []
        array.forEach(element => {
            let color = "black"
            if(element["status"] === false){
                color = "red"
            } else if (element["is_complete"] === false){
                color = "#d1aa00"
            } else if (element["is_complete"] === true){
                color = "#002394"
            } 
            if (element["status"] == true){
                color = "#007d23"
            }
            // console.log(color)
            items.push({
                id: element["id"] ? element["id"] : "N/A",
                Name: element["name"] ? <div style={{color: color, fontWeight: '500'}}>{element["name"]}</div> : "N/A",
                type: element["type"] ? element["type"] : "N/A",
                Area: element["area"] ? element["area"] : "N/A",
                Location: element["location"] && element["location"].length > 30 ? element["location"].substring(0, 30) + '...' : element["location"] ? element["location"] : "N/A",
                added_by: element["full_name"] ? element["full_name"] : "N/A",
                added_at: element["created_at"] ? element["created_at"].substring(0, 10) : "N/A",
                phone: element["phone"] ? element["phone"] : "N/A",
                is_complete: element["is_complete"],
                Action: <CButton color="dark" href='/VenueProfile' onClick={() =>{localStorage.setItem('f_v_id', element['id'])}}>Profile</CButton>,
            },)
        });
        return items
      }
      var itemlist = create_table(items)
      console.log("itemlist: ", itemlist)
      
      if (sortMode === "newest to oldest") {
        // itemlist.sort((a, b) => {
        //   return new Date(b.added_at) - new Date(a.added_at);
        // });
        itemlist.sort((a, b) => b.id - a.id);
      }

      if (sortMode === "oldest to neest") {
        // itemlist.sort((a, b) => {
        //   return new Date(a.added_at) - new Date(b.added_at);
        // });
        itemlist.sort((a, b) => a.id - b.id);
      }

      if (sortMode === "complete") {
        // itemlist.sort((a, b) => {
        //   return new Date(a.added_at) - new Date(b.added_at);
        // });
        itemlist = itemlist.filter((item) => {
          return item.is_complete === true;
        }
        );
      }

      if (sortMode === "incomplete") {
       itemlist = itemlist.filter((item) => {

          return item.is_complete === false;
        }
        );
      }

      

      if(filter !== ""){
        itemlist = itemlist.filter((item) => {
          return item.Name.props.children.toLowerCase().includes(filter.toLowerCase()) || item.Area.toLowerCase().includes(filter.toLowerCase()) || item.Location.toLowerCase().includes(filter.toLowerCase()) || item.type.toLowerCase().includes(filter.toLowerCase()) || item.added_by.toLowerCase().includes(filter.toLowerCase()) || item.phone.toLowerCase().includes(filter.toLowerCase())
        })
      }

      const onAdvancedSortClicked = () =>{
        console.log("clicked")
        setVisible(true)
      }

      const onApplyClicked = () =>{
        console.log("clicked")
        setAreFiltersActive(true)
        setVisible(false)
      }

      if (areFiltersActive){
        if(filter_name != ""){
          itemlist = itemlist.filter(item => item.added_by.toLowerCase().includes(filter_name.toLowerCase()))
        }
        if(filter_date != ""){
          console.log("yes")
          itemlist = itemlist.filter(item => item.added_at.toLowerCase().includes(filter_date.toLowerCase()))
        }

      }


  return (
    <CRow>
      <CModal alignment="center" scrollable visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Select your filter options</CModalTitle>
            </CModalHeader>
            <CModalBody>
            <CFormInput
              type="text"
              label="Employee Name"
              placeholder="Name"
              text="Copy and past the Name of the employee you wish to view their data"
              aria-describedby="exampleFormControlInputHelpInline"
              onChange={(event) => { setfilter_name(event.target.value) }}
              value={filter_name}
            />
            <br/>

              <CFormInput
              type="text"
              label="Date"
              placeholder="FORMAT : YYYY-MM-DD"
              text="example : 2023-01-01"
              aria-describedby="exampleFormControlInputHelpInline"
              onChange={(event) => { setfilter_date(event.target.value) }}
              value={filter_date}
            />
            
            
            </CModalBody>
            <CModalFooter>
              <CButton color="secondary" onClick={() => setVisible(false)}>Cancel</CButton>
              <CButton color='danger' onClick={()=>{setfilter_name(''); setfilter_date(''); setAreFiltersActive(false)}}>clear filters</CButton>
              <CButton color="primary" onClick={onApplyClicked}>Apply</CButton>
            </CModalFooter>

          </CModal>
      <CCol xs={12}>
        <h1>Venues</h1><br/>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CFormInput
            type="text"
            placeholder="search venues"
            aria-label="default input example"
            onChange={(event) => { setfilter(event.target.value) }}
          />
          <CDropdown style={{ marginLeft: '10px'}}>
            <CDropdownToggle href="#" color="primary" >sort</CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem onClick={()=>{setSortMode('newest to oldest')}}>newest to oldest</CDropdownItem>
              <CDropdownItem onClick={()=>{setSortMode('oldest to newest')}}>oldest to newest</CDropdownItem>
              <CDropdownItem onClick={()=>{setSortMode('complete')}}>complete</CDropdownItem>
              <CDropdownItem onClick={()=>{setSortMode('incomplete')}}>incomplete</CDropdownItem>
              {/* <CDropdownItem href="#">Something else here</CDropdownItem> */}
            </CDropdownMenu>
          </CDropdown>
          <CButton style={{width: '150px', marginLeft: '10px'}} onClick={onAdvancedSortClicked}>advanced sort</CButton>
        </div>
        <br/><br/>
        <div className="table table-responsive"><CTable className="table table-striped table-hover" columns={columns} items={itemlist}/></div>
      </CCol>
    </CRow>
  )
}

export default Venues