import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'

const columns = [
    {
      key: 'id',
      label: 'id',
      _props: { scope: 'col' },
    },
    {
      key: 'Name',
      label: 'Name',
      _props: { scope: 'col' },
    },
    {
      key: 'Type',
      label: 'Type',
      _props: { scope: 'col' },
    },
      {
        key: 'ab',
        label: 'Added by',
        _props: { scope: 'col' },
      },
      {
        key: 'aa',
        label: 'Added at',
        _props: { scope: 'col' },
      },
      {
        key: 'Action',
        label: 'Action',
        _props: { scope: 'col' },
      },
  ]



const Orders = () => {
    const [items, setItems] = useState([]);
    const [filter, setfilter] = useState("");

    useEffect(() => {
        const fetchOrders = async () => {
          const response = await fetch(api + "getOrders");
          const data = await response.json();
          console.log(data)
          setItems(data);
        };
    
        fetchOrders();
      }, []);

      const create_table = (array) =>{
        var items = []
        array.forEach(element => {
            items.push({
                id: element["id"],
                Name: element["name"],
                Type: element["type"],
                ab: element["employee_name"],
                aa: element["created_at"] ? element["created_at"].substring(0,10) : "",
                added_by: element["full_name"],
                Salary: element["salary"],
                Action: <CButton color="dark" href='/OrderProfile' onClick={() =>{localStorage.setItem('f_o_id', element['id'])}}>View order</CButton>,
            },)
        });
        return items
      }
      var itemlist = create_table(items)
      if(filter !== ""){
        itemlist = itemlist.filter((item) => {
          return item.Name.toLowerCase().includes(filter.toLowerCase()) || item.Area.toLowerCase().includes(filter.toLowerCase()) || item.Location.toLowerCase().includes(filter.toLowerCase()) || item.type.toLowerCase().includes(filter.toLowerCase()) || item.added_by.toLowerCase().includes(filter.toLowerCase())
        })
      }
      console.log("orders")
  return (
    <CRow>
      <CCol xs={12}>
        <h1>Orders </h1><br/>
        <CFormInput type="text" placeholder={"search"} aria-label="default input example" onChange={(event)=>{setfilter(event.target.value)}}/><br/><br/>
        <div className="table table-responsive"><CTable className="table table-striped table-hover" columns={columns} items={itemlist}/></div>
      </CCol>
    </CRow>
  )
}

export default Orders