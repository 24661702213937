import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'
import moment from 'moment'

const columns = [
    // {
    //   key: 'id',
    //   label: 'id',
    //   _props: { scope: 'col' },
    // },
    {
      key: 'Name',
      label: 'Name',
      _props: { scope: 'col' },
    },
    {
      key: 'Description',
      label: 'Description',
      _props: { scope: 'col' },
    },
      // {
      //   key: 'start_date',
      //   label: 'Start Date',
      //   _props: { scope: 'col' },
      // },
      {
        key: 'end_date',
        label: 'Deadline',
        _props: { scope: 'col' },
      },
      {
        key: 'Completion',
        label: 'Completion',
        _props: { scope: 'col' },
      },
      {
        key: 'Score',
        label: 'Score',
        _props: { scope: 'col' },
      },
      {
        key: 'Action',
        label: 'Action',
        _props: { scope: 'col' },
      },
  ]



const MyTasks = () => {
    const [items, setItems] = useState([]);
    const [filter, setfilter] = useState("");
    const currentDate = moment();
    const currentTime = new Date();

    useEffect(() => {
      console.log(localStorage.getItem('f_emp_id'))
       // make a get request to api to get tasks by id and send localstorage of f_emp_id as query using fetch
        fetch(api + 'getEmpTasks?emp_id=' + localStorage.getItem('f_emp_id'), {

            method: 'GET',
            headers: {  
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
          console.log(data)
            setItems(data.sort((a, b) => (a.id < b.id) ? 1 : -1));
        }
        )
        .catch((error) => {
            console.error('Error:', error);
        }
        );

      }, []);

      function isDateAfter(dateString) {
        const currentDate = new Date();
        const givenDate = new Date(dateString);
      
        // Get the year, month, and day components of the dates
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();
        const givenYear = givenDate.getFullYear();
        const givenMonth = givenDate.getMonth();
        const givenDay = givenDate.getDate();
      
        // Compare the year, month, and day components
        if (currentYear > givenYear) {
          return true;
        } else if (currentYear === givenYear) {
          if (currentMonth > givenMonth) {
            return true;
          } else if (currentMonth === givenMonth) {
            if (currentDay > givenDay) {
              return true;
            }
          }
        }
      
        return false;
      }
  
      function formatDateToYearMonthDay(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
      
        return `${year}-${month}-${day}`;
      }

      const isAfter = (timeString) => {
        if (timeString === null){
          return false
        }
        const currentHour = currentTime.getHours();
        const currentMinute = currentTime.getMinutes();
        const currentSecond = currentTime.getSeconds();
    
        const [variableHour, variableMinute, variableSecond] = timeString.split(':');
    
        if (
          currentHour > parseInt(variableHour, 10) ||
          (currentHour === parseInt(variableHour, 10) && currentMinute > parseInt(variableMinute, 10)) ||
          (currentHour === parseInt(variableHour, 10) &&
            currentMinute === parseInt(variableMinute, 10) &&
            currentSecond > parseInt(variableSecond, 10))
        ) {
          return true;
        }
    
        return false;
      };

      const create_table = (array) =>{
        var items = []
        array.forEach(element => {
          console.log("completion : ", element["completion"], "end_date: ", element['end_date'], "cd > deadline : ", currentDate.isAfter(element["end_date"]))
            items.push({
                id: element["id"],
                Name: <div style={{}}>{element["name"]}</div>,
                Description: element["description"],
                ...(element["score"] == null)? {Score: "unscored"} : {Score: element["score"] + " "},
                ...(element["start_date"] == null)? {start_date: "-"} : {start_date: element["start_date"].substring(0,10)},
                // ...(element["end_date"] == null)? {end_date: "-"} : {end_date: element["end_date"].substring(0,10)}, 
                //if element['end_date']  null show  "-" if current data after end date show element["end_date"] in red color, if completion = 1 show in green,  else show element["end_date"] in yellow
                ...(element["end_date"] == null)? {end_date: "-"} : 
                (isDateAfter(element["end_date"]) || (formatDateToYearMonthDay(currentTime) == formatDateToYearMonthDay(new Date(element["end_date"])) && isAfter(element['end_time']))) && element['completion'] == 0 ? {end_date: <div style={{color:"red"}}>{formatDateToYearMonthDay(new Date(element['end_date']))}</div>} :  {end_date: <div style={{color:""}}>{formatDateToYearMonthDay(new Date(element['end_date']))}</div>},

                //if complettion = 0 and current date after end date show overdue in red, if completion = 0 and current date before end date show incomplete in yellow, else show complete in green
                // ...(element["completion"] == 0 && currentDate.isAfter(element["end_date"]))? {Completion: <div style={{color:"red"}}>overdue</div>} : (element["completion"] == 0 && currentDate.isBefore(element["end_date"]))? {Completion: <div style={{color:"yellow"}}>Incomplete</div>} : {Completion: <div style={{color:"Green"}}>Complete</div>,,
//                 ...(element["completion"] == 0 && moment(element["end_date"]).isBefore(moment()))? {Completion: <div style={{color:"red"}}>overdue</div>} : 
// (element["completion"] == 0 && moment(element["end_date"]).isAfter(moment()))? {Completion: <div style={{color:"#9e9400"}}>Incomplete</div>} : 
// {Completion: <div style={{color:"Green"}}>Complete</div>},

...(element["completion"] == 1) ?  {Completion: <div style={{color:"green"}}>Complete</div>} : {Completion: <div >Incomplete</div>},


                // ... (currentDate.isAfter(element["end_date"]) && element["completion"] == 0)? {Completion: 'overdue'} : (element["completion"] == 0 && !currentDate.isAfter(element["end_date"]))? {Completion: 'incomplete'} : {Completion: 'complete'},
                // Completion: element["completion"] + '',
                Action: <CButton color="dark" href='/TaskProfile' onClick={() =>{localStorage.setItem("f_t_id", element["id"])}}>Task</CButton>,
  
            },)
        });
        return items
      }
      console.log("items : ", items)
      var items2 = []
      if(filter != ""){
        items2 = items.filter((item) => {
          return item.name.toLowerCase().includes(filter.toLowerCase()) || item.description.toLowerCase().includes(filter.toLowerCase())
        })
      } else {
        items2 = items
      }
      console.log("items2 : ", items2)
      var itemlist = create_table(items2)
      console.log("itemlist : ", itemlist)

  return (
    <CRow>
      <CCol xs={12}>
        <h1>My Tasks</h1><br/>
        <CFormInput type="text" placeholder={"Filter"} aria-label="default input example" onChange={(event)=>{setfilter(event.target.value)}}/><br/><br/>
        <div className="table table-responsive"><CTable className="table table-striped table-hover" columns={columns} items={itemlist}/></div>
      </CCol>
    </CRow>
  )
}

export default MyTasks