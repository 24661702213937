import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput, CForm, CFormLabel,  } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'


const AddEmployee = () => {
    const [fn, setfn] = useState("");
    const [email, setEmail] = useState("");
    const [jb, setjb] = useState("");
    const [phone, setPhone] = useState("");
    const [salary, setSalary] = useState("");
    const [role, setRole] = useState("");

    console.log(fn, email, jb, phone, salary)

    const onAddEmployeeClicked = async() =>{
            await fetch(api + 'addEmployee', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({fn: fn, jb:jb, phone:phone, salary:salary, email:email})
        }).then(() => {
        console.log('Data uploaded successfully!');
        }).catch((error) => {
        console.log('Error: ', error);
        });

        await fetch(api + 'addUser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email:email, role:role})
            }).then(() => {
            console.log('User Created successfully!');
            alert("User Created")
            }).catch((error) => {
            console.log('Error: ', error);
            });
    }

  return (
    <CRow>
      <CCol xs={12}>
        <h1>Add Emplooyee</h1><br/>
        <CForm id="add-employee-form">
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Full Name</CFormLabel>
                    <CFormInput type="text" id="fn" placeholder="" onChange={(event)=>{setfn(event.target.value)}}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Phone</CFormLabel>
                    <CFormInput type="text" id="phone" placeholder="" onChange={(event)=>{setPhone(event.target.value)}}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Email</CFormLabel>
                    <CFormInput type="text" id="ssn" placeholder="name@example.com" onChange={(event)=>{setEmail(event.target.value)}}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Job Position</CFormLabel>
                    <CFormInput type="text" id="jp" placeholder="" onChange={(event)=>{setjb(event.target.value)}}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Role</CFormLabel>
                    <CFormInput type="text" id="jp" placeholder="" onChange={(event)=>{setRole(event.target.value)}}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Salary</CFormLabel>
                    <CFormInput type="email" id="emaial" onChange={(event)=>{setSalary(event.target.value)}}/>
                </div>

                <CButton color="primary" variant="outline" onClick={onAddEmployeeClicked}>Add Employee</CButton>
                </CForm>
                <br></br>
      </CCol>
    </CRow>
  )
}

export default AddEmployee
