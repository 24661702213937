import React, { useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable,
  CFormInput,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'
import { useNavigate } from 'react-router-dom'

const columns = [
  {
    key: 'id',
    label: 'id',
    _props: { scope: 'col' },
  },
  {
    key: 'Full_Name',
    label: 'Full Name',
    _props: { scope: 'col' },
  },
  
  {
    key: 'Phone',
    label: 'Phone',
    _props: { scope: 'col' },
  },

  {
    key: 'type',
    label: 'type',
    _props: { scope: 'col' },
  },
  {
    key: 'added_by',
    label: 'Added By',
    _props: { scope: 'col' },
  },
  {
    key: 'Date',
    label: 'Date',
    _props: { scope: 'col' },
  },
  {
    key: 'Action',
    label: 'Action',
    _props: { scope: 'col' },
  },
]

const Employees = () => {
  const [items, setItems] = useState([])
  const [filter, setfilter] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const fetchEmployees = async () => {
      const response = await fetch(api + 'getEntertainers')
      const data = await response.json()
      console.log(data)
      setItems(data.sort((a, b) => (a['id'] > b['id'] ? 1 : -1)))
    }

    fetchEmployees()
  }, [])

  const create_table = (array) => {
    var items = []
    array.forEach((element) => {
      if (element['full_name'] === '-') {
        return;
      }

      items.push({
        id: element['id'],
        Full_Name: element['full_name'] + " ",
        type: element['type'] + ' ',
        Phone: element['phone'] + " ",
        Job_Position: element['job_position'] ? element["job_position"] : "-",
        Salary: element['salary'] + '',
        added_by: element['emp_name']  ? element['emp_name'] : '-',
        Date: element['created_at'] + " ",
        Action: (
          <CButton
            color="dark"
            onClick={() => {
            localStorage.setItem("f_d_id", element['id'])
              navigate('/DjProfile', { state: { employee: element } })
            }}
          >
            Profile
          </CButton>
        ),
      })
    })
    return items
  }

  var itemlist = create_table(items)
  if (filter !== '') {
    itemlist = itemlist.filter((item) => {
      return item['Full_Name'].toLowerCase().includes(filter.toLowerCase()) || item['Phone'].toLowerCase().includes(filter.toLowerCase()) || item['type'].toLowerCase().includes(filter.toLowerCase()) || item['added_by'].toLowerCase().includes(filter.toLowerCase())
    })
  }
  return (
    <CRow>
      <CCol xs={12}>
        <h1>Entertainers ({items.length})</h1>
        <br />
        <CFormInput
          type="text"
          placeholder={'Filter'}
          aria-label="default input example"
          onChange={(event) => {
            setfilter(event.target.value)
          }}
        />
        <br />
        <br />
        <div className="table table-responsive">
          <CTable className="table table-striped table-hover" columns={columns} items={itemlist} />
        </div>
      </CCol>
    </CRow>
  )
}

export default Employees
