import React, { useEffect, useState, useRef } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'
import {
  cilGem,
} from '@coreui/icons'

const columns = [
    {
          key: 'emp_name',
          label: 'Employee Name',
          _props: { scope: 'col' },
        },
        {
          key: 'nol',
          label: 'Number of Sales',
          _props: { scope: 'col' },
        },
        // {
        //     key: 'nov',
        //     label: 'Number of venues',
        //     _props: { scope: 'col' },
        //   },
      ]

const LeaderBoard = () => {
    const [items, setItems] = useState([]);
    const [filter, setfilter] = useState("");
    var [dictionary, setDict] = useState("");
    const [vals, setVals] = useState([]);

    useEffect(() => {
        fetch(api + 'getLeaderboard', {

            method: 'GET',
            headers: {  
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
          // console.log(data)
          setItems(data.sort((a, b) => {
            return b.count_leads - a.count_leads;
          }));
            
        }
        )
        .catch((error) => {
            console.error('Error:', error);
        }
        );



      }, []);

      
      const create_table = (array) =>{
        var items = []
        var first = true
        array.forEach(element => {
          if(element['full_name'] == 'Maged Paula'){
            return
          }
          if (first){
            items.push({
              emp_name: <div>{element["full_name"]}  <CIcon icon={cilGem} color='red'/> (top employee) </div> ,
              ...(element["count_leads"] == null)? {nol: "0"} : {nol: element["count_leads"]},
              ...(element["count_venues"] == null)? {nov: "0"} : {nov: element["count_venues"]},
              // nol: element["count_leads"],
              // nov: element["count_venues"],
          },)
            first = false
          } else {
            items.push({
              emp_name: element["full_name"],
              ...(element["count_leads"] == null)? {nol: "0"} : {nol: element["count_leads"]},
              ...(element["count_venues"] == null)? {nov: "0"} : {nov: element["count_venues"]},
              // nol: element["count_leads"],
              // nov: element["count_venues"],
          },)
          }
            
        });
        return items
      }
      var itemlist = create_table(items)
      
  return (
    <>  <h1 style={{
        fontSize: '3rem',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#333',
        fontFamily: 'sans-serif',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
        textShadow: '2px 2px #ccc'
          }}>
              LeaderBoard
          </h1>
    <CRow>
      <CCol xs={12}>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                Sales LeaderBoard
              </h4>
              <div className="small text-medium-emphasis"> Top employees </div>
            </CCol>
            {/* <CCol sm={7} className="d-none d-md-block">
              <CButton color="primary" className="float-end">
                <CIcon icon={cilCloudDownload} />
              </CButton>
              <CButtonGroup className="float-end me-3">
                {['Day', 'Month', 'Year'].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === 'Month'}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>
            </CCol> */}
          </CRow>
          <CTable columns={columns} items={itemlist}/>
        </CCardBody>
        {/* <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            {progressExample.map((item, index) => (
              <CCol className="mb-sm-2 mb-0" key={index}>
                <div className="text-medium-emphasis">{item.title}</div>
                <strong>
                  {item.value} ({item.percent}%)
                </strong>
                <CProgress thin className="mt-2" color={item.color} value={item.percent} />
              </CCol>
            ))}
          </CRow>
        </CCardFooter> */}
      </CCard>
      </CCol>
    </CRow>
    </>
  )
}

export default LeaderBoard