import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormInput, CForm, CFormLabel, CFormTextarea, CModal, CModalBody, CModalHeader, CModalTitle, CListGroup, CListGroupItem, CModalFooter  } from '@coreui/react'
import { compareTwoStrings } from 'string-similarity';
import { api } from 'src/configs'


const AddVenue = () => {
    const [name, setName] = useState("");
    const [Area, setArea] = useState("");
    const [location, setLocation] = useState("");
    const [type, setType] = useState("");
    const [price, setPrice] = useState(0);
    const [added_by, setAb] = useState("");
    const [phone, setPhone] = useState("");
    const [price_text, setPriceText] = useState("");
    const [description, setDescription] = useState("");
    const [contactFN, setContactFN] = useState("");
    const [contactJB, setContactJB] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [capacity, setCapacity] = useState(0);

    const [venues, setVenues] = useState([]);
    const [similarVenues, setSimilarVenues] = useState([]);
    const [visible, setVisible] = useState(false);
    const [canAdd, setCanAdd] = useState(true);

    const stop_words = ["villa", "hotel", "house", "apartment", "resort", "hostel", "camping", "camp", "campground", "hut", "cottage", "bungalow", "bungalow", "cabin", "lodge", "chalet", "motel", "inn"]

    useEffect (() => {
        fetch(api + 'getVenues', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            if(res.status === 200){
                res.json().then((data) => {
                    // console.log(data)
                    setVenues(data.map(obj => obj.name))
                    // console.log(venues)
                })
            }
        })
    } , [])


    const addVenue = () => {
        fetch(api + 'addVenue', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',

        },
        body: JSON.stringify({
            name: name,
            location: location,
            type: type,
            price: price,
            added_by: localStorage.getItem('f_emp_id'),
            area: Area,
            price_text: price_text,
            description: description,
            phone: phone,
            contact_full_name: contactFN,
            contact_job_position: contactJB,
            contact_email: contactEmail,
            capacity: capacity
        })
    }).then((res) => {
        // console.log(res)
        if(res.status === 200){
            alert("Venue Added Successfully")
            setName("")
            setArea("")
            setLocation("")
            setType("")
            setPrice(0)
            setAb("")
            setPhone("")
            setPriceText("")
            setDescription("")
            setContactFN("")
            setContactJB("")
            setContactEmail("")
            setCapacity(0)
            setVisible(false)
            setCanAdd(true)
        
        }
        else{
            alert("Venue Not Added")
        }
    })
    }

    const onAddVenueClicked = () =>{

        if (name == ''){
            alert("Please enter a name for the venue")
            return
        }

        if (Area == ''){
            alert("Please enter an area, example : North Coast, Giza, etc.")
            return
        }
        if (type == ''){
            alert("Please enter a venue type, example: hotel, villa, etc.")
            return
        }
        if (capacity == ''){
            alert("Please enter the capacity of the venue, if you are not sure enter an estimate")
            return
        }
        // if (phone.length < 8 && phone.length>0){
        //     alert("Please enter a valid number")
        //     return
        // }
        

        
        

        const similarityThreshold = 0.6; // Adjust the threshold to define the level of similarity

        const similarVenues = venues.filter(venueName =>
            compareTwoStrings(venueName, name) >= similarityThreshold
        );

        if (similarVenues.length > 0) {
            // console.log('Similar venues found:', similarVenues);
            setCanAdd(false);
            setSimilarVenues(similarVenues);
            setVisible(true);
        } else {
            addVenue()
        }

        // if (canAdd === true){
        //     addVenue()
        // }

        

    //         fetch(api + 'addVenue', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',

    //     },
    //     body: JSON.stringify({
    //         name: name,
    //         location: location,
    //         type: type,
    //         price: price,
    //         added_by: localStorage.getItem('f_emp_id'),
    //         area: Area,
    //         price_text: price_text,
    //         description: description,
    //         phone: phone,
    //         contact_full_name: contactFN,
    //         contact_job_position: contactJB,
    //         contact_email: contactEmail
    //     })
    // }).then((res) => {
    //     console.log(res)
    //     if(res.status === 200){
    //         alert("Venue Added Successfully")
    //         setName("")
    //         setArea("")
    //         setLocation("")
    //         setType("")
    //         setPrice(0)
    //         setAb("")
    //         setPhone("")
    //         setPriceText("")
    //         setDescription("")
    //         setContactFN("")
    //         setContactJB("")
    //         setContactEmail("")
        
    //     }
    //     else{
    //         alert("Venue Not Added")
    //     }
    // })
    }
    // const onPhoneChange = (event)=>{
    //     if (phone.length != 11)
    //         alert("Please enter a valid number")
    //     else
    //     setPhone(event.target.value)
    // }

    const onPriceChange = (e) => {
        //check if the price is a number
        if (isNaN(e.target.value)){
            alert("Price must be a number")
            return
        }
        setPrice(e.target.value)
    }

    const onCapacityChange = (e) => {
        //check if the price is a number
        if (isNaN(e.target.value)){
            alert("Capacity must be a number")
            return
        }
        setCapacity(e.target.value)
    }


  return (
    <CRow>
      <CCol xs={12}>
        <h1>Add Venue</h1><br/>

        <CModal alignment="center" scrollable visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>There are similary venues</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CListGroup>
                {similarVenues.map((item, index) => (
                  <CListGroupItem key={index}>
                  {item}
                </CListGroupItem> 
                ))}
              </CListGroup>
            </CModalBody>
            <CModalFooter>
                <CButton color="dark" onClick={() => setVisible(false)}>Close</CButton>
                <CButton color="dark" onClick={addVenue}>Add anyway</CButton>
            </CModalFooter>

          </CModal>
    
        <CForm id="add-employee-form">
        <CCard>
            <CCardHeader><h3>Venue Details </h3></CCardHeader>
            <CCardBody>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Name<span className="text-danger">*</span></CFormLabel>
                    <CFormInput type="text" id="fn" placeholder=" Example: Hilton" onChange={(event)=>{setName(event.target.value)}} value={name} required/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Area<span className="text-danger">*</span></CFormLabel>
                    <CFormInput type="text" id="phone" placeholder=" Example: New Cairo" onChange={(event)=>{setArea(event.target.value)}} value={Area}required/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Location</CFormLabel>
                    <CFormInput type="text" id="ssn" placeholder="Example: Steet Name, Area, City" onChange={(event)=>{setLocation(event.target.value)}} value={location}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Contact full name</CFormLabel>
                    <CFormInput type="text" id="ssn" placeholder="Example: Jane Doe William" onChange={(event)=>{setContactFN(event.target.value)}} value={contactFN}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Contact job position</CFormLabel>
                    <CFormInput type="text" id="ssn" placeholder="Example: Sales Manager" onChange={(event)=>{setContactJB(event.target.value)}} value={contactJB}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Contact Phone number</CFormLabel>
                    <CFormInput type="text" id="ssn" placeholder="Example: 01001456263 " onChange= {(event)=>{setPhone(event.target.value)}} value={phone}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Contact Email</CFormLabel>
                    <CFormInput type="text" id="ssn" placeholder="jane.doe@gmail.com" onChange={(event)=>{setContactEmail(event.target.value)}} value={contactEmail}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Venue type<span className="text-danger">*</span></CFormLabel>
                    <CFormInput type="text" id="jp" placeholder="Example: hotel, villa, etc..." onChange={(event)=>{setType(event.target.value)}} value={type}required/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Capacity (must be a number, if you are not sure, enter an estimate) <span className="text-danger">*</span></CFormLabel>
                    <CFormInput type="text" id="jp" placeholder="Example: 500 person" onChange={onCapacityChange} value={capacity}/>
                </div>
                {/* <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Added by</CFormLabel>
                    <CFormInput type="email" id="emaial" onChange={(event)=>{setAb(event.target.value)}}/>
                </div> */}
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Price Range</CFormLabel>
                    <CFormInput type="email" id="emaial" onChange={(event)=>{setPriceText(event.target.value)}} value={price_text}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Price numeric In EGP (must be a number, optional)</CFormLabel>
                    <CFormInput type="email" id="emaial" onChange={onPriceChange} value={price}/>
                </div>
                <CFormTextarea
                    id="exampleFormControlTextarea1"
                    label="More info "
                    rows={6}
                    // text="Must be 8-20 words long."
                    onChange={(event)=>{setDescription(event.target.value)}}
                    value={description}
                ></CFormTextarea>
                
                </CCardBody>     
                </CCard><br/>
                


                <CButton color="primary" variant="outline" onClick={onAddVenueClicked}>Add Venue</CButton>
                </CForm>
                
                <br></br>
      </CCol>
    </CRow>
  )
}

export default AddVenue
