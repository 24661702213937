import React, { useEffect, useState } from 'react'
import { CButton, CCol, CRow, CTable, CFormInput, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CModal, CModalHeader, CModalTitle, CModalBody, CListGroup, CListGroupItem, CModalFooter } from '@coreui/react'
import { api } from 'src/configs'

const columns = [
  {
    key: 'id',
    label: 'id',
    _props: { scope: 'col' },
  },
    {
      key: 'client_name',
      label: 'Client Name',
      _props: { scope: 'col' },
    },
    {
      key: 'title',
      label: 'Title',
      _props: { scope: 'col' },
    },
      {
        key: 'company',
        label: 'Company Name',
        _props: { scope: 'col' },
      },
      {
        key: 'emp_name',
        label: 'Added by',
        _props: { scope: 'col' },
      },
      {
        key: 'added_at',
        label: 'Added At',
        _props: { scope: 'col' },
      },
      {
        key: 'Action',
        label: 'Action',
        _props: { scope: 'col' },
      },
  ]



const Leads = () => {
    const [items, setItems] = useState([]);
    const [filter, setfilter] = useState("");
    const [sortMode, setSortMode] = useState("newest to oldest");

    const [visible, setVisible] = useState(false);

    const [filter_name, setfilter_name] = useState("");
    const [filter_date, setfilter_date] = useState("");
    const [areFiltersActive, setAreFiltersActive] = useState(false);

    useEffect(() => {
        const fetchVenues = async () => {
          const response = await fetch(api + "getLeads");
          const data = await response.json();
          console.log(data)
          setItems(data);

        };
    
        fetchVenues();
      }, []);

      const create_table = (array) =>{
        var items = []
        array.forEach(element => {
          // console.log("element", element)
            items.push({
                id: element["id"],
                emp_name: element["employee_name"],
                client_name: element["full_name"],
                title: element["title"],
                type: element["type"],
                emp_id: element["emp_id"],
                company: element["company_name"],
                added_at: element['created_at'] != null ? element['created_at'].substring(0, 10) : " ",
                // acceptance: element["state"] == null? "pending" : element["state"] == true? "accepted" : "denied" ,
                ...(element["state"] == null)? {acceptance : <div style={{color: "#9c9400", fontWeight: 'bold'}}>pending</div>} : element["state"] == true? {acceptance : <div style={{color: "green", fontWeight: 'bold'}}>Sold</div> } :{acceptance : <div style={{color: "red", fontWeight: 'bold'}}>rejected</div> } ,
                Action: <CButton color="dark" href='/LeadProfile' onClick={() =>{localStorage.setItem("f_client_id", element["id"]); localStorage.setItem("f_l_emp_id", element["emp_id"]);}}>Lead</CButton>,
            },)
        });
        return items
      }
      var itemlist = create_table(items)

      if (filter != ""){
        itemlist = itemlist.filter(item => item.client_name.toLowerCase().includes(filter.toLowerCase()) || item.title.toLowerCase().includes(filter.toLowerCase()) || item.company.toLowerCase().includes(filter.toLowerCase()) || item.emp_name.toLowerCase().includes(filter.toLowerCase()) || item.added_at.toLowerCase().includes(filter.toLowerCase()))
      }

      if (sortMode == "newest to oldest"){
        itemlist.sort((a, b) => (a.id < b.id) ? 1 : -1)
      }

      if (sortMode == "oldest to newest"){
        itemlist.sort((a, b) => (a.id > b.id) ? 1 : -1)
      }

      const onAdvancedSortClicked = () =>{
        console.log("clicked")
        setVisible(true)
      }

      const onApplyClicked = () =>{
        console.log("clicked")
        setAreFiltersActive(true)
        setVisible(false)
      }

      if (areFiltersActive){
        if(filter_name != ""){
          itemlist = itemlist.filter(item => item.emp_name.toLowerCase().includes(filter_name.toLowerCase()))
        }
        if(filter_date != ""){
          console.log("yes")
          itemlist = itemlist.filter(item => item.added_at.toLowerCase().includes(filter_date.toLowerCase()))
        }

      }


  return (
    <CRow>
      <CModal alignment="center" scrollable visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Select your filter options</CModalTitle>
            </CModalHeader>
            <CModalBody>
            <CFormInput
              type="text"
              label="Employee Name"
              placeholder="Name"
              text="Copy and past the Name of the employee you wish to view their data"
              aria-describedby="exampleFormControlInputHelpInline"
              onChange={(event) => { setfilter_name(event.target.value) }}
              value={filter_name}
            />
            <br/>

              <CFormInput
              type="text"
              label="Date"
              placeholder="FORMAT : YYYY-MM-DD"
              text="example : 2023-01-01"
              aria-describedby="exampleFormControlInputHelpInline"
              onChange={(event) => { setfilter_date(event.target.value) }}
              value={filter_date}
            />
            
            
            </CModalBody>
            <CModalFooter>
              <CButton color="secondary" onClick={() => setVisible(false)}>Cancel</CButton>
              <CButton color='danger' onClick={()=>{setfilter_name(''); setfilter_date(''); setAreFiltersActive(false)}}>clear filters</CButton>
              <CButton color="primary" onClick={onApplyClicked}>Apply</CButton>
            </CModalFooter>

          </CModal>
      <CCol xs={12}>
        <h1>Leads</h1><br/>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CFormInput
            type="text"
            placeholder="search leads"
            aria-label="default input example"
            onChange={(event) => { setfilter(event.target.value) }}
          />
          <CDropdown style={{ marginLeft: '10px'}}>
            <CDropdownToggle href="#" color="primary" >sort</CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem onClick={()=>{setSortMode('newest to oldest')}}>newest to oldest</CDropdownItem>
              <CDropdownItem onClick={()=>{setSortMode('oldest to newest')}}>oldest to newest</CDropdownItem>
              {/* <CDropdownItem href="#">Something else here</CDropdownItem> */}
            </CDropdownMenu>
          </CDropdown>
          <CButton style={{width: '150px', marginLeft: '10px'}} onClick={onAdvancedSortClicked}>advanced sort</CButton>
        </div>
        <div className="table table-responsive"><CTable className="table table-striped table-hover" columns={columns} items={itemlist}/></div>
      </CCol>
    </CRow>
  )
}

export default Leads