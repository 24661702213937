import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormTextarea, CFormLabel } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell, cilPencil } from '@coreui/icons';
import { DocsExample } from 'src/components'
import { api } from 'src/configs'
import moment from 'moment'


const VenueProfile = () => {
  const [venue, setVenue] = useState(null);
  const [name, setName] = useState("");
  const [area, setArea] = useState("");
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const [price, setPrice] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [priceText, setPriceText] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [contactFN, setContactFN] = useState("");
  const [contactJB, setContactJB] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [capacity, setCapacity] = useState("");
  const [status, setStatus] = useState("");
  const [is_complete, setIs_complete] = useState("");
  const [active_comp, setActive_comp] = useState("");
  const [link, setLink] = useState("");

  const [editMode, setEditMode] = useState(false);

  const [updatedName, setUpdatedName] = useState(name);
  const [updatedArea, setUpdatedArea] = useState(area);
  const [updatedLocation, setUpdatedLocation] = useState(location);
  const [updatedType, setUpdatedType] = useState(type);
  let [updatedPrice, setUpdatedPrice] = useState(price);
  const [updatedPriceText, setUpdatedPriceText] = useState(priceText);
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [updatedPhone, setUpdatedPhone] = useState(phone);
  const [updatedContactFN, setUpdatedContactFN] = useState(contactFN);
  const [updatedContactJB, setUpdatedContactJB] = useState(contactJB);
  const [updatedContactEmail, setUpdatedContactEmail] = useState(contactEmail);
  const [updatedCapacity, setUpdatedCapacity] = useState(capacity);
  const [updatedLink, setUpdatedLink] = useState(link);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    // make a get request to api to get venue by id and send localstorage of venue_id as query using fetch
    fetch(api +"getCateringById?id="+ localStorage.getItem('f_c_id'), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log("data:",data)
        setVenue(data);
        setName(data[0].name);
        setPhone(data[0].phone);
        setDescription(data[0].description);
        setStatus(data[0].status);
        setLink(data[0].social_link);

        setUpdatedName(data[0].name);
        setUpdatedPrice(data[0].price);
        setUpdatedPhone(data[0].phone);
        setUpdatedDescription(data[0].description);
        setUpdatedLink(data[0].social_link);



      })
      .catch((error) => {
        console.error('Error:', error);
      });


  }, []);

  const setCompletion = (completion) => {
    fetch(api + "setVenueCompletion", {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      // The body of the request is JSON encoded
      body: JSON.stringify({
        venue_id: localStorage.getItem('f_v_id'),
        completion: completion
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.res === "Successfully updated venue") {
          alert("Venue updated successfully");
          window.location.href = '/venues';
        }
      }
      )
  }

  const setVenueStatus = (status) => {
    fetch(api + "setCateringstatus", {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      // The body of the request is JSON encoded
      body: JSON.stringify({
        id: localStorage.getItem('f_c_id'),
        status: status
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data === "success") {
            alert("status changed successfully");
            window.location.reload();
          }
      }
      )
  }


  const onDeleteVenueClicked = () => {
    const confirmation = window.confirm("Are you sure you want to delete this venue?");
    if (confirmation) {
      fetch(api + "deleteCatering?id=" + localStorage.getItem('f_c_id'), {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
      })
        .then(response => response.json())
        .then(data => {
          console.log(data.res)
          if (data === "success") {
            alert("Catering successfully");
            window.location.href = '/catering';
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };
  

  const updateVenue = () => {
    console.log("updateVenue called")

    // Create a payload object with the updated data
    const payload = {
        id: localStorage.getItem('f_c_id'),
        name: updatedName,
        phone: updatedPhone,
        description: updatedDescription,
        link: updatedLink,
    };
  
    // Make a PUT request to update the venue data
    fetch(api + 'updateCatering', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data == "success"){
          alert("success")
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const onCapacityChange = (e) => {
    //check if is a number
    if (isNaN(e.target.value)) {
      alert("Capacity must be a number");
      return;
    }

    setUpdatedCapacity(e.target.value);
  };

  const status_dict = {null: 'pending', false: 'rejected', true: 'approved'};


  return (
    <CRow>
    <CCol>
      <CCard>
        <CCardHeader className="display-6">
          Catering info
          <CButton color="secondary" onClick={toggleEditMode} className="ml-auto" style={{marginLeft: '10px'}}>
            <CIcon icon={cilPencil} />
          </CButton>
        </CCardHeader>
        <CCardBody>
          {venue && (
            <>
              <div className="form-group mb-4">
                <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Full Name
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={updatedName}
                    onChange={(e) => setUpdatedName(e.target.value)}
                  />
                ) : (
                  <p id="name">{name ? name : 'N/A'}</p>
                )}
              </div>
              <br />
              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Phone
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="area"
                    value={updatedPhone}
                    onChange={(e) => setUpdatedPhone(e.target.value)}
                  />
                ) : (
                  <p id="area">{phone}</p>
                )}
              </div>
              {/* <br /> */}
              {/* <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Type
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="area"
                    value={updatedType}
                    onChange={(e) => setUpdatedType(e.target.value)}
                  />
                ) : (
                  // <p id="area">{location}</p>
                  <p id="area">{type}</p>
                )}
              </div> */}
              <br />
              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Social Media Link
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="area"
                    value={updatedLink}
                    onChange={(e) => setUpdatedLink(e.target.value)}
                  />
                ) : (
                  // <p id="area">{location}</p>
                  <p id="area">{link}</p>
                )}
              </div>
              <br />

             

              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Description
                </CFormLabel>
                {editMode ? (
                  // <input
                  //   type="text"
                  //   className="form-control"
                  //   id="area"
                  //   value={updatedDescription}
                  //   onChange={(e) => setUpdatedLocation(e.target.value)}
                  // />
                  <CFormTextarea
                      id="exampleFormControlTextarea1"
                      value={updatedDescription}
                      rows={6}
                      contentEditable={true}
                      onChange={(e) => setUpdatedDescription(e.target.value)}
                      // text="Must be 8-20 words long."
                      
                  ></CFormTextarea>
                ) : (

                  <CFormTextarea
                      id="exampleFormControlTextarea1"
                      value={description}
                      rows={6}
                      contentEditable={false}
                      // text="Must be 8-20 words long."
                      
                  ></CFormTextarea>
                )}
                <br/>
                 <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Status
                </CFormLabel>
                <br/>
                {status == null ? 'Pending' : status ? 'Approved' : 'Rejected'}
              </div>
              <br />
              {/* Render other fields similarly */}
              {/* ... */}
              {editMode && (
                <>
                  <CButton color="primary" onClick={updateVenue}>
                    Update Catering
                  </CButton>
                  <br />
                  <br />
                </>
              )}
              
            </>
            
            
          )}
          {venue && (
            <>
                {
                status === null && (
                    <>
                        <CButton color="success" onClick={() => setVenueStatus(true)}>
                            Approved
                        </CButton>
                        <CButton color="danger" onClick={() => setVenueStatus(false)}>
                            Rejected
                        </CButton>
                    </>
                )
            
              }
              {
                status === true && (
                    <CButton color="danger" onClick={() => setVenueStatus(false)}>
                        Rejected
                    </CButton>
                )
              }
              {
                status === false && (
                    <CButton color="success" onClick={() => setVenueStatus(true)}>
                        Approved
                    </CButton>
                )
              }
              <br />
              <br />
              <CButton color="danger" onClick={onDeleteVenueClicked}>
                Delete Catering
                </CButton>
            </>
          )}
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  )
}

export default VenueProfile