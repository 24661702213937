import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCol, CRow, CButtonGroup, CCardFooter } from '@coreui/react'
import { CChart } from '@coreui/react-chartjs'
import { CChartLine } from '@coreui/react-chartjs'
import CIcon from '@coreui/icons-react'
import { getStyle, hexToRgba } from '@coreui/utils'
import {

  cilCloudDownload,

} from '@coreui/icons'
import { api } from 'src/configs'



const Statistics = () => {
  const [chartViewMode, setChartViewMode] = useState('Leads')
  const [chartTime, setChartTime] = useState('Year')
  const [receivedData, setReceivedData] = useState({})
  const [leadsByMonth, setLeadsByMonth] = useState({})
  const [leadsByDay, setLeadsByDay] = useState({})

  const [avgClients, setAvgClients] = useState(0)
  const [avgVenues, setAvgVenues] = useState(0)
  const [avgOrders, setAvgOrders] = useState(0)

  const [compOrders, setCompOrders] = useState(0)
  const [incomOrders, setIncomOrders] = useState(0)

  const [sumSalaries, setSumSalaries] = useState(0)
  const [sumOrderPrices, setSumOrderPrices] = useState(0)

  useEffect(() => {
    // const fetchStats = async () => {
    //   try {
    //     const response = await fetch(api + 'getEmpStats', {
    //       method: 'GET',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json',
    //       },
    //     });
  
    //     if (!response.ok) {
    //       throw new Error('Failed to fetch data');
    //     }
  
    //     const data = await response.json();
    //     console.log(data);
    //     setReceivedData(data);
  
    //     const leadsByMonth = {};
    //     const leadsByDay = {};
  
    //     let newestYear = 2023;
    //     let newestMonth = 1;
  
    //     for (const key in data) {
    //       if (data.hasOwnProperty(key)) {
    //         console.log(key, data[key]);
  
  
    //         data[key]['clients'].forEach((element) => {

    //           const yearMonth = element['date'].split('-');
    //           const year = parseInt(yearMonth[0]) || 0;
    //           const month = parseInt(yearMonth[1]) || 0;

    
    //           if (year > newestYear || (year === newestYear && month > parseInt(newestMonth))) {
    //             console.log('newest', year, month);
    //             newestYear = year;
    //             newestMonth = month.toString().padStart(2, '0');
    //           }


    //           const date = element['date'];
    //           const day = (date.split('-')[2] || '').padStart(2, '0');
  
    //           if (leadsByMonth[date.substring(0, 7)]) {
    //             leadsByMonth[date.substring(0, 7)] += parseInt(element['count']);
    //           } else {
    //             leadsByMonth[date.substring(0, 7)] = parseInt(element['count']);
    //           }
              
    //           if (year === newestYear && month === parseInt(newestMonth)) {
    //             if (leadsByDay[day]) {
    //               leadsByDay[day] += parseInt(element['count']);
    //             } else {
    //               leadsByDay[day] = parseInt(element['count']);
    //             }
    //           }
    //         });
    //       }
    //     }
  
    //     console.log(leadsByMonth);
    //     console.log(leadsByDay);
  
    //     setLeadsByMonth(leadsByMonth);
    //     setLeadsByDay(leadsByDay);
    //   } catch (error) {
    //     console.error('Error:', error);
    //   }
    // };
  
    // fetchStats();



    const fetchStats = async () => {
      try {
        const response = await fetch(api + 'getGeneralStats', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
  
        const data = await response.json();
        console.log(data);
        setAvgClients(data['avgClients']);
        setAvgVenues(data['avgVenues']);
        setAvgOrders(data['avgOrders']);

        setCompOrders(data.orderStatusRatio[0].completed_orders);
        setIncomOrders(data.orderStatusRatio[0].non_completed_orders);

        setSumSalaries(data['sumSalaries']);
        
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    fetchStats();



  }, []);

  // let chartData = {}

  // if (chartViewMode == 'Leads' && chartTime == 'Year') {
  //   chartData = {
  //     label: 'Number of leads',
  //           backgroundColor: hexToRgba(getStyle('--cui-info'), 10),
  //           borderColor: getStyle('--cui-info'),
  //           pointHoverBackgroundColor: getStyle('--cui-info'),
  //           borderWidth: 2,
  //           data: [
  //             leadsByMonth['2023-01'] ? leadsByMonth['2023-01'] : 0,
  //             leadsByMonth['2023-02'] ? leadsByMonth['2023-02'] : 0,
  //             leadsByMonth['2023-03'] ? leadsByMonth['2023-03'] : 0,
  //             leadsByMonth['2023-04'] ? leadsByMonth['2023-04'] : 0,
  //             leadsByMonth['2023-05'] ? leadsByMonth['2023-05'] : 0,
  //             leadsByMonth['2023-06'] ? leadsByMonth['2023-06'] : 0,
  //             leadsByMonth['2023-07'] ? leadsByMonth['2023-07'] : 0,
  //             leadsByMonth['2023-08'] ? leadsByMonth['2023-08'] : 0,
  //             leadsByMonth['2023-09'] ? leadsByMonth['2023-09'] : 0,
  //             leadsByMonth['2023-10'] ? leadsByMonth['2023-10'] : 0,
  //             leadsByMonth['2023-11'] ? leadsByMonth['2023-11'] : 0,
  //             leadsByMonth['2023-12'] ? leadsByMonth['2023-12'] : 0,
  //           ],
  //           fill: true,
  // }
  // }

  // if (chartViewMode == 'Leads' && chartTime == 'Month') {
  //   chartData = {
  //     label: 'Number of leads',
  //           backgroundColor: hexToRgba(getStyle('--cui-info'), 10),
  //           borderColor: getStyle('--cui-info'),
  //           pointHoverBackgroundColor: getStyle('--cui-info'),
  //           borderWidth: 2,
  //           data: [
              
  //           ],
  //           fill: true,
  // }
  // }

  // for (let i = 1; i <= 31; i++) {
  //   const day = i.toString().padStart(2, '0');
  //   chartData.data.push(leadsByDay[day] ? leadsByDay[day] : 0);
  // }


  return (
    <>
    {/* <CRow>
      <CCol xs={12}>
        <h1>Statistics</h1><br/>
        <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                General Stats
              </h4>
              <div className="small text-medium-emphasis">overall data</div>
            </CCol>
            <CCol sm={7} className="d-none d-md-block">
              <CButton color="primary" className="float-end">
                <CIcon icon={cilCloudDownload} />
              </CButton>
              <CButtonGroup className="float-end me-3">
                {['Year', 'Month'].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === chartTime}
                    onClick={() => setChartTime(value)}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>
              <CButtonGroup className="float-end me-3">
                {['Leads', 'Venues', 'Orders'].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === chartViewMode}
                    onClick={() => setChartViewMode(value)}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>
            </CCol>
          </CRow>
          <CChartLine
            style={{ height: '300px', marginTop: '40px' }}
            data={{
              labels : chartTime == 'Year' ? ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', "November", "December"] : ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", '22', '23', '24', '25', '26', '27', '28', '29', '30', "31"],
              datasets: [
                chartData

              ],
            }}
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  grid: {
                    drawOnChartArea: false,
                  },
                },
                y: {
                  ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    stepSize: Math.ceil(250 / 5),
                    max: 250,
                  },
                },
              },
              elements: {
                line: {
                  tension: 0.4,
                },
                point: {
                  radius: 0,
                  hitRadius: 10,
                  hoverRadius: 4,
                  hoverBorderWidth: 3,
                },
              },
            }}
          />
        </CCardBody>
        <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 4 }} className="text-center">

          </CRow>
        </CCardFooter>
      </CCard>
      </CCol>
    </CRow> */}

    <h1>Statistics</h1>
    <CChart
    style={{ width: '900px', marginTop: '40px' }}
    type="bar"
    data={{
      labels: ['AVG leads / day', 'AVG venues / day', 'AVG orders / day'],
      datasets: [
        {
          label: 'Averages',
          backgroundColor: '#f87979',
          data: [parseFloat(avgClients), parseFloat(avgVenues), parseFloat(avgOrders)],
        },
      ],
    }}
    labels="months"
/>
<br/><br/><br/><br/>

<CChart
  type="doughnut"
  style={{ width: '700px', marginTop: '40px' }}
  data={{
    labels: ['Completed Orders', 'Incomplete/pending Orders'],
    datasets: [
      {
        backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
        data: [parseInt(compOrders), incomOrders == null && compOrders == null? 1 : incomOrders == null ? 0 : parseInt(incomOrders)],
      },
    ],
  }}
/>
<br/><br/><br/><br/>
<CChart
    style={{ width: '900px', marginTop: '40px' }}
    type="bar"
    data={{
      labels: ['SUM ORDER PRICES','SUM SALARIES'],
      datasets: [
        {
          label: 'EXPENSES VS INCOME',
          backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
          data: [sumOrderPrices, sumSalaries],
        },
      ],
    }}
    labels="months"
/>

    </>
  )
}

export default Statistics