import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'

const columns = [
  {
    key: 'id',
    label: 'id',
    _props: { scope: 'col' },
  },
    {
      key: 'client_name',
      label: 'Client Name',
      _props: { scope: 'col' },
    },
    {
      key: 'title',
      label: 'Title',
      _props: { scope: 'col' },
    },
      {
        key: 'company',
        label: 'Company Name',
        _props: { scope: 'col' },
      },
      // {
      //   key: 'emp_name',
      //   label: 'Added by',
      //   _props: { scope: 'col' },
      // },
      {
        key: 'added_at',
        label: 'Added At',
        _props: { scope: 'col' },
      },
      {
        key: 'Action',
        label: 'Action',
        _props: { scope: 'col' },
      },
  ]




const MyLeads = () => {
    const [items, setItems] = useState([]);
    const [filter, setfilter] = useState("");

    const [sortMode, setSortMode] = useState("newest to oldest");

    useEffect(() => {
        fetch(api+ 'getClientsByEmpId?emp_id=' + localStorage.getItem('f_emp_id'), {

            method: 'GET',
            headers: {  
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
          console.log(data)
            setItems(data);
        }
        )
        .catch((error) => {
            console.error('Error:', error);
        }
        );

        
      }, []);

      const create_table = (array) =>{
        var items = []
        array.forEach(element => {
          console.log(element)
            items.push({
              id: element["id"],
              emp_name: element["employee_name"],
              client_name: element["full_name"],
              title: element["title"],
              type: element["type"],
              emp_id: element["emp_id"],
              company: element["company_name"] + " ",
              added_at: element['created_at'] != null ? element['created_at'].substring(0, 10) : " ",
              // acceptance: element["state"] == null? "pending" : element["state"] == true? "accepted" : "denied" ,
              ...(element["state"] == null)? {acceptance : <div style={{color: "#9c9400", fontWeight: 'bold'}}>pending</div>} : element["state"] == true? {acceptance : <div style={{color: "green", fontWeight: 'bold'}}>Sold</div> } :{acceptance : <div style={{color: "red", fontWeight: 'bold'}}>rejected</div> } ,
              Action: <CButton color="dark" href='/LeadProfile' onClick={() =>{localStorage.setItem("f_client_id", element["id"]); localStorage.setItem("f_l_emp_id", element["emp_id"]);}}>Lead</CButton>,
            },)
        });
        return items
      }
      var itemlist = create_table(items)

      if(filter != ""){
        itemlist = itemlist.filter((item) => {
            return item.client_name.toLowerCase().includes(filter.toLowerCase())
        })
      }

      if (sortMode == "newest to oldest"){
        itemlist.sort((a, b) => (a.id < b.id) ? 1 : -1)
      }

      if (sortMode == "oldest to newest"){
        itemlist.sort((a, b) => (a.id > b.id) ? 1 : -1)
      }

  return (
    <CRow>
      <CCol xs={12}>
        <h1>My Leads</h1><br/>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CFormInput
            type="text"
            placeholder="search leads"
            aria-label="default input example"
            onChange={(event) => { setfilter(event.target.value) }}
          />
          <CDropdown style={{ marginLeft: '10px'}}>
            <CDropdownToggle href="#" color="primary" >sort</CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem onClick={()=>{setSortMode('newest to oldest')}}>newest to oldest</CDropdownItem>
              <CDropdownItem onClick={()=>{setSortMode('oldest to newest')}}>oldest to newest</CDropdownItem>
              {/* <CDropdownItem href="#">Something else here</CDropdownItem> */}
            </CDropdownMenu>
          </CDropdown>
        </div>
        <br/><br/>
        <div className="table table-responsive"><CTable className="table table-striped table-hover" columns={columns} items={itemlist}/></div>
      </CCol>
    </CRow>
  )
}

export default MyLeads