import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput, CForm, CFormLabel, CModal, CModalHeader, CModalTitle, CModalBody, CListGroup, CListGroupItem  } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'



const AddFine = () => {
    const [reason, setReason] = useState("");
    const [amount, setAmount] = useState("");
    const [emp_id, setEmp_id] = useState("");

    const [emps, setEmps] = useState([])
    const [items, setItems] = useState([])
    const [visible, setVisible] = useState(false)


    useEffect (() => {
        fetch(`${api}getUsers`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          })
            .then((response) => response.json())
            .then((data) => {
              // console.log("data : ", data)
              setEmps(data)
              const temp = []
              data.forEach((element) => {
                // console.log('element', element)
                if(element.emp_id){
                  temp.push({ username: element.username, id: element.emp_id })
                }
              })
              setItems(temp)
              // console.log('items', items)
            })
            .catch((error) => {
              console.error('Error:', error)
            })
        }, [])

    const onAddVenueClicked = () =>{
            fetch(api + 'addFine', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',

        },
        body: JSON.stringify({
            reason: reason,
            amount: amount,
            emp_id: emp_id

        })
    }).then((res) => {
        // console.log(res)
        if(res.status === 200){
            alert("Fine Added Successfully")
            setAmount('')
            setReason('')
            setEmp_id('')
        }
        else{
            alert("Fine Not Added")
        }
    })
    }

    
    const onSelectEmp = (item) => {
        // console.log(item)
        setEmp_id(item.id)
    }

  return (
    <CRow>
          <CModal alignment="center" scrollable visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Select an employee to add Fine to ...</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CListGroup>
                {items.map((item, index) => (
                  <CListGroupItem key={index} onClick={() => onSelectEmp(item)} component="a">
                  {item.username}
                </CListGroupItem> 
                ))}
              </CListGroup>
            </CModalBody>

          </CModal>
      <CCol xs={12}>
        <h1>Add Fine</h1><br/>
    
        <CForm id="add-employee-form">
        <CCard>
            <CCardHeader><h4>Fine Details </h4></CCardHeader>
            <CCardBody>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Reason</CFormLabel>
                    <CFormInput value={reason} type="text" id="fn" placeholder="" onChange={(event)=>{setReason(event.target.value)}}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Amount</CFormLabel>
                    <CFormInput value={amount} type="text" id="phone" placeholder="" onChange={(event)=>{setAmount(event.target.value)}}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Employee</CFormLabel><br/>
                    <CButton size='sm' onClick={() =>{setVisible(!visible)}}>select employee</CButton><br/>
                    <CFormInput value={emp_id} type="text" id="ssn" placeholder="" onChange={(event)=>{setEmp_id(event.target.value)}}/>
                </div>
                </CCardBody>     
                </CCard><br/>
                


                <CButton color="danger" variant="outline" onClick={onAddVenueClicked}>Add Fine</CButton>
                </CForm>
                
                <br></br>
      </CCol>
    </CRow>
  )
}

export default AddFine
