import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormTextarea, CFormLabel } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell, cilPencil } from '@coreui/icons';
import { DocsExample } from 'src/components'
import { api } from 'src/configs'
import moment from 'moment'


const VenueProfile = () => {
  const [venue, setVenue] = useState(null);
  const [name, setName] = useState("");
  const [area, setArea] = useState("");
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const [price, setPrice] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [priceText, setPriceText] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [contactFN, setContactFN] = useState("");
  const [contactJB, setContactJB] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [capacity, setCapacity] = useState("");
  const [status, setStatus] = useState("");
  const [is_complete, setIs_complete] = useState("");
  const [active_comp, setActive_comp] = useState("");

  const [editMode, setEditMode] = useState(false);

  const [updatedName, setUpdatedName] = useState(name);
  const [updatedArea, setUpdatedArea] = useState(area);
  const [updatedLocation, setUpdatedLocation] = useState(location);
  const [updatedType, setUpdatedType] = useState(type);
  let [updatedPrice, setUpdatedPrice] = useState(price);
  const [updatedPriceText, setUpdatedPriceText] = useState(priceText);
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [updatedPhone, setUpdatedPhone] = useState(phone);
  const [updatedContactFN, setUpdatedContactFN] = useState(contactFN);
  const [updatedContactJB, setUpdatedContactJB] = useState(contactJB);
  const [updatedContactEmail, setUpdatedContactEmail] = useState(contactEmail);
  const [updatedCapacity, setUpdatedCapacity] = useState(capacity);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    // make a get request to api to get venue by id and send localstorage of venue_id as query using fetch
    fetch(api +"getVenueById?venue_id="+ localStorage.getItem('f_v_id'), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        console.log("received price : ", data.price)
        setVenue(data);
        setName(data[0].name);
        setArea(data[0].area);
        setLocation(data[0].location);
        setType(data[0].type);
        setPrice(data[0].price);
        setAddedBy(data[0].username);
        setPriceText(data[0].price_text);
        setDescription(data[0].description);
        setPhone(data[0].phone);
        setContactFN(data[0].contact_full_name);
        setContactJB(data[0].contact_job_position);
        setContactEmail(data[0].contact_email);
        setCapacity(data[0].capacity);
        setStatus(data[0].status);
        setIs_complete(data[0].is_complete);
        setActive_comp(data[0].is_complete);

        setUpdatedName(data[0].name);
        setUpdatedArea(data[0].area);
        setUpdatedLocation(data[0].location);
        setUpdatedType(data[0].type);
        setUpdatedPrice(data[0].price);
        setUpdatedPriceText(data[0].price_text);
        setUpdatedDescription(data[0].description);
        setUpdatedPhone(data[0].phone);
        setUpdatedContactFN(data[0].contact_full_name);
        setUpdatedContactJB(data[0].contact_job_position);
        setUpdatedContactEmail(data[0].contact_email);
        setUpdatedCapacity(data[0].capacity);

      })
      .catch((error) => {
        console.error('Error:', error);
      });


  }, []);

  const setCompletion = (completion) => {
    fetch(api + "setVenueCompletion", {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      // The body of the request is JSON encoded
      body: JSON.stringify({
        venue_id: localStorage.getItem('f_v_id'),
        completion: completion
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.res === "Successfully updated venue") {
          alert("Venue updated successfully");
          window.location.href = '/venues';
        }
      }
      )
  }

  const setVenueStatus = (status) => {
    fetch(api + "setVenueStatus", {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      // The body of the request is JSON encoded
      body: JSON.stringify({
        venue_id: localStorage.getItem('f_v_id'),
        status: status
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.res === "Successfully updated venue") {
          alert("Venue updated successfully");
          window.location.href = '/venues';
        }
      }
      )
  }


  const onDeleteVenueClicked = () => {
    const confirmation = window.confirm("Are you sure you want to delete this venue?");
    if (confirmation) {
      fetch(api + "deleteVenueById?venue_id=" + localStorage.getItem('f_v_id'), {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
      })
        .then(response => response.json())
        .then(data => {
          console.log(data.res)
          if (data.res === "Successfully deleted venue") {
            alert("Venue deleted successfully");
            window.location.href = '/venues';
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };
  

  const updateVenue = () => {
    if(updatedPrice == undefined || updatedPrice == ''){
      updatedPrice = 0;
    }


    if(isNaN(+updatedPrice)){
      alert("Price Numeric must be a number, put 0 if you don't know the exact price");
      return;
    }

    // Create a payload object with the updated data
    const payload = {
      venue_id: localStorage.getItem('f_v_id'),
      name: updatedName,
      area: updatedArea,
      location: updatedLocation,
      type: updatedType,
      price: updatedPrice,
      priceText: updatedPriceText,
      description: updatedDescription,
      phone: updatedPhone,
      contact_full_name: updatedContactFN,
      contact_job_position: updatedContactJB,
      contact_email: updatedContactEmail,
      capacity: updatedCapacity,
    };
  
    // Make a PUT request to update the venue data
    fetch(api + 'updateVenueById', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.res == "Successfully updated venue"){
          alert("Venue updated successfully")
          window.location.reload()
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const onCapacityChange = (e) => {
    //check if is a number
    if (isNaN(e.target.value)) {
      alert("Capacity must be a number");
      return;
    }

    setUpdatedCapacity(e.target.value);
  };

  const status_dict = {null: 'pending', false: 'rejected', true: 'approved'};


  return (
    <CRow>
    <CCol>
      <CCard>
        <CCardHeader className="display-6">
          Venue info
          <CButton color="secondary" onClick={toggleEditMode} className="ml-auto" style={{marginLeft: '10px'}}>
            <CIcon icon={cilPencil} />
          </CButton>
        </CCardHeader>
        <CCardBody>
          {venue && (
            <>
              <div className="form-group mb-4">
                <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Venue Name
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={updatedName}
                    onChange={(e) => setUpdatedName(e.target.value)}
                  />
                ) : (
                  <p id="name">{name ? name : 'N/A'}</p>
                )}
              </div>
              <br />
              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Area
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="area"
                    value={updatedArea}
                    onChange={(e) => setUpdatedArea(e.target.value)}
                  />
                ) : (
                  <p id="area">{area}</p>
                )}
              </div>
              <br />
              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Location
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="area"
                    value={updatedLocation}
                    onChange={(e) => setUpdatedLocation(e.target.value)}
                  />
                ) : (
                  // <p id="area">{location}</p>
                  <p id="area">{location}</p>
                )}
              </div>
              <br />

              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Contact Full Name
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="area"
                    value={updatedContactFN}
                    onChange={(e) => setUpdatedContactFN(e.target.value)}
                  />
                ) : (
                  <p id="area">{contactFN ? contactFN : 'N/A'}</p>
                )}
              </div>

              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Contact Phone number
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="area"
                    value={updatedPhone ? updatedPhone : 'N/A'}
                    onChange={(e) => setUpdatedPhone(e.target.value)}
                  />
                ) : (
                  <p id="area">{phone}</p>
                )}
              </div>

              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Contact Job Title
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="area"
                    value={updatedContactJB}
                    onChange={(e) => setUpdatedContactJB(e.target.value)}
                  />
                ) : (
                  <p id="area">{contactJB ? contactJB : 'N/A'}</p>
                )}
              </div>

              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Contact Email
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="area"
                    value={updatedContactEmail}
                    onChange={(e) => setUpdatedContactEmail(e.target.value)}
                  />
                ) : (
                  <p id="area">{contactEmail ? contactEmail : "N/A"}</p>
                )}
              </div>
              
              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Type
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="area"
                    value={updatedType}
                    onChange={(e) => setUpdatedType(e.target.value)}
                  />
                ) : (
                  <p id="area">{type ? type : "N/A"}</p>
                )}
              </div>
              <br />

              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Max Capacity
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="area"
                    value={updatedCapacity}
                    onChange={onCapacityChange}
                  />
                ) : (
                  <p id="area">{capacity ? capacity : "N/A"}</p>
                )}
              </div>
              <br />

              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  price Test
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="area"
                    value={updatedPriceText}
                    onChange={(e) => setUpdatedPriceText(e.target.value)}
                  />
                ) : (
                  <p id="area">{priceText ? priceText : "N/A"}</p>
                )}
              </div>
              <br />

              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  price Numeric
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="area"
                    value={updatedPrice}
                    onChange={(e) => setUpdatedPrice(e.target.value)}
                  />
                ) : (
                  <p id="area">{price != 0 ? price : 'N/A'}</p>
                )}
              </div>
              <br />

              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Description
                </CFormLabel>
                {editMode ? (
                  // <input
                  //   type="text"
                  //   className="form-control"
                  //   id="area"
                  //   value={updatedDescription}
                  //   onChange={(e) => setUpdatedLocation(e.target.value)}
                  // />
                  <CFormTextarea
                      id="exampleFormControlTextarea1"
                      value={updatedDescription}
                      rows={6}
                      contentEditable={true}
                      onChange={(e) => setUpdatedDescription(e.target.value)}
                      // text="Must be 8-20 words long."
                      
                  ></CFormTextarea>
                ) : (

                  <CFormTextarea
                      id="exampleFormControlTextarea1"
                      value={description}
                      rows={6}
                      contentEditable={false}
                      // text="Must be 8-20 words long."
                      
                  ></CFormTextarea>
                )}
              </div>
              <br />
              {/* Render other fields similarly */}
              {/* ... */}
              {editMode && (
                <>
                  <CButton color="primary" onClick={updateVenue}>
                    Update Venue
                  </CButton>
                  <br />
                  <br />
                </>
              )}
            </>
            
          )}
          {venue && (
            <>
              <div style={{display: 'flex', margin: '10px'}}>
                {
                  is_complete == false ? <div><CButton color="success" onClick={() =>{setCompletion(true)}}>Mark Complete</CButton></div> : <div><CButton color="warning" onClick={() =>{setCompletion(false)}}>Mark Incomplete</CButton></div>
                }
                {
                  [null, false, true].map((item, index) => {
                    if (item == status) return <CButton color="info" disabled>{status_dict[item]}</CButton>
                    return <CButton color="info" key={index} onClick={() =>{setVenueStatus(item)}}>{status_dict[item]}</CButton>
                  })

                }
                <CButton color="danger" onClick={onDeleteVenueClicked} style={{float: 'right'}}>Delete Venue</CButton>
              </div>
              <br />
              <br />
            </>
          )}
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  )
}

export default VenueProfile