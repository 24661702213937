import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormTextarea, CFormLabel } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell, cilPencil } from '@coreui/icons';
import { DocsExample } from 'src/components'
import { api } from 'src/configs'
import moment from 'moment'


const OrderProfile = () => {
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [client, setClient] = useState("");
    const [details, setDetails] = useState("");
    const [added_by, setAdded_by] = useState("");
    const [status, setStatus] = useState(null);

    const [editMode, setEditMode] = useState(false);
    const [client_id, setClient_id] = useState("");

    const [updatedName, setUpdatedName] = useState("");
    const [updatedType, setUpdatedType] = useState("");
    // const [updatedClient, setUpdatedClient] = useState("");
    const [updatedDetails, setUpdatedDetails] = useState("");
    const [updatedStatus, setUpdatedStatus] = useState(null);

    console.log(localStorage.getItem('f_o_id'))


  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
  
    fetch(api +"getOrderById?order_id="+ localStorage.getItem('f_o_id'), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data[0].name != undefined && data[0].type != undefined && data[0].client_id != undefined && data[0].details != undefined){
          setName(data[0].name)
          setType(data[0].type)
          setClient(data[0].client_name)
          setDetails(data[0].details)
          setAdded_by(data[0].emp_name)
          setClient_id(data[0].client_id)
          setStatus(data[0].status)

            setUpdatedName(data[0].name)
            setUpdatedType(data[0].type)
            setUpdatedDetails(data[0].details)
            setUpdatedStatus(data[0].status)
        }
        

      })
      .catch((error) => {
        console.error('Error:', error);
      });


  }, []);

  const onDeleteOrderClicked = () => {
    // make a delete request to api to delete venue by id and send localstorage of venue_id as query using fetch
    fetch(api +"deleteOrderById?order_id="+ localStorage.getItem('f_o_id'), {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log(data.res)
        if (data.res == "Order Deleted"){
          alert("Order deleted successfully")
          window.location.href = '/Orders'
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const updateOrder = () => {
    // Create a payload object with the updated data
    const payload = {
        order_id: localStorage.getItem('f_o_id'),
        name: updatedName,
        type: updatedType,
        details: updatedDetails,
        status: updatedStatus
    };

    console.log(payload)
  
    // Make a PUT request to update the venue data
    fetch(api + 'updateOrderById', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.res == "Order Updated"){
          alert("Order updated successfully")
          window.location.reload()
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const status_to_var = {"Pending": null, "Sold" : true, "Rejected": false}
  const var_to_status = {null: "Pending", true: "Sold", false: "Rejected"}

  console.log(updatedStatus)



  return (
    <CRow>
    <CCol>
      <CCard>
        <CCardHeader className="display-6">
          Order info
          <CButton color="secondary" onClick={toggleEditMode} className="ml-auto" style={{marginLeft: '10px'}}>
            <CIcon icon={cilPencil} />
          </CButton>
        </CCardHeader>
        <CCardBody>
          { (
            <>
              <div className="form-group mb-4">
                <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Order Name
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={updatedName}
                    onChange={(e) => setUpdatedName(e.target.value)}
                  />
                ) : (
                  <p id="name">{name}</p>
                )}
              </div>
              <br />
              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Client 
                </CFormLabel><br/>
                
                  <a id="area" href='/LeadProfile' onClick={() =>{localStorage.setItem('f_client_id', client_id)}}>{client}</a>
              </div>
              <br />
              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Added by
                </CFormLabel><br/>

                  <a id="area">{added_by}</a>

              </div>
              <br />
              
              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Type
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="area"
                    value={updatedType}
                    onChange={(e) => setUpdatedType(e.target.value)}
                  />
                ) : (
                  <p id="area">{type}</p>
                )}
              </div>
              <br />

              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Status
                </CFormLabel>
                {editMode ? (
                  <div>
                    {['Pending', 'Sold', 'Rejected'].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === var_to_status[updatedStatus]}
                    onClick={() => setUpdatedStatus(status_to_var[value])}
                  >
                    {value}
                  </CButton>
                ))}
                  </div>
                ) : (
                  <p id="area">{status == null ? <div style={{fontWeight: 'bold', color: '#6b6500'}}>pending</div> : status == true ? <div style={{fontWeight: 'bold', color: 'green'}}>Sold</div> : <div style={{fontWeight: 'bold', color: 'red'}}>rejected</div>}</p>
                )}
              </div>
              <br />

              <div className="form-group mb-4">
                <CFormLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Details
                </CFormLabel>
                {editMode ? (
                  <CFormTextarea
                      id="exampleFormControlTextarea1"
                      value={updatedDetails}
                      rows={6}
                      contentEditable={true}
                      onChange={(e) => setUpdatedDetails(e.target.value)}
                      // text="Must be 8-20 words long."
                      
                  ></CFormTextarea>
                ) : (

                  <CFormTextarea
                      id="exampleFormControlTextarea1"
                      value={details}
                      rows={6}
                      contentEditable={false}
                      // text="Must be 8-20 words long."
                      
                  ></CFormTextarea>
                )}
              </div>
              <br />
              {/* Render other fields similarly */}
              {/* ... */}
              {editMode && (
                <>
                  <CButton color="primary" onClick={updateOrder}>
                    Update Order
                  </CButton>
                  <br />
                  <br />
                </>
              )}
            </>
            
          )}
          {true && (
            <>
              <CButton color="danger" onClick={onDeleteOrderClicked}>
                Delete Order
              </CButton>
              <br />
              <br />
            </>
          )}
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  )
}

export default OrderProfile