import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { api } from 'src/configs'
import Flogo from 'src/assets/images/Flogo.png'

const Login = () => {
  const [username, setUsername] = useState(0)
  const [password, setPassword] = useState(0)
  const [Invalid, setInvalid] = useState(false)

  const navigate = useNavigate()

  const onClick = () => {
    // console.log(localStorage)
    const fetchEmployees = async () => {
      const sentData = { username: username }
      const response = await fetch(
        api + `getUser?username=${encodeURIComponent(sentData.username)}`,
      )
      const data = await response.json()
      console.log(data)
      if (data.length == 0) {
        setInvalid(true)
        return
      } else if (data[0]['password'] != password) {
        console.log("ues")
        setInvalid(true)
        return
      }
      else if (data[0]['password'] == password) {
        localStorage.setItem('f_role', data[0]['role'])
        localStorage.setItem('is_logged_in', true)
        localStorage.setItem('f_emp_id', data[0]['emp_id'])
        localStorage.setItem('f_username', data[0]['username'])
        if (data[0]['role'] == 'adm') {
          navigate('/dashboard')  
        } else if(data[0]['role'] == 'manager'){
          navigate('/ManagerDashboard')
        }
        else {
          navigate('/UserDashboard')
        }
      }
    }

    fetchEmployees()

    // if (username == "admin" && password == "admin"){
    //   localStorage.setItem("is_logged_in", true)
    //   navigate("/dashboard");
    // }
  }


  const onClick2 = () => {
    const loginUser = async () => {
      const request_options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: username, password: password })
      }
      

    }

    loginUser()

    // if (username == "admin" && password == "admin"){
    //   localStorage.setItem("is_logged_in", true)
    //   navigate("/dashboard");
    // }
  }

  return (
    <div>
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        {/* <div style={{ display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  marginTop:'-600px',
  left: '0',
  right: '0',
  top: '0',
  bottom: '0',

  zIndex: '1'}}>
        <img src={Flogo} width={350} alt="your-image-alt" />
      </div> */}
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm>
                      <h1>Login</h1>
                      <p className="text-medium-emphasis">Sign In to your account</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput
                          placeholder="Username"
                          autoComplete="username"
                          onChange={(event) => {
                            setUsername(event.target.value)
                          }}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          onChange={(event) => {
                            setPassword(event.target.value)
                          }}
                        />
                      </CInputGroup>
                      <CRow>
                        <CCol xs={6}>
                          <CButton color="primary" className="px-4" onClick={onClick}>
                            Login
                          </CButton>
                        </CCol>
                        <CCol xs={6} className="text-right">
                          <CButton color="link" className="px-0">
                            Forgot password?
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                    {
                      Invalid ? <p style={{ color: 'red' }}>Invalid username or password</p> : null
                    }
                  </CCardBody>
                </CCard>
                <CCard className="text-white bg-primary py-5">
                  <CCardBody className="text-center">
                    <div>
                      <h2>Fusion System</h2>

                      <p>
                        Welcome to the Fusion operations system, where all our work can be managed
                        and monitored easly and efficently.
                      </p>
                      {/* <Link to="/register"> */}
                        <CButton color="primary" className="mt-3" active tabIndex={-1}>
                          Request account
                        </CButton>
                      {/* </Link> */}
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </div>
  )
}

export default Login
