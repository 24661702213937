import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput, CForm, CFormLabel, CFormTextarea, CModal, CModalHeader, CModalTitle, CModalBody, CListGroup, CListGroupItem, CModalFooter  } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'



const AddOrder = () => {
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [added_by, setAb] = useState("");
    const [details, setDetails] = useState("");

    const [clients, setClients] = useState([]);
    const [visible, setVisible] = useState(false)
    const [items, setItems] = useState([])
    const [client, setClient] = useState("")

    const [clients_filter, setClientsFilter] = useState("")

    useEffect (() => {
        fetch(`${api}getClientsNameId`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("data : ", data)
              setItems(data)

            })
            .catch((error) => {
              console.error('Error:', error)
            })
        }, [])


    const onAddOrderClicked = () =>{
      if (client === ""){
        alert("Please select a client")
        return
      }
            fetch(api + 'addOrder', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',

        },
        body: JSON.stringify({
            name: name,
            type: type,
            added_by: localStorage.getItem('f_emp_id'),
            details: details,
            client_id: client

        })
    }).then((res) => {
        console.log(res)
        if(res.status === 200){
            alert("Order Added Successfully")
            setName("")
            setType("")
            setDetails("")

        }
        else{
            alert("Order Not Added")
        }
    })
    }

    const onSelectClientClicked = (item) =>{
        setClient(item.id)
    }



  return (
    <CRow>
      <CCol xs={12}>
        <h1>Add Order</h1><br/>

        <CModal alignment="center" scrollable visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Select a Client to make an order to ...</CModalTitle>
            </CModalHeader>
            <CFormInput type="text" placeholder='search clients' onChange={(e) => {setClientsFilter(e.target.value)}}/>
            <CModalBody>
              <CListGroup>
                {items.map((item, index) => (
                  (item.full_name.toLowerCase().includes(clients_filter.toLowerCase()) || item.id.toString().includes(clients_filter)) &&
                  <CListGroupItem key={index} onClick={() => onSelectClientClicked(item)} component="a">
                  {'ID:' + item.id + ', Name: ' +  item.full_name}
                </CListGroupItem> 
                ))}
              </CListGroup>
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => {setVisible(false); setClientsFilter("")}}>Cancel</CButton>
                <CButton color="secondary" onClick={() => {setVisible(false); setClientsFilter("")}}>Add client</CButton>
            </CModalFooter>

          </CModal>
    
        <CForm id="add-employee-form">
        <CCard>
            <CCardHeader><h4>Order Details </h4></CCardHeader>
            <CCardBody>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Name</CFormLabel>
                    <CFormInput type="text" id="fn" placeholder="" onChange={(event)=>{setName(event.target.value)}} value={name}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">type</CFormLabel>
                    <CFormInput type="text" id="jp" placeholder="" onChange={(event)=>{setType(event.target.value)}} value={type}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Client ID</CFormLabel>
                    <CButton style={{marginLeft: "20px"}} onClick={() =>{setVisible(!visible)}}>select client </CButton>
                    <CFormInput type="text" id="emaial" value={client}/>
                </div>
                <CFormTextarea
                    id="exampleFormControlTextarea1"
                    label="Details "
                    rows={6}
                    // text="Must be 8-20 words long."
                    onChange={(event)=>{setDetails(event.target.value)}}
                    value={details}
                ></CFormTextarea>
                
                </CCardBody>     
                </CCard><br/>
                


                <CButton color="primary" variant="outline" onClick={onAddOrderClicked}>Add Order</CButton>
                </CForm>
                
                <br></br>
      </CCol>
    </CRow>
  )
}

export default AddOrder
