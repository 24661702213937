import React from 'react'

// normal imports
import Dashboard from './views/dashboard/Dashboard'
import Employees from './views/Admin/Employees/Employees'
import AddEmployee from './views/Admin/AddEmployee/AddEmployee'
import Venues from './views/Admin/Venues/Venues'
import Sales from './views/Admin/Sales/Sales'
import Tasks from './views/Admin/Tasks/Tasks'
import AddTask from './views/Admin/AddTask/AddTask'
import Leads from './views/Admin/Leads/Leads'
import addVenue from './views/Admin/AddVenue/AddVenue'
import Statistics from './views/Admin/Statistics/Statistics'
import AddLead from './views/Admin/AddLead/AddLead'
import CustGpt from './views/Admin/CustGpt/CustGpt'
import LeaderBoard from './views/Admin/LeaderBoard/LeaderBoard'
import Finances from './views/Admin/Finances/Finances'
import TaskProfile from './views/Admin/TaskProfile/TaskProfile'
// import TrelloBoard from './views/Admin/TrelloBoard/TrelloBoard'
import LeadProfile from './views/Admin/LeadProfile/LeadProfile'
import VenueProfile from './views/Admin/VenueProfile/VenueProfile'
import AddLeadAdmin from './views/Admin/AddLeadAdmin/AddLeadAdmin'
import TaskProfileAdmin from './views/Admin/TaskProfileAdmin/TaskProfileAdmin'
import EmployeeProfile from './views/Admin/EmployeeProfile/EmployeeProfile'
import Bonuses from './views/Admin/Bonuses/Bonuses'
import AddBonus from './views/Admin/AddBonus/AddBonus'
import Fines from './views/Admin/Fines/Fines'
import AddFine from './views/Admin/AddFine/AddFine'
import Orders from './views/Admin/Orders/Orders'
import AddOrder from './views/Admin/AddOrder/AddOrder'
import OrderProfile from './views/Admin/OrderProfile/OrderProfile'
import EmployeeProfile2 from './views/Admin/EmployeeProfile2/EmployeeProfile2'
import Meetings from './views/Admin/Meetings/Meetings'
import AddMeeting from './views/Admin/AddMeeting/AddMeeting'
import MeetingProfile from './views/Admin/MeetingProfile/MeetingProfile'
import MyMeetings from './views/Admin/MyMeetings/MyMeetings'
import ManagerDashboard from './views/Manager/ManagerDashboard/ManagerDashboard'
import ManagerStatistics from './views/Manager/ManagerStatistics/ManagerStatistics'
import MyTasks from './views/User/MyTasks/MyTasks'
import MyLeads from './views/User/MyLeads/MyLeads'
import UserDashboard from './views/User/UserDashboard/UserDashboard'
import SystemUpdates from './views/Admin/SystemUpdates/SystemUpdates'
import SystemReports from './views/Admin/SystemReports/SystemReports'
import Reminders from './views/Admin/Reminders/Reminders'
import ReminderProfile from './views/Admin/ReminderProfile/ReminderProfile'
import AddReminder from './views/Admin/AddReminder/AddReminder'
import MyReminders from './views/Admin/MyReminders/MyReminders'
import Djs from './views/Admin/Djs/Djs'
import AddDj from './views/Admin/AddDj/AddDj'
import DjProfile from './views/Admin/DjProfile/DjProfile'
import Photographers from './views/Admin/Photographers/Photographers'
import AddPhotographer from './views/Admin/AddPhotographer/AddPhotographer'
import PhotographerProfile from './views/Admin/PhotographerProfile/PhotographerProfile'
import Catering from './views/Admin/Catering/Catering'
import AddCatering from './views/Admin/AddCatering/AddCatering'
import CateringProfile from './views/Admin/CateringProfile/CateringProfile'
 
// const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
// const Colors = React.lazy(() => import('./views/theme/colors/Colors'))
// const Typography = React.lazy(() => import('./views/theme/typography/Typography'))

// // Base
// const Accordion = React.lazy(() => import('./views/base/accordion/Accordion'))
// const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'))
// const Cards = React.lazy(() => import('./views/base/cards/Cards'))
// const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'))
// const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'))
// const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'))
// const Navs = React.lazy(() => import('./views/base/navs/Navs'))
// const Paginations = React.lazy(() => import('./views/base/paginations/Paginations'))
// const Placeholders = React.lazy(() => import('./views/base/placeholders/Placeholders'))
// const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'))
// const Progress = React.lazy(() => import('./views/base/progress/Progress'))
// const Spinners = React.lazy(() => import('./views/base/spinners/Spinners'))
// const Tables = React.lazy(() => import('./views/base/tables/Tables'))
// const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'))

// // Buttons
// const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'))
// const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'))
// const Dropdowns = React.lazy(() => import('./views/buttons/dropdowns/Dropdowns'))

// //Forms
// const ChecksRadios = React.lazy(() => import('./views/forms/checks-radios/ChecksRadios'))
// const FloatingLabels = React.lazy(() => import('./views/forms/floating-labels/FloatingLabels'))
// const FormControl = React.lazy(() => import('./views/forms/form-control/FormControl'))
// const InputGroup = React.lazy(() => import('./views/forms/input-group/InputGroup'))
// const Layout = React.lazy(() => import('./views/forms/layout/Layout'))
// const Range = React.lazy(() => import('./views/forms/range/Range'))
// const Select = React.lazy(() => import('./views/forms/select/Select'))
// const Validation = React.lazy(() => import('./views/forms/validation/Validation'))

// const Charts = React.lazy(() => import('./views/charts/Charts'))

// // Icons
// const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'))
// const Flags = React.lazy(() => import('./views/icons/flags/Flags'))
// const Brands = React.lazy(() => import('./views/icons/brands/Brands'))

// // Notifications
// const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'))
// const Badges = React.lazy(() => import('./views/notifications/badges/Badges'))
// const Modals = React.lazy(() => import('./views/notifications/modals/Modals'))
// const Toasts = React.lazy(() => import('./views/notifications/toasts/Toasts'))

//Custom
// const Employees = React.lazy(() => import('./views/Admin/Employees/Employees'))
// const AddEmployee = React.lazy(() => import('./views/Admin/AddEmployee/AddEmployee'))
// const Venues = React.lazy(() => import('./views/Admin/Venues/Venues'))
// const Sales = React.lazy(() => import('./views/Admin/Sales/Sales'))
// const Tasks = React.lazy(() => import('./views/Admin/Tasks/Tasks'))
// const AddTask = React.lazy(() => import('./views/Admin/AddTask/AddTask'))
// const Leads = React.lazy(() => import('./views/Admin/Leads/Leads'))
// const addVenue = React.lazy(() => import('./views/Admin/AddVenue/AddVenue'))
// const Statistics = React.lazy(() => import('./views/Admin/Statistics/Statistics'))
// const AddLead = React.lazy(() => import('./views/Admin/AddLead/AddLead'))
// const CustGpt = React.lazy(() => import('./views/Admin/CustGpt/CustGpt'))
// const LeaderBoard = React.lazy(() => import('./views/Admin/LeaderBoard/LeaderBoard'))
// const Finances = React.lazy(() => import('./views/Admin/Finances/Finances'))
// const TaskProfile = React.lazy(() => import('./views/Admin/TaskProfile/TaskProfile'))
// const TrelloBoard = React.lazy(() => import('./views/Admin/TrelloBoard/TrelloBoard'))
// const LeadProfile = React.lazy(() => import('./views/Admin/LeadProfile/LeadProfile'))
// const VenueProfile = React.lazy(() => import('./views/Admin/VenueProfile/VenueProfile'))
// const AddLeadAdmin = React.lazy(() => import('./views/Admin/AddLeadAdmin/AddLeadAdmin'))
// const TaskProfileAdmin = React.lazy(() => import('./views/Admin/TaskProfileAdmin/TaskProfileAdmin'))
// const EmployeeProfile = React.lazy(() => import('./views/Admin/EmployeeProfile/EmployeeProfile'))
// const Bonuses = React.lazy(() => import('./views/Admin/Bonuses/Bonuses'))
// const AddBonus = React.lazy(() => import('./views/Admin/AddBonus/AddBonus'))
// const Fines = React.lazy(() => import('./views/Admin/Fines/Fines'))
// const AddFine = React.lazy(() => import('./views/Admin/AddFine/AddFine'))
// const Orders = React.lazy(() => import('./views/Admin/Orders/Orders'))
// const AddOrder = React.lazy(() => import('./views/Admin/AddOrder/AddOrder'))
// const OrderProfile = React.lazy(() => import('./views/Admin/OrderProfile/OrderProfile'))
// const EmployeeProfile2 = React.lazy(() => import('./views/Admin/EmployeeProfile2/EmployeeProfile2'))
// const Meetings = React.lazy(() => import('./views/Admin/Meetings/Meetings'))
// const AddMeeting = React.lazy(() => import('./views/Admin/AddMeeting/AddMeeting'))  
// const MeetingProfile = React.lazy(() => import('./views/Admin/MeetingProfile/MeetingProfile'))

// const MyTasks = React.lazy(() => import('./views/User/MyTasks/MyTasks'))
// const MyLeads = React.lazy(() => import('./views/User/MyLeads/MyLeads'))
// const UserDashboard = React.lazy(() => import('./views/User/UserDashboard/UserDashboard'))

// const ManagerDashboard = React.lazy(() => import('./views/Manager/ManagerDashboard/ManagerDashboard'))
// const ManagerStatistics = React.lazy(() => import('./views/Manager/ManagerStatistics/ManagerStatistics'))

// const Widgets = React.lazy(() => import('./views/widgets/Widgets'))



const routes = [
  // { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  // { path: '/theme', name: 'Theme', element: Colors, exact: true },
  // { path: '/theme/colors', name: 'Colors', element: Colors },
  // { path: '/theme/typography', name: 'Typography', element: Typography },
  // { path: '/base', name: 'Base', element: Cards, exact: true },
  // { path: '/base/accordion', name: 'Accordion', element: Accordion },
  // { path: '/base/breadcrumbs', name: 'Breadcrumbs', element: Breadcrumbs },
  // { path: '/base/cards', name: 'Cards', element: Cards },
  // { path: '/base/carousels', name: 'Carousel', element: Carousels },
  // { path: '/base/collapses', name: 'Collapse', element: Collapses },
  // { path: '/base/list-groups', name: 'List Groups', element: ListGroups },
  // { path: '/base/navs', name: 'Navs', element: Navs },
  // { path: '/base/paginations', name: 'Paginations', element: Paginations },
  // { path: '/base/placeholders', name: 'Placeholders', element: Placeholders },
  // { path: '/base/popovers', name: 'Popovers', element: Popovers },
  // { path: '/base/progress', name: 'Progress', element: Progress },
  // { path: '/base/spinners', name: 'Spinners', element: Spinners },
  // { path: '/base/tables', name: 'Tables', element: Tables },
  // { path: '/base/tooltips', name: 'Tooltips', element: Tooltips },
  // { path: '/buttons', name: 'Buttons', element: Buttons, exact: true },
  // { path: '/buttons/buttons', name: 'Buttons', element: Buttons },
  // { path: '/buttons/dropdowns', name: 'Dropdowns', element: Dropdowns },
  // { path: '/buttons/button-groups', name: 'Button Groups', element: ButtonGroups },
  // { path: '/charts', name: 'Charts', element: Charts },
  // { path: '/forms', name: 'Forms', element: FormControl, exact: true },
  // { path: '/forms/form-control', name: 'Form Control', element: FormControl },
  // { path: '/forms/select', name: 'Select', element: Select },
  // { path: '/forms/checks-radios', name: 'Checks & Radios', element: ChecksRadios },
  // { path: '/forms/range', name: 'Range', element: Range },
  // { path: '/forms/input-group', name: 'Input Group', element: InputGroup },
  // { path: '/forms/floating-labels', name: 'Floating Labels', element: FloatingLabels },
  // { path: '/forms/layout', name: 'Layout', element: Layout },
  // { path: '/forms/validation', name: 'Validation', element: Validation },
  // { path: '/icons', exact: true, name: 'Icons', element: CoreUIIcons },
  // { path: '/icons/coreui-icons', name: 'CoreUI Icons', element: CoreUIIcons },
  // { path: '/icons/flags', name: 'Flags', element: Flags },
  // { path: '/icons/brands', name: 'Brands', element: Brands },
  // { path: '/notifications', name: 'Notifications', element: Alerts, exact: true },
  // { path: '/notifications/alerts', name: 'Alerts', element: Alerts },
  // { path: '/notifications/badges', name: 'Badges', element: Badges },
  // { path: '/notifications/modals', name: 'Modals', element: Modals },
  // { path: '/notifications/toasts', name: 'Toasts', element: Toasts },
  // { path: '/widgets', name: 'Widgets', element: Widgets },
  { path: '/Employees', name: 'Emplooyees', element: Employees },
  { path: '/AddEmployee', name: 'AddEmplooyee', element: AddEmployee },
  { path: '/Venues', name: 'Venues', element: Venues },
  { path: '/Sales', name: 'Sales', element: Sales },
  { path: '/Tasks', name: 'Tasks', element: Tasks },
  { path: '/AddTask', name: 'AddTask', element: AddTask },
  { path: '/Leads', name: 'Leads', element: Leads },
  { path: '/addVenue', name: 'addVenue', element: addVenue },
  { path: '/Statistics', name: 'Statistics', element: Statistics },
  { path: '/MyTasks', name: 'MyTasks', element: MyTasks },
  { path: '/AddLead', name: 'AddLead', element: AddLeadAdmin },
  { path: '/MyLeads', name: 'MyLeads', element: MyLeads },
  { path: '/CustGpt', name: 'CustGpt', element: CustGpt },
  { path: '/UserDashboard', name: 'UserDashboard', element: UserDashboard },
  { path: '/LeaderBoard', name: 'LeaderBoard', element: LeaderBoard },
  { path: '/Finances', name: 'Finances', element: Finances },
  { path: '/TaskProfile', name: 'TaskProfile', element: TaskProfile },
  // { path: '/TrelloBoard', name: 'TrelloBoard', element: TrelloBoard },
  { path: '/LeadProfile', name: 'LeadProfile', element: LeadProfile },
  { path: '/VenueProfile', name: 'VenueProfile', element: VenueProfile },
  { path: '/AddLeadAdmin', name: 'AddLeadAdmin', element: AddLeadAdmin },
  { path: '/TaskProfileAdmin', name: 'TaskProfileAdmin', element: TaskProfileAdmin },
  { path: '/EmployeeProfile', name: 'EmployeeProfile', element: EmployeeProfile },
  { path: '/ManagerDashboard', name: 'ManagerDashboard', element: ManagerDashboard },
  { path: '/Bonuses', name: 'Bonuses', element: Bonuses },
  { path: '/AddBonus', name: 'AddBonus', element: AddBonus },
  { path: '/Fines', name: 'Fines', element: Fines },
  { path: '/AddFine', name: 'AddFine', element: AddFine },
  { path: '/ManagerStatistics', name: 'ManagerStatistics', element: ManagerStatistics },
  { path: '/Orders', name: 'Orders', element: Orders },
  { path: '/AddOrder', name: 'AddOrder', element: AddOrder },
  { path: '/OrderProfile', name: 'OrderProfile', element: OrderProfile },
  { path: '/EmployeeProf', name: 'EmployeeProf', element: EmployeeProfile2 },
  { path: '/Meetings', name: 'Meetings', element: Meetings },
  { path: '/AddMeeting', name: 'AddMeeting', element: AddMeeting },
  { path: '/MeetingProfile', name: 'MeetingProfile', element: MeetingProfile },
  { path: '/SystemUpdates', name: 'SystemUpdates', element: SystemUpdates },
  { path: '/SystemReports', name: 'SystemReports', element: SystemReports },
  { path: '/MyMeetings', name: 'MyMeetings', element: MyMeetings },
  { path: '/Reminders', name: 'Reminders', element: Reminders },
  { path: '/ReminderProfile', name: 'ReminderProfile', element: ReminderProfile },
  { path: '/AddReminder', name: 'AddReminder', element: AddReminder },
  { path: '/MyReminders', name: 'MyReminders', element: MyReminders },
  { path: '/Djs', name: 'Djs', element: Djs },
  { path: '/AddDj', name: 'AddDj', element: AddDj },
  { path: '/DjProfile', name: 'DjProfile', element: DjProfile },
  { path: '/Photographers', name: 'Photographers', element: Photographers },
  { path: '/AddPhotographer', name: 'AddPhotographer', element: AddPhotographer },
  { path: '/PhotographerProfile', name: 'PhotographerProfile', element: PhotographerProfile },
  { path: '/PhotographerProfile', name: 'PhotographerProfile', element: PhotographerProfile },
  { path: '/Catering', name: 'Catering', element: Catering},
  { path: '/AddCatering', name: 'AddCatering', element: AddCatering},
  { path: '/CateringProfile', name: 'CateringProfile', element: CateringProfile}


]

export default routes
