import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput, CCallout } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'
import { CSVLink } from 'react-csv'


const headers = [
  {label: 'full_name', key: "full_name"},
  {label: 'salary', key: "salary"},
]

const headers2 = [
  {label: 'Employee name', key: "full_name"},
  {label: 'Client name', key: "client_name"},
  {label: 'Lead name', key: "name"},
  {label: 'Selling price', key: "price"},
]


const Finances = () => {
    const [csvReport, setCsvReport] = useState({filename: 'EmployeeData.csv', headers: headers, data: []})
    const [csvReport2, setCsvReport2] = useState({filename: 'LeadsData.csv', headers: headers2, data: []})

    useEffect(() => {
        //fetch data from api at /getEmpNamesAndSalaries
        const fetchEmployees = async () => {
          const response = await fetch(api + "getEmpNamesAndSalaries");
          const data = await response.json();
          console.log(data)
          setCsvReport(prevState => ({
            ...prevState,
            data: data
          }));
        }
        const fetchLeads = async () => {
          const response = await fetch(api + "getLeads");
          const data = await response.json();
          console.log(data)
          setCsvReport2(prevState => ({
            ...prevState,
            data: data
          }));
        }
        fetchEmployees();
        fetchLeads();


      }, []);

  return (
    <CRow>
  
      <CCol xs={12}>
        <h1>Finances</h1><br/>
        <CCallout color='primary'>Download CSV files to analyse data.</CCallout>
        <CSVLink {...csvReport}>Download Employee CSV file</CSVLink><br/>
        <CSVLink {...csvReport2}>Download Leads CSV file</CSVLink>
      </CCol>
    </CRow>
  )
}

export default Finances