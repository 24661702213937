import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput, CForm, CFormLabel, CModal, CModalHeader, CModalTitle, CModalBody, CListGroup, CModalFooter, CListGroupItem, CFormTextarea  } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'



const AddLeadAdmin = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [linked_in, setLinkedIn] = useState("");
    const [type, setType] = useState("");
    const [price, setPrice] = useState(0);
    const [lead_name, setLn] = useState("");
    const [title, setTitle] = useState("");
    const [comments, setComments] = useState("");
    const [company_name, setCompanyName] = useState("");
    const [emp_id, setEmp_id] = useState(0);
    const [emps, setEmps] = useState([])
    const [items, setItems] = useState([])
    const [visible, setVisible] = useState(false)
    const [description, setDesc] = useState("");


    useEffect (() => {
        fetch(`${api}getUsers`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          })
            .then((response) => response.json())
            .then((data) => {
            //   console.log("data : ", data)
              setEmps(data)
              const temp = []
              data.forEach((element) => {
                // console.log('element', element)
                if(element.role != 'adm'){
                  temp.push({ username: element.username, id: element.emp_id })
                }
              })
              setItems(temp)
            //   console.log('items', items)
            })
            .catch((error) => {
            console.error('Error:', error)
            })
        }, [])

    const onAddLeadClicked = async() =>{
        // make a post request to add clients with body name, email, phone, if response is Client Added alert ok
        // else alert not added

        // if(emp_id === 0){
        //     alert("Please Fill All Fields")
        //     return
        // }

        if (description.length > 500){
            alert("Description should be less than 500 characters")
            return
        }
        if (name == ''){
            alert("Please enter a name")
            return
        }
        // if (phone.length != 11 && phone.length>0){
        //     alert("Please enter a valid number")
        //     return
        // }


        await fetch(api + 'addClient', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
    
            },
            body: JSON.stringify({
                full_name: name,
                email: email,
                phone: phone,
                linked_in: linked_in,
                title: title,
                comments: comments,
                company_name: company_name,
                emp_id: localStorage.getItem('f_emp_id'),
            })
        }).then((res) => {
            console.log(res)
            if(res.status === 200){
                alert("Client Added Successfully")
                setName("")
                setEmail("")
                setPhone("")
                setLinkedIn("")
                setType("")
                setLn("")
                setTitle("")
                setComments("")
                setCompanyName("")
            }
            else{
                alert("Client Not Added")
            }
        })
        

    //     fetch(api + 'getClientByEmail?email=' + email, {

    //     method: 'GET',
    //     headers: {  
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    // })
    // .then(response => response.json())
    // .then(data => {
    //     // console.log(data)
    //     fetch (api + 'addLead', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',

    //     },
    //     body: JSON.stringify({
    //         client_id: data[0].id,
    //         type: type,
    //         price: price,
    //         emp_id: emp_id,
    //         name: lead_name,
    //         description: description
    //     })
    // }).then((res) => {
    //     console.log(res)
    //     if(res.status === 200){
    //         alert("Lead Added Successfully")
    //     }
    //     else{
    //         alert("Lead Not Added")
    //     }
    // })
    // }
    // )
    // .catch((error) => {
    //     console.error('Error:', error);
    // }
    // );

    }


    const onSelectEmp = (item) => {
        // console.log(item)
        setEmp_id(item.id)
    }

    console.log(localStorage)

return (
    <CRow>
         {/* <CModal alignment="center" scrollable visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Select an employee to add lead to ...</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CListGroup>
                {items.map((item, index) => (
                  <CListGroupItem key={index} onClick={() => onSelectEmp(item)} component="a">
                  {item.username}
                </CListGroupItem> 
                ))}
              </CListGroup>
            </CModalBody>

          </CModal> */}
          
    <CCol xs={12}>
        <h1>Add Lead</h1><br/>
    
        <CForm id="add-employee-form">
        <CCard>
            <CCardHeader><h4>Client info </h4></CCardHeader>
            <CCardBody>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Full Name<span className="text-danger">*</span></CFormLabel>
                    <CFormInput type="text" id="fn" placeholder="" onChange={(event)=>{setName(event.target.value)}} value={name}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Title</CFormLabel>
                    <CFormInput type="text" id="fn" placeholder="" onChange={(event)=>{setTitle(event.target.value)}} value={title}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Company/Organization name</CFormLabel>
                    <CFormInput type="text" id="fn" placeholder="" onChange={(event)=>{setCompanyName(event.target.value)}} value={company_name}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Email</CFormLabel>
                    <CFormInput type="text" id="phone" placeholder="" onChange={(event)=>{setEmail(event.target.value)}} value={email}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Phone<span className="text-danger">*</span></CFormLabel>
                    <CFormInput type="text" id="ssn" placeholder="" onChange={(event)=>{setPhone(event.target.value)}} value={phone}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Linked In (optional)</CFormLabel>
                    <CFormInput type="text" id="ssn" placeholder="" onChange={(event)=>{setLinkedIn(event.target.value)}} value={linked_in}/>
                </div>
                <div className="mb-3">
                                <CForm>
                <CFormTextarea
                    id="exampleFormControlTextarea1"
                    label="Comments on client "
                    rows={6}
                    // text="Must be 8-20 words long."
                    onChange={(event)=>{setComments(event.target.value)}}
                    value={comments}
                ></CFormTextarea>
                </CForm>
                </div>
                {/* <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Employee ID</CFormLabel><br/>
                    <CButton size='sm' onClick={() =>{setVisible(!visible)}}>select employee</CButton><br/>
                    <CFormInput contentEditable={false} value={emp_id == 0 ? '': emp_id} type="email" id="emaial" onChange={(event)=>{setEmp_id(event.target.value)}}/>
                </div> */}
                </CCardBody>     
                </CCard><br/><br/>


                {/* <CCard>
            <CCardHeader><h4>Lead info </h4></CCardHeader>
            <CCardBody>
            <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">name</CFormLabel>
                    <CFormInput type="text" id="jp" placeholder="" onChange={(event)=>{setLn(event.target.value)}}/>
                </div>
            <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">type</CFormLabel>
                    <CFormInput type="text" id="jp" placeholder="" onChange={(event)=>{setType(event.target.value)}}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">price</CFormLabel>
                    <CFormInput type="email" id="emaial" onChange={(event)=>{setPrice(event.target.value)}}/>
                </div>
                <div className="mb-3">
                                <CForm>
                <CFormTextarea
                    id="exampleFormControlTextarea1"
                    label="Description (optional)"
                    rows={3}
                    // text="Must be 8-20 words long."
                    onChange={(event)=>{setDesc(event.target.value)}}
                ></CFormTextarea>
                </CForm>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Employee ID</CFormLabel><br/>
                    <CButton size='sm' onClick={() =>{setVisible(!visible)}}>select employee</CButton><br/>
                    <CFormInput contentEditable={false} value={emp_id == 0 ? '': emp_id} type="email" id="emaial" onChange={(event)=>{setEmp_id(event.target.value)}}/>
                </div>
                </CCardBody>     
                </CCard><br/> */}


                    <CButton color="primary" variant="outline" onClick={onAddLeadClicked}>Add Lead</CButton>
                </CForm>
                
                <br></br><br/>
    </CCol>
    </CRow>
)
}

export default AddLeadAdmin
