import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'

const columns = [
    {
      key: 'id',
      label: 'id',
      _props: { scope: 'col' },
    },
    {
      key: 'full_name',
      label: 'Full Name',
      _props: { scope: 'col' },
    },
    {
      key: 'phone',
      label: 'Phone',
      _props: { scope: 'col' },
    },
    {
      key: 'Venue_id',
      label: 'Venue ID',
      _props: { scope: 'col' },
    },
    {
        key: 'acceptance',
        label: 'Acceptance',
        _props: { scope: 'col' },
      },
      {
        key: 'Action',
        label: 'Action',
        _props: { scope: 'col' },
      },
  ]



const Sales = () => {
    const [items, setItems] = useState([]);
    const [filter, setfilter] = useState("");

    useEffect(() => {
        const fetchVenues = async () => {
          const response = await fetch(api + "getVenueOwners");
          const data = await response.json();
          console.log(data)
          setItems(data);
        };
    
        fetchVenues();
      }, []);

      const create_table = (array) =>{
        var items = []
        array.forEach(element => {
            items.push({
                id: element["id"],
                full_name: element["full_name"],
                phone: element["phone"],
                Venue_id: element["venue_id"],
                ...(element["acceptance"] == null)? {acceptance : "pending" } : element["acceptance"] == true? {acceptance : "accepted" } :{acceptance : "denied" } ,
                Action: <CButton color="dark">Profile</CButton>,
            },)
        });
        return items
      }
      var itemlist = create_table(items)
  return (
    <CRow>
      <CCol xs={12}>
        <h1>Venue owners</h1><br/>
        <CFormInput type="text" placeholder={"Filter"} aria-label="default input example" onChange={(event)=>{setfilter(event.target.value)}}/><br/><br/>
        <div className="table table-responsive"><CTable className="table table-striped table-hover" columns={columns} items={itemlist}/></div>
      </CCol>
    </CRow>
  )
}

export default Sales