import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilStar } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'
import moment from 'moment'

const columns = [
    {
      key: 'id',
      label: 'id',
      _props: { scope: 'col' },
    },
    {
      key: 'Employee_name',
      label: 'Employee Name',
      _props: { scope: 'col' },
    },
    {
      key: 'Name',
      label: 'Task Name',
      _props: { scope: 'col' },
    },
    {
      key: 'Description',
      label: 'Description',
      _props: { scope: 'col' },
    },
      {
        key: 'end_date',
        label: 'Deadline',
        _props: { scope: 'col' },
      },
      {
        key: 'Completion',
        label: 'Completion',
        _props: { scope: 'col' },
      },
      {
        key: 'Score',
        label: 'Score',
        _props: { scope: 'col' },
      },
      {
        key: 'Action',
        label: 'Action',
        _props: { scope: 'col' },
      },
  ]



const Tasks = () => {
    const [items, setItems] = useState([]);
    const [filter, setfilter] = useState("");
    const currentDate = new Date();
    const currentTime = new Date();
    const currentTimeString = currentTime.toLocaleTimeString('en-US', {
      hour12: false,
    });
    console.log(currentTimeString);

    const isAfter = (timeString) => {
      if (timeString === null){
        return false
      }
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();
      const currentSecond = currentTime.getSeconds();
  
      const [variableHour, variableMinute, variableSecond] = timeString.split(':');
  
      if (
        currentHour > parseInt(variableHour, 10) ||
        (currentHour === parseInt(variableHour, 10) && currentMinute > parseInt(variableMinute, 10)) ||
        (currentHour === parseInt(variableHour, 10) &&
          currentMinute === parseInt(variableMinute, 10) &&
          currentSecond > parseInt(variableSecond, 10))
      ) {
        return true;
      }
  
      return false;
    };

    function isDateAfter(dateString) {
      const currentDate = new Date();
      const givenDate = new Date(dateString);
    
      // Get the year, month, and day components of the dates
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();
      const givenYear = givenDate.getFullYear();
      const givenMonth = givenDate.getMonth();
      const givenDay = givenDate.getDate();
    
      // Compare the year, month, and day components
      if (currentYear > givenYear) {
        return true;
      } else if (currentYear === givenYear) {
        if (currentMonth > givenMonth) {
          return true;
        } else if (currentMonth === givenMonth) {
          if (currentDay > givenDay) {
            return true;
          }
        }
      }
    
      return false;
    }

    function formatDateToYearMonthDay(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
    
      return `${year}-${month}-${day}`;
    }

    

    useEffect(() => {
        const fetchVenues = async () => {
          const response = await fetch(api + "getTasks");
          const data = await response.json();
          console.log(data)
          if(localStorage.getItem("f_username") == "Maged7816"){
            // console.log("MGD here")
            setItems(data.filter(item => item.added_by !== 37))
          }
          else{
            setItems(data);
          }
        };
    
        fetchVenues();
      }, []);

      const create_table = (array) => {
        var items = [];
        array.forEach((element) => {
          // console.log(element)
          // console.log(formatDateToYearMonthDay(currentDate))
          // console.log(formatDateToYearMonthDay(new Date(element["end_date"])))
          
          items.push({
            id: element["id"],
            Name: element["name"],
            Description: element["description"],
            end_date:
              (isDateAfter(element["end_date"]) || (formatDateToYearMonthDay(currentDate) == formatDateToYearMonthDay(new Date(element["end_date"])) && isAfter(element['end_time']))) && element['completion'] == 0 ? <div style={{ color: "red" }}>{formatDateToYearMonthDay(new Date(element["end_date"]))}</div> : <div>{formatDateToYearMonthDay(new Date(element["end_date"]))}</div>,
            Employee_id: element["emp_id"],
            Score: element["score"] === null ? "unscored" :
              <>{Array.from({ length: Number(element['score']) }, (_, index) => <CIcon key={index} icon={cilStar} />)}</>,
            Employee_name: element["full_name"],
            Completion: element["completion"] === 1 ? <div style={{ color: "green" }}>complete</div> : <div>incomplete</div>,
            Action: <CButton color="dark" href='/TaskProfileAdmin' onClick={() => { localStorage.setItem("f_t_id", element["id"]) }}>Task</CButton>
          });
        });
        return items;
      };
      var itemlist = create_table(items)
  return (
    <CRow>
      <CCol xs={12}>
        <h1>Tasks</h1><br/>
        <CFormInput type="text" placeholder={"Filter"} aria-label="default input example" onChange={(event)=>{setfilter(event.target.value)}}/><br/><br/>
        <div className="table table-responsive"><CTable className="table table-striped table-hover" columns={columns} items={itemlist}/></div>
      </CCol>
    </CRow>
  )
}

export default Tasks