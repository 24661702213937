import React, { useEffect, useState } from 'react'

import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { CChartLine } from '@coreui/react-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils'
import CIcon from '@coreui/icons-react'
import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibGoogle,
  cibFacebook,
  cibLinkedin,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cibTwitter,
  cilCloudDownload,
  cilPeople,
  cilUser,
  cilUserFemale,
} from '@coreui/icons'

import avatar1 from 'src/assets/images/avatars/1.jpg'
import avatar2 from 'src/assets/images/avatars/2.jpg'
import avatar3 from 'src/assets/images/avatars/3.jpg'
import avatar4 from 'src/assets/images/avatars/4.jpg'
import avatar5 from 'src/assets/images/avatars/5.jpg'
import avatar6 from 'src/assets/images/avatars/6.jpg'

import WidgetsBrand from '../widgets/WidgetsBrand'
import WidgetsDropdown from '../widgets/WidgetsDropdown'
import { api } from 'src/configs'


const Dashboard = () => {
  //use effect function to fetch venue data from api and set state
  const [Venues, setVenues] = useState([]);
  const [Leads, setLeads] = useState([]);

  const [leadsByMonth, setLeadsByMonth] = useState({});
  const [numOfLeadsThisYear, setNumOfLeadsThisYear] = useState(0);

  const [numOfVenuesThisYear, setNumOfVenuesThisYear] = useState(0);
  const [venuesByMonth, setVenuesByMonth] = useState({});

  const [numOfOrdersThisYear, setNumOfOrdersThisYear] = useState(0);
  const [ordersByMonth, setOrdersByMonth] = useState({});
  const [completedOrdersByMonth, setCompletedOrdersByMonth] = useState({});
  const [rejectedOrdersByMonth, setRejectedOrdersByMonth] = useState({});

  const [numOfTasksThisYear, setNumOfTasksThisYear] = useState(0);
  const [tasksByMonth, setTasksByMonth] = useState({});
  const [completedTasksByMonth, setCompletedTasksByMonth] = useState({});
  const [todayVenues, setTodayVenues] = useState([]);
  const [todayClients, setTodayClients] = useState([]);
  const [todayEntertainers, setTodayEntertainers] = useState([]);
  const [todayPhotographers, setTodayPhotographers] = useState([]);

  const [chartViewMode, setChartViewMode] = useState("Leads");

  useEffect(() => {
  //   const fetchVenues = async () => {
  //     const response = await fetch(api + "getVenues");
  //     const data = await response.json();
  //     if (data){
  //       // console.log(data)
  //       setNumOfVenuesThisYear(data.length)
  //       const venuesByMonth = data.reduce((acc, venue) => {
  //         const month = venue.created_at.slice(0, 7); // extract month from date string
  //         if (!acc[month]) {
  //           acc[month] = 1;
  //         } else {
  //           acc[month] += 1;
  //         }
  //         return acc;
  //       }, {});

  //       // console.log (venuesByMonth)

  //       setVenuesByMonth(venuesByMonth);


  //       setVenues(data.sort((a, b) => (a.id > b.id) ? 1 : -1).slice(0, 5))
  //     }
  //   };

  //   const fetchLeads = async () => {
  //     const response = await fetch(api + "getLeads");
  //     const data = await response.json();
  //     if (data){
  //       console.log("Leads", data)
  //       setNumOfLeadsThisYear(data.length)

  //       const leadsByMonth = data.reduce((acc, lead) => {
  //           const month = lead.created_at.slice(0, 7); // extract month from date string
  //           if (!acc[month]) {
  //             acc[month] = 1;
  //           } else {
  //             acc[month] += 1;
  //           }
  //           return acc;
  //         }, {});

  //         setLeadsByMonth(leadsByMonth);


  //       setLeads(data.sort((a, b) => (a.id > b.id) ? 1 : -1).slice(0, 5))
  //     }
  //   };

  //   const fetchOrders = async () => {
  //     const response = await fetch(api + "getOrders");
  //     const data = await response.json();
  //     if (data){
  //       console.log("Orders", data)
  //       setNumOfOrdersThisYear(data.length)

  //       const ordersByMonth = data.reduce((acc, order) => {
  //           const month = order.created_at.slice(0, 7); // extract month from date string
  //           if (!acc[month]) {
  //             acc[month] = 1;
  //           } else {
  //             acc[month] += 1;
  //           }
  //           return acc;
          
  //       }, {});

  //       const completedOrdersByMonth = data.reduce((acc, order) => {
  //         const month = order.created_at.slice(0, 7); // extract month from date string
  //         if (order.status == true){
  //           if (!acc[month]) {
  //             acc[month] = 1;
  //           } else {
  //             acc[month] += 1;
  //           }
  //         }
  //         return acc;

  //     }, {});

  //     const rejectedOrdersByMonth = data.reduce((acc, order) => {
  //       const month = order.created_at.slice(0, 7); // extract month from date string
  //       if (order.status == false){
  //         if (!acc[month]) {
  //           acc[month] = 1;
  //         } else {
  //           acc[month] += 1;
  //         }
  //       }
  //       return acc;

  //   }, {});

  //       setOrdersByMonth(ordersByMonth);
  //       setCompletedOrdersByMonth(completedOrdersByMonth);
  //       setRejectedOrdersByMonth(rejectedOrdersByMonth);

  //   };
  // }


  //   fetchVenues();
  //   fetchLeads();
  //   fetchOrders();

  const fetchVenues = async () => {
        const response = await fetch(api + "getVenueStats");
        const data = await response.json();
        if (data){
        // console.log("🚀 ~ file: Dashboard.js:191 ~ fetchVenues ~ data:", data)
        setNumOfVenuesThisYear(data.totalVenues)
        setVenuesByMonth(data.venuesByMonth);
        setVenues(data.latestVenues)
        }
      };
  
      const fetchLeads = async () => {
        const response = await fetch(api + "getLeadStats");
        const data = await response.json();
        if (data){
          // console.log("🚀 ~ file: Dashboard.js:191 ~ fetchLeads ~ data:", data)
          setNumOfLeadsThisYear(data.totalLeads)
          setLeadsByMonth(data.leadsByMonth);
          setLeads(data.latestLeads)
        }
      };

      const fetchOrders = async () => {
        const response = await fetch(api + "getOrderStats");
        const data = await response.json();
        if (data){
          // console.log("🚀 ~ file: Dashboard.js:191 ~ fetchOrders ~ data:", data)
          setNumOfOrdersThisYear(data.totalOrders)
          setOrdersByMonth(data.ordersByMonth);
          setCompletedOrdersByMonth(data.completedOrdersByMonth);
          setRejectedOrdersByMonth(data.rejectedOrdersByMonth);
        }
      };

      const fetchTodayActivity = async () => {
        const response = await fetch(api + "getTodayActivities");
        const data = await response.json();
        if (data){
          console.log("activity: ", data)
          setTodayVenues(data.venues_today)
          setTodayClients(data.clients_today)
          setTodayEntertainers(data.djs_today)
          setTodayPhotographers(data.photographers_today)

        }
      };



      fetchVenues();
      fetchLeads();
      fetchOrders();
      fetchTodayActivity();


  }, []);


  const leads_columns = [
    // {
    //   key: 'emp_name',
    //   label: 'Employee Name',
    //   _props: { scope: 'col' },
    // },
    {
      key: 'client_name',
      label: 'Client Name',
      _props: { scope: 'col' },
    },
    {
      key: 'title',
      label: 'Title',
      _props: { scope: 'col' },
    },
    {
      key: 'company',
      label: 'Company Name',
      _props: { scope: 'col' },
    },
    {
      key: 'added_by',
      label: 'Added by',
      _props: { scope: 'col' },
    },
      {
        key: 'added_at',
        label: 'Added At',
        _props: { scope: 'col' },
      }
    ]
    const create_Leads_items = (array) =>{
      var items = []
      array.forEach(element => {
          items.push({
            emp_name: element["employee_name"],
                  client_name: element["full_name"],
                  title: element["title"],
                  type: element["type"],
                  emp_id: element["emp_id"],
                  company: element["company_name"],
                  added_by: element["emp_name"],
                  added_at: element['created_at'] != null ? element['created_at'].substring(0, 10) : " ",
          //   ...(element["acceptance"] == null)? {acceptance : "pending" } : element["acceptance"] == true? {acceptance : "sold" } :{acceptance : "rejected" } ,
          },)
      });
      return items
    }
    const leads_items = create_Leads_items(Leads)


  const venue_columns = [
    {
      key: 'Name',
      label: 'Name',
      _props: { scope: 'col' },
    },
    {
      key: 'Area',
      label: 'Area',
      _props: { scope: 'col' },
    },
      {
        key: 'type',
        label: 'type',
        _props: { scope: 'col' },
      },
      {
        key: 'added_by',
        label: 'Added by',
        _props: { scope: 'col' },
      },
  ]
  const create_venue_items = (array) =>{
    var items = []
    array.forEach(element => {
        items.push({
            Name: element["name"],
            type: element["type"],
            Area: element["area"],
            added_by: element["emp_name"],
            Action: <CButton color="dark">Profile</CButton>,
        },)
    });
    return items
  }
  const venue_items = create_venue_items(Venues)

  const Entertainres_columns = [
    {
      key: 'Name',
      label: 'Name',
      _props: { scope: 'col' },
    },
    {
      key: 'social',
      label: 'Link',
      _props: { scope: 'col' },
    },
      {
        key: 'type',
        label: 'type',
        _props: { scope: 'col' },
      },
      {
        key: 'added_by',
        label: 'Added by',
        _props: { scope: 'col' },
      },
  ]

  const create_entertainers_items = (array) =>{
    var items = []
    array && array.forEach(element => {
        items.push({
            Name: element["full_name"],
            type: element["type"],
            social: element["social_link"],
            added_by: element["emp_name"] ? element["emp_name"] : "N/A",
            Action: <CButton color="dark">Profile</CButton>,
        },)
    });
    return items
  }

  const todayEntertainersItems = create_entertainers_items(todayEntertainers)


  const Photographers_columns = [
    {
      key: 'Name',
      label: 'Name',
      _props: { scope: 'col' },
    },
      {
        key: 'type',
        label: 'type',
        _props: { scope: 'col' },
      },
      {
        key: 'phone',
        label: 'Phone',
        _props: { scope: 'col' },
      },
      {
        key: 'added_by',
        label: 'Added by',
        _props: { scope: 'col' },
      },
  ]

  const create_photographers_items = (array) =>{
    var items = []
    array && array.forEach(element => {
        items.push({
            Name: element["full_name"],
            type: element["type"],
            phone: element["phone"],
            added_by: element["emp_name"] ? element["emp_name"] : "N/A",
            Action: <CButton color="dark">Profile</CButton>,
        },)
    });
    return items
  }

  const photographersTodayItems = create_photographers_items(todayPhotographers)



  const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

  // const progressExample = [
  //   { title: 'Visits', value: '29.703 Users', percent: 40, color: 'success' },
  //   { title: 'Unique', value: '24.093 Users', percent: 20, color: 'info' },
  //   { title: 'Pageviews', value: '78.706 Views', percent: 60, color: 'warning' },
  //   { title: 'New Users', value: '22.123 Users', percent: 80, color: 'danger' },
  //   { title: 'Bounce Rate', value: 'Average Rate', percent: 40.15, color: 'primary' },
  // ]

  // const progressGroupExample1 = [
  //   { title: 'Monday', value1: 34, value2: 78 },
  //   { title: 'Tuesday', value1: 56, value2: 94 },
  //   { title: 'Wednesday', value1: 12, value2: 67 },
  //   { title: 'Thursday', value1: 43, value2: 91 },
  //   { title: 'Friday', value1: 22, value2: 73 },
  //   { title: 'Saturday', value1: 53, value2: 82 },
  //   { title: 'Sunday', value1: 9, value2: 69 },
  // ]

  // const progressGroupExample2 = [
  //   { title: 'Male', icon: cilUser, value: 53 },
  //   { title: 'Female', icon: cilUserFemale, value: 43 },
  // ]

  // const progressGroupExample3 = [
  //   { title: 'Organic Search', icon: cibGoogle, percent: 56, value: '191,235' },
  //   { title: 'Facebook', icon: cibFacebook, percent: 15, value: '51,223' },
  //   { title: 'Twitter', icon: cibTwitter, percent: 11, value: '37,564' },
  //   { title: 'LinkedIn', icon: cibLinkedin, percent: 8, value: '27,319' },
  // ]

  // const tableExample = [
  //   {
  //     avatar: { src: avatar1, status: 'success' },
  //     user: {
  //       name: 'Yiorgos Avraamu',
  //       new: true,
  //       registered: 'Jan 1, 2021',
  //     },
  //     country: { name: 'USA', flag: cifUs },
  //     usage: {
  //       value: 50,
  //       period: 'Jun 11, 2021 - Jul 10, 2021',
  //       color: 'success',
  //     },
  //     payment: { name: 'Mastercard', icon: cibCcMastercard },
  //     activity: '10 sec ago',
  //   },
  //   {
  //     avatar: { src: avatar2, status: 'danger' },
  //     user: {
  //       name: 'Avram Tarasios',
  //       new: false,
  //       registered: 'Jan 1, 2021',
  //     },
  //     country: { name: 'Brazil', flag: cifBr },
  //     usage: {
  //       value: 22,
  //       period: 'Jun 11, 2021 - Jul 10, 2021',
  //       color: 'info',
  //     },
  //     payment: { name: 'Visa', icon: cibCcVisa },
  //     activity: '5 minutes ago',
  //   },
  //   {
  //     avatar: { src: avatar3, status: 'warning' },
  //     user: { name: 'Quintin Ed', new: true, registered: 'Jan 1, 2021' },
  //     country: { name: 'India', flag: cifIn },
  //     usage: {
  //       value: 74,
  //       period: 'Jun 11, 2021 - Jul 10, 2021',
  //       color: 'warning',
  //     },
  //     payment: { name: 'Stripe', icon: cibCcStripe },
  //     activity: '1 hour ago',
  //   },
  //   {
  //     avatar: { src: avatar4, status: 'secondary' },
  //     user: { name: 'Enéas Kwadwo', new: true, registered: 'Jan 1, 2021' },
  //     country: { name: 'France', flag: cifFr },
  //     usage: {
  //       value: 98,
  //       period: 'Jun 11, 2021 - Jul 10, 2021',
  //       color: 'danger',
  //     },
  //     payment: { name: 'PayPal', icon: cibCcPaypal },
  //     activity: 'Last month',
  //   },
  //   {
  //     avatar: { src: avatar5, status: 'success' },
  //     user: {
  //       name: 'Agapetus Tadeáš',
  //       new: true,
  //       registered: 'Jan 1, 2021',
  //     },
  //     country: { name: 'Spain', flag: cifEs },
  //     usage: {
  //       value: 22,
  //       period: 'Jun 11, 2021 - Jul 10, 2021',
  //       color: 'primary',
  //     },
  //     payment: { name: 'Google Wallet', icon: cibCcApplePay },
  //     activity: 'Last week',
  //   },
  //   {
  //     avatar: { src: avatar6, status: 'danger' },
  //     user: {
  //       name: 'Friderik Dávid',
  //       new: true,
  //       registered: 'Jan 1, 2021',
  //     },
  //     country: { name: 'Poland', flag: cifPl },
  //     usage: {
  //       value: 43,
  //       period: 'Jun 11, 2021 - Jul 10, 2021',
  //       color: 'success',
  //     },
  //     payment: { name: 'Amex', icon: cibCcAmex },
  //     activity: 'Last week',
  //   },
  // ]


  let chartData = {}
  if (chartViewMode === 'Leads') {
    chartData = [{
            label: 'Number of leads',
                  backgroundColor: hexToRgba(getStyle('--cui-info'), 10),
                  borderColor: getStyle('--cui-info'),
                  pointHoverBackgroundColor: getStyle('--cui-info'),
                  borderWidth: 2,
                  data: [
                    leadsByMonth['2023-01'] ? leadsByMonth['2023-01'] : 0,
                    leadsByMonth['2023-02'] ? leadsByMonth['2023-02'] : 0,
                    leadsByMonth['2023-03'] ? leadsByMonth['2023-03'] : 0,
                    leadsByMonth['2023-04'] ? leadsByMonth['2023-04'] : 0,
                    leadsByMonth['2023-05'] ? leadsByMonth['2023-05'] : 0,
                    leadsByMonth['2023-06'] ? leadsByMonth['2023-06'] : 0,
                    leadsByMonth['2023-07'] ? leadsByMonth['2023-07'] : 0,
                    leadsByMonth['2023-08'] ? leadsByMonth['2023-08'] : 0,
                    leadsByMonth['2023-09'] ? leadsByMonth['2023-09'] : 0,
                    leadsByMonth['2023-10'] ? leadsByMonth['2023-10'] : 0,
                    leadsByMonth['2023-11'] ? leadsByMonth['2023-11'] : 0,
                    leadsByMonth['2023-12'] ? leadsByMonth['2023-12'] : 0,
                  ],
                  fill: true,
        }]
      
    
  } else if (chartViewMode === 'Venues') {
    chartData = [{
      label: 'Number of Venues',
        backgroundColor: 'transparent',
        borderColor: getStyle('--cui-success'),
        pointHoverBackgroundColor: getStyle('--cui-success'),
        borderWidth: 2,
        data: [
          venuesByMonth['2023-01'] ? venuesByMonth['2023-01'] : 0,
          venuesByMonth['2023-02'] ? venuesByMonth['2023-02'] : 0,
          venuesByMonth['2023-03'] ? venuesByMonth['2023-03'] : 0,
          venuesByMonth['2023-04'] ? venuesByMonth['2023-04'] : 0,
          venuesByMonth['2023-05'] ? venuesByMonth['2023-05'] : 0,
          venuesByMonth['2023-06'] ? venuesByMonth['2023-06'] : 0,
          venuesByMonth['2023-07'] ? venuesByMonth['2023-07'] : 0,
          venuesByMonth['2023-08'] ? venuesByMonth['2023-08'] : 0,
          venuesByMonth['2023-09'] ? venuesByMonth['2023-09'] : 0,
          venuesByMonth['2023-10'] ? venuesByMonth['2023-10'] : 0,
          venuesByMonth['2023-11'] ? venuesByMonth['2023-11'] : 0,
          venuesByMonth['2023-12'] ? venuesByMonth['2023-12'] : 0,
        ],
      }]
    } else if (chartViewMode === 'Orders') {
      chartData = [{
        label: 'Number of Orders',
          backgroundColor: 'transparent',
          borderColor: 'purple',
          pointHoverBackgroundColor: getStyle('--cui-success'),
          borderWidth: 2,
          data: [
            ordersByMonth['2023-01'] ? ordersByMonth['2023-01'] : 0,
            ordersByMonth['2023-02'] ? ordersByMonth['2023-02'] : 0,
            ordersByMonth['2023-03'] ? ordersByMonth['2023-03'] : 0,
            ordersByMonth['2023-04'] ? ordersByMonth['2023-04'] : 0,
            ordersByMonth['2023-05'] ? ordersByMonth['2023-05'] : 0,
            ordersByMonth['2023-06'] ? ordersByMonth['2023-06'] : 0,
            ordersByMonth['2023-07'] ? ordersByMonth['2023-07'] : 0,
            ordersByMonth['2023-08'] ? ordersByMonth['2023-08'] : 0,
            ordersByMonth['2023-09'] ? ordersByMonth['2023-09'] : 0,
            ordersByMonth['2023-10'] ? ordersByMonth['2023-10'] : 0,
            ordersByMonth['2023-11'] ? ordersByMonth['2023-11'] : 0,
            ordersByMonth['2023-12'] ? ordersByMonth['2023-12'] : 0,
          ],
        },
        {
          label: 'Number of COMPETED Orders',
            backgroundColor: 'transparent',
            borderColor: 'green',
            pointHoverBackgroundColor: getStyle('--cui-success'),
            borderWidth: 2,
            data: [
              completedOrdersByMonth['2023-01'] ? completedOrdersByMonth['2023-01'] : 0,
              completedOrdersByMonth['2023-02'] ? completedOrdersByMonth['2023-02'] : 0,
              completedOrdersByMonth['2023-03'] ? completedOrdersByMonth['2023-03'] : 0,
              completedOrdersByMonth['2023-04'] ? completedOrdersByMonth['2023-04'] : 0,
              completedOrdersByMonth['2023-05'] ? completedOrdersByMonth['2023-05'] : 0,
              completedOrdersByMonth['2023-06'] ? completedOrdersByMonth['2023-06'] : 0,
              completedOrdersByMonth['2023-07'] ? completedOrdersByMonth['2023-07'] : 0,
              completedOrdersByMonth['2023-08'] ? completedOrdersByMonth['2023-08'] : 0,
              completedOrdersByMonth['2023-09'] ? completedOrdersByMonth['2023-09'] : 0,
              completedOrdersByMonth['2023-10'] ? completedOrdersByMonth['2023-10'] : 0,
              completedOrdersByMonth['2023-11'] ? completedOrdersByMonth['2023-11'] : 0,
              completedOrdersByMonth['2023-12'] ? completedOrdersByMonth['2023-12'] : 0,
            ],
          },
          {
            label: 'Number of REJECTED Orders',
              backgroundColor: 'transparent',
              borderColor: 'red',
              pointHoverBackgroundColor: getStyle('--cui-success'),
              borderWidth: 2,
              data: [
                rejectedOrdersByMonth['2023-01'] ? rejectedOrdersByMonth['2023-01'] : 0,
                rejectedOrdersByMonth['2023-02'] ? rejectedOrdersByMonth['2023-02'] : 0,
                rejectedOrdersByMonth['2023-03'] ? rejectedOrdersByMonth['2023-03'] : 0,
                rejectedOrdersByMonth['2023-04'] ? rejectedOrdersByMonth['2023-04'] : 0,
                rejectedOrdersByMonth['2023-05'] ? rejectedOrdersByMonth['2023-05'] : 0,
                rejectedOrdersByMonth['2023-06'] ? rejectedOrdersByMonth['2023-06'] : 0,
                rejectedOrdersByMonth['2023-07'] ? rejectedOrdersByMonth['2023-07'] : 0,
                rejectedOrdersByMonth['2023-08'] ? rejectedOrdersByMonth['2023-08'] : 0,
                rejectedOrdersByMonth['2023-09'] ? rejectedOrdersByMonth['2023-09'] : 0,
                rejectedOrdersByMonth['2023-10'] ? rejectedOrdersByMonth['2023-10'] : 0,
                rejectedOrdersByMonth['2023-11'] ? rejectedOrdersByMonth['2023-11'] : 0,
                rejectedOrdersByMonth['2023-12'] ? rejectedOrdersByMonth['2023-12'] : 0,
              ],
            }
      ]
    } else if (chartViewMode === 'Tasks') {
      chartData = [{
        label: 'Number of Tasks',
          backgroundColor: 'transparent',
          borderColor: 'purple',
          pointHoverBackgroundColor: getStyle('--cui-success'),
          borderWidth: 2,
          data: [
            tasksByMonth['2023-01'] ? tasksByMonth['2023-01'] : 0,
            tasksByMonth['2023-02'] ? tasksByMonth['2023-02'] : 0,
            tasksByMonth['2023-03'] ? tasksByMonth['2023-03'] : 0,
            tasksByMonth['2023-04'] ? tasksByMonth['2023-04'] : 0,
            tasksByMonth['2023-05'] ? tasksByMonth['2023-05'] : 0,
            tasksByMonth['2023-06'] ? tasksByMonth['2023-06'] : 0,
            tasksByMonth['2023-07'] ? tasksByMonth['2023-07'] : 0,
            tasksByMonth['2023-08'] ? tasksByMonth['2023-08'] : 0,
            tasksByMonth['2023-09'] ? tasksByMonth['2023-09'] : 0,
            tasksByMonth['2023-10'] ? tasksByMonth['2023-10'] : 0,
            tasksByMonth['2023-11'] ? tasksByMonth['2023-11'] : 0,
            tasksByMonth['2023-12'] ? tasksByMonth['2023-12'] : 0,
          ],
        },
        {
          label: 'Number of COMPETE Tasks',
            backgroundColor: 'transparent',
            borderColor: 'green',
            pointHoverBackgroundColor: getStyle('--cui-success'),
            borderWidth: 2,
            data: [
              tasksByMonth['2023-01'] ? tasksByMonth['2023-01'] : 0,
              tasksByMonth['2023-02'] ? tasksByMonth['2023-02'] : 0,
              tasksByMonth['2023-03'] ? tasksByMonth['2023-03'] : 0,
              tasksByMonth['2023-04'] ? tasksByMonth['2023-04'] : 0,
              tasksByMonth['2023-05'] ? tasksByMonth['2023-05'] : 0,
              tasksByMonth['2023-06'] ? tasksByMonth['2023-06'] : 0,
              tasksByMonth['2023-07'] ? tasksByMonth['2023-07'] : 0,
              tasksByMonth['2023-08'] ? tasksByMonth['2023-08'] : 0,
              tasksByMonth['2023-09'] ? tasksByMonth['2023-09'] : 0,
              tasksByMonth['2023-10'] ? tasksByMonth['2023-10'] : 0,
              tasksByMonth['2023-11'] ? tasksByMonth['2023-11'] : 0,
              tasksByMonth['2023-12'] ? tasksByMonth['2023-12'] : 0,
            ],
          },
          {
            label: 'Number of INCOMPLETE Tasks',
              backgroundColor: 'transparent',
              borderColor: 'red',
              pointHoverBackgroundColor: getStyle('--cui-success'),
              borderWidth: 2,
              data: [
                tasksByMonth['2023-01'] ? tasksByMonth['2023-01'] : 0,
                tasksByMonth['2023-02'] ? tasksByMonth['2023-02'] : 0,
                tasksByMonth['2023-03'] ? tasksByMonth['2023-03'] : 0,
                tasksByMonth['2023-04'] ? tasksByMonth['2023-04'] : 0,
                tasksByMonth['2023-05'] ? tasksByMonth['2023-05'] : 0,
                tasksByMonth['2023-06'] ? tasksByMonth['2023-06'] : 0,
                tasksByMonth['2023-07'] ? tasksByMonth['2023-07'] : 0,
                tasksByMonth['2023-08'] ? tasksByMonth['2023-08'] : 0,
                tasksByMonth['2023-09'] ? tasksByMonth['2023-09'] : 0,
                tasksByMonth['2023-10'] ? tasksByMonth['2023-10'] : 0,
                tasksByMonth['2023-11'] ? tasksByMonth['2023-11'] : 0,
                tasksByMonth['2023-12'] ? tasksByMonth['2023-12'] : 0,
              ],
            }]
    }


  return (
    <>
      {/* <WidgetsDropdown /> */}
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                Main Statistics
              </h4>
              <div className="small text-medium-emphasis">January - July 2023</div>
            </CCol>
            <CCol sm={7} className=" d-md-block">
              {/* <CButton color="primary" className="float-end">
                <CIcon icon={cilCloudDownload} />
              </CButton> */}
              <CButtonGroup className="float-end me-3">
              {['Leads', 'Venues', 'Orders', 'Tasks'].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === chartViewMode}
                    onClick={() => setChartViewMode(value)}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>
            </CCol>
          </CRow>
          <CChartLine
            style={{ height: '300px', marginTop: '40px' }}
            data={{
              labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
              datasets: chartData,
              // [
              //   chartData
              //   // {
              //   //   label: 'My First dataset',
              //   //   backgroundColor: hexToRgba(getStyle('--cui-info'), 10),
              //   //   borderColor: getStyle('--cui-info'),
              //   //   pointHoverBackgroundColor: getStyle('--cui-info'),
              //   //   borderWidth: 2,
              //   //   data: [
              //   //     random(50, 200),
              //   //     random(50, 200),
              //   //     random(50, 200),
              //   //     random(50, 200),
              //   //     random(50, 200),
              //   //     random(50, 200),
              //   //     random(50, 200),
              //   //   ],
              //   //   fill: true,
              //   // },
              //   // {
              //   //   label: 'My Second dataset',
              //   //   backgroundColor: 'transparent',
              //   //   borderColor: getStyle('--cui-success'),
              //   //   pointHoverBackgroundColor: getStyle('--cui-success'),
              //   //   borderWidth: 2,
              //   //   data: [
              //   //     random(50, 200),
              //   //     random(50, 200),
              //   //     random(50, 200),
              //   //     random(50, 200),
              //   //     random(50, 200),
              //   //     random(50, 200),
              //   //     random(50, 200),
              //   //   ],
              //   // },
              //   // {
              //   //   label: 'My Third dataset',
              //   //   backgroundColor: 'transparent',
              //   //   borderColor: getStyle('--cui-danger'),
              //   //   pointHoverBackgroundColor: getStyle('--cui-danger'),
              //   //   borderWidth: 1,
              //   //   borderDash: [8, 5],
              //   //   data: [65, 65, 65, 65, 65, 65, 65],
              //   // },
              // ],
            }}
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  grid: {
                    drawOnChartArea: false,
                  },
                },
                y: {
                  ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    stepSize: Math.ceil(250 / 5),
                    max: 250,
                  },
                },
              },
              elements: {
                line: {
                  tension: 0.4,
                },
                point: {
                  radius: 0,
                  hitRadius: 10,
                  hoverRadius: 4,
                  hoverBorderWidth: 3,
                },
              },
            }}
          />
        </CCardBody>
        <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            {/* {progressExample.map((item, index) => (
              <CCol className="mb-sm-2 mb-0" key={index}>
                <div className="text-medium-emphasis">{item.title}</div>
                <strong>
                  {item.value} ({item.percent}%)
                </strong>
                <CProgress thin className="mt-2" color={item.color} value={item.percent} />
              </CCol>
            ))} */}
             <CCol className="mb-sm-2 mb-0">
                <div className="text-medium-emphasis">{'Total Leads this year'}</div>
                <strong>
                  {'Total Leads: '} ({numOfLeadsThisYear})
                </strong>
                <CProgress thin className="mt-2" color={'info'} value={100} />
              </CCol>

              <CCol className="mb-sm-2 mb-0">
                <div className="text-medium-emphasis">{'Total Venues added this year'}</div>
                <strong>
                  {'Total Venue: '} ({numOfVenuesThisYear})
                </strong>
                <CProgress thin className="mt-2" color={'success'} value={100} />
              </CCol>

              <CCol className="mb-sm-2 mb-0">
                <div className="text-medium-emphasis">{'Total orders placed this year'}</div>
                <strong>
                  {'Total Orders: '} ({numOfOrdersThisYear})
                </strong>
                <CProgress thin className="mt-2" color={'purple'} value={100} />
              </CCol>

              <CCol className="mb-sm-2 mb-0">
                <div className="text-medium-emphasis">{'COMPLETED orders this year'}</div>
                <strong>
                  {'Total Completed Orders: '} ({numOfOrdersThisYear})
                </strong>
                <CProgress thin className="mt-2" color={'warning'} value={100} />
              </CCol>

              <CCol className="mb-sm-2 mb-0">
                <div className="text-medium-emphasis">{'Total tasks this year'}</div>
                <strong>
                  {'Total Tasks: '} ({numOfTasksThisYear})
                </strong>
                <CProgress thin className="mt-2" color={'danger'} value={100} />
              </CCol>
              
          </CRow>
        </CCardFooter>
      </CCard>


      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                Today Activity
              </h4>
              <div className="small text-medium-emphasis">Latest venues</div>
            </CCol>
            <CCol sm={7} className="d-none d-md-block">
            </CCol>
          </CRow>
          <CTable columns={venue_columns} items={todayVenues}/>
          <CRow>
            <CCol sm={5}>
              <div className="small text-medium-emphasis">Latest Clients</div>
            </CCol>
            <CCol sm={7} className="d-none d-md-block">
            </CCol>
          </CRow>
          <CTable columns={venue_columns} items={todayClients}/>
          <CRow>
            <CCol sm={5}>
              <div className="small text-medium-emphasis">Latest Entertainers ({todayEntertainers.length})</div>
            </CCol>
            <CCol sm={7} className="d-none d-md-block">
            </CCol>
          </CRow>
          <CTable columns={Entertainres_columns} items={todayEntertainersItems}/>
          <CRow>
            <CCol sm={5}>
              <div className="small text-medium-emphasis">Latest Photographers({todayPhotographers.length})</div>
            </CCol>
            <CCol sm={7} className="d-none d-md-block">
            </CCol>
          </CRow>
          <CTable columns={Photographers_columns} items={photographersTodayItems}/>
        </CCardBody>
        {/* <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            {progressExample.map((item, index) => (
              <CCol className="mb-sm-2 mb-0" key={index}>
                <div className="text-medium-emphasis">{item.title}</div>
                <strong>
                  {item.value} ({item.percent}%)
                </strong>
                <CProgress thin className="mt-2" color={item.color} value={item.percent} />
              </CCol>
            ))}
          </CRow>
        </CCardFooter> */}
      </CCard>

      

      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                New Venues
              </h4>
              <div className="small text-medium-emphasis">Latest Entries</div>
            </CCol>
            <CCol sm={7} className="d-none d-md-block">
              <CButton color="primary" className="float-end" href='Venues'>
                view venues
              </CButton>
              {/* <CButtonGroup className="float-end me-3">
                {['Day', 'Month', 'Year'].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === 'Month'}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup> */}
            </CCol>
          </CRow>
          <CTable columns={venue_columns} items={venue_items}/>
        </CCardBody>
        {/* <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            {progressExample.map((item, index) => (
              <CCol className="mb-sm-2 mb-0" key={index}>
                <div className="text-medium-emphasis">{item.title}</div>
                <strong>
                  {item.value} ({item.percent}%)
                </strong>
                <CProgress thin className="mt-2" color={item.color} value={item.percent} />
              </CCol>
            ))}
          </CRow>
        </CCardFooter> */}
      </CCard>



      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                New Leads
              </h4>
              <div className="small text-medium-emphasis">Latest Entries</div>
            </CCol>
            <CCol sm={7} className="d-none d-md-block">
              <CButton color="primary" className="float-end" href='leads'>
                view leads
              </CButton>
              {/* <CButtonGroup className="float-end me-3">
                {['Day', 'Month', 'Year'].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === 'Month'}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup> */}
            </CCol>
          </CRow>
          <CTable columns={leads_columns} items={leads_items}/>
        </CCardBody>
        {/* <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            {progressExample.map((item, index) => (
              <CCol className="mb-sm-2 mb-0" key={index}>
                <div className="text-medium-emphasis">{item.title}</div>
                <strong>
                  {item.value} ({item.percent}%)
                </strong>
                <CProgress thin className="mt-2" color={item.color} value={item.percent} />
              </CCol>
            ))}
          </CRow>
        </CCardFooter> */}
      </CCard>

      {/* <WidgetsBrand withCharts /> */}

      {/* <CRow>
        <CCol xs>
          <CCard className="mb-4">
            <CCardHeader>Traffic {' & '} Sales</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol xs={12} md={6} xl={6}>
                  <CRow>
                    <CCol sm={6}>
                      <div className="border-start border-start-4 border-start-info py-1 px-3">
                        <div className="text-medium-emphasis small">New Clients</div>
                        <div className="fs-5 fw-semibold">9,123</div>
                      </div>
                    </CCol>
                    <CCol sm={6}>
                      <div className="border-start border-start-4 border-start-danger py-1 px-3 mb-3">
                        <div className="text-medium-emphasis small">Recurring Clients</div>
                        <div className="fs-5 fw-semibold">22,643</div>
                      </div>
                    </CCol>
                  </CRow>

                  <hr className="mt-0" />
                  {progressGroupExample1.map((item, index) => (
                    <div className="progress-group mb-4" key={index}>
                      <div className="progress-group-prepend">
                        <span className="text-medium-emphasis small">{item.title}</span>
                      </div>
                      <div className="progress-group-bars">
                        <CProgress thin color="info" value={item.value1} />
                        <CProgress thin color="danger" value={item.value2} />
                      </div>
                    </div>
                  ))}
                </CCol>

                <CCol xs={12} md={6} xl={6}>
                  <CRow>
                    <CCol sm={6}>
                      <div className="border-start border-start-4 border-start-warning py-1 px-3 mb-3">
                        <div className="text-medium-emphasis small">Pageviews</div>
                        <div className="fs-5 fw-semibold">78,623</div>
                      </div>
                    </CCol>
                    <CCol sm={6}>
                      <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                        <div className="text-medium-emphasis small">Organic</div>
                        <div className="fs-5 fw-semibold">49,123</div>
                      </div>
                    </CCol>
                  </CRow>

                  <hr className="mt-0" />

                  {progressGroupExample2.map((item, index) => (
                    <div className="progress-group mb-4" key={index}>
                      <div className="progress-group-header">
                        <CIcon className="me-2" icon={item.icon} size="lg" />
                        <span>{item.title}</span>
                        <span className="ms-auto fw-semibold">{item.value}%</span>
                      </div>
                      <div className="progress-group-bars">
                        <CProgress thin color="warning" value={item.value} />
                      </div>
                    </div>
                  ))}

                  <div className="mb-5"></div>

                  {progressGroupExample3.map((item, index) => (
                    <div className="progress-group" key={index}>
                      <div className="progress-group-header">
                        <CIcon className="me-2" icon={item.icon} size="lg" />
                        <span>{item.title}</span>
                        <span className="ms-auto fw-semibold">
                          {item.value}{' '}
                          <span className="text-medium-emphasis small">({item.percent}%)</span>
                        </span>
                      </div>
                      <div className="progress-group-bars">
                        <CProgress thin color="success" value={item.percent} />
                      </div>
                    </div>
                  ))}
                </CCol>
              </CRow>

              <br />

              <CTable align="middle" className="mb-0 border" hover responsive>
                <CTableHead color="light">
                  <CTableRow>
                    <CTableHeaderCell className="text-center">
                      <CIcon icon={cilPeople} />
                    </CTableHeaderCell>
                    <CTableHeaderCell>User</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Country</CTableHeaderCell>
                    <CTableHeaderCell>Usage</CTableHeaderCell>
                    <CTableHeaderCell className="text-center">Payment Method</CTableHeaderCell>
                    <CTableHeaderCell>Activity</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {tableExample.map((item, index) => (
                    <CTableRow v-for="item in tableItems" key={index}>
                      <CTableDataCell className="text-center">
                        <CAvatar size="md" src={item.avatar.src} status={item.avatar.status} />
                      </CTableDataCell>
                      <CTableDataCell>
                        <div>{item.user.name}</div>
                        <div className="small text-medium-emphasis">
                          <span>{item.user.new ? 'New' : 'Recurring'}</span> | Registered:{' '}
                          {item.user.registered}
                        </div>
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        <CIcon size="xl" icon={item.country.flag} title={item.country.name} />
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="clearfix">
                          <div className="float-start">
                            <strong>{item.usage.value}%</strong>
                          </div>
                          <div className="float-end">
                            <small className="text-medium-emphasis">{item.usage.period}</small>
                          </div>
                        </div>
                        <CProgress thin color={item.usage.color} value={item.usage.value} />
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        <CIcon size="xl" icon={item.payment.icon} />
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="small text-medium-emphasis">Last login</div>
                        <strong>{item.activity}</strong>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow> */}
    </>
  )
}

export default Dashboard
