import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput } from '@coreui/react'
import { CChart } from '@coreui/react-chartjs'
import { api } from 'src/configs'



const Statistics = () => {


  return (
    <CRow>
        <h2>coming soon</h2>
    </CRow>
  )
}

export default Statistics