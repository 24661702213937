import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'

const columns = [
    {
      key: 'emp_name',
      label: 'Employee Name',
      _props: { scope: 'col' },
    },
    {
      key: 'amount',
      label: 'Amount',
      _props: { scope: 'col' },
    },
    {
        key: 'reason',
        label: 'Reason',
        _props: { scope: 'col' },
      },
      {
        key: 'date',
        label: 'Date',
        _props: { scope: 'col' },
      },
      {
        key: 'state',
        label: 'State',
        _props: { scope: 'col' },
      },
      {
        key: 'Action',
        label: 'Action',
        _props: { scope: 'col' },
      },
  ]



const Fines = () => {
    const [items, setItems] = useState([]);
    const [filter, setfilter] = useState("");

    useEffect(() => {
        const fetchBonuses = async () => {
          const response = await fetch(api + "getFines");
          const data = await response.json();
          console.log(data)
          setItems(data);
        };
    
        fetchBonuses();
      }, []);

      const onDeleteBonusClicked = (id) => {
        console.log("id", id)
        const requestOptions = {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: id })
      };
      fetch(api + 'deleteFine', requestOptions)
          .then(response => response.json())
          .then(data => {
            console.log("data", data)
            if(data["res"] == "Successfully deleted fine"){
              window.location.reload();
            }
          });
      }


      const create_table = (array) =>{
        var items = []
        array.forEach(element => {
          console.log("element", element)
            items.push({
                emp_name: element["full_name"],
                amount: element["amount"] + "EGP",
                date: element['date'] ? element['date'].substring(0, 10) : " ",
                reason: element["reason"],
                // state: element["state"] == null ? "pending" : element["state"] == true ? "accepted" : "denied",
                // acceptance: element["state"] == null? "pending" : element["state"] == true? "accepted" : "denied" ,
                ...(element["state"] == null)? {state : <div style={{color: "#9c9400", fontWeight: 'bold'}}>pending</div>} : element["state"] == true? {state : <div style={{color: "green", fontWeight: 'bold'}}>Sold</div> } :{state : <div style={{color: "red", fontWeight: 'bold'}}>rejected</div> } ,
                Action: <CButton color="dark" onClick={() =>{onDeleteBonusClicked(element['id'])}}>remove</CButton>,
            },)
        });
        return items
      }
      var itemlist = create_table(items)
  return (
    <CRow>
      <CCol xs={12}>
        <h1>Fines</h1><br/>
        <div style={{ display: "flex", alignItems: "center" }}>
  <CFormInput
    type="text"
    placeholder={"Filter"}
    aria-label="default input example"
    onChange={(event) => {
      setfilter(event.target.value);
    }}
    
  />
    <CButton style={{marginLeft: "20px"}} onClick={() =>{window.location.href = 'AddFine'}}>add</CButton>
</div><br/><br/>
        <div className="table table-responsive"><CTable className="table table-striped table-hover" columns={columns} items={itemlist}/></div>
      </CCol>
    </CRow>
  )
}

export default Fines