import React, { useEffect, useState } from 'react'

import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCallout,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { CChartLine } from '@coreui/react-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils'
import CIcon from '@coreui/icons-react'
import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibGoogle,
  cibFacebook,
  cibLinkedin,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cibTwitter,
  cilCloudDownload,
  cilPeople,
  cilUser,
  cilUserFemale,
} from '@coreui/icons'

import { motion } from 'framer-motion';
import Flogo from 'src/assets/images/Flogo.png'

import avatar1 from 'src/assets/images/avatars/1.jpg'
import avatar2 from 'src/assets/images/avatars/2.jpg'
import avatar3 from 'src/assets/images/avatars/3.jpg'
import avatar4 from 'src/assets/images/avatars/4.jpg'
import avatar5 from 'src/assets/images/avatars/5.jpg'
import avatar6 from 'src/assets/images/avatars/6.jpg'
import { api } from 'src/configs'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'




const UserDashboard = () => {
  //use effect function to fetch venue data from api and set state
  const [Venues, setVenues] = useState([]);
  const [Leads, setLeads] = useState([]);
  const [Tasks, setTasks] = useState([]);
  const [Reminders, setReminders] = useState([]);
  const [showReminders, setShowReminders] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    //fetch leads by emp id get request in query params
    fetch(api + 'getClientsByEmpId?emp_id=' + localStorage.getItem('f_emp_id'), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    })
    .then(response => response.json())
    .then(data => {
        console.log(data)
        setLeads(data.sort((a, b) => (a.id < b.id) ? 1 : -1).slice(0, 5))
        }
    )

    //fetch venues by emp id get request in query params
    fetch(api + 'getVenuesByEmpId?emp_id=' + localStorage.getItem('f_emp_id'), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    })
    .then(response => response.json())
    .then(data => {
        console.log(data)
        setVenues(data.sort((a, b) => (a.id < b.id) ? 1 : -1).slice(0, 5))
        }
    )


    fetch(api + 'getEmpTasks?emp_id=' + localStorage.getItem('f_emp_id'), {

    method: 'GET',
    headers: {  
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        },
    })
    .then(response => response.json())
    .then(data => {
    console.log(data)
        setTasks(data);
    }
    )
    .catch((error) => {
        console.error('Error:', error);
    }
    );


    fetch(api + 'getEmpReminders?id=' + localStorage.getItem('f_emp_id'), {

      method: 'GET',
      headers: {  
          'Content-Type': 'application/json',
          'Accept': 'application/json'
          },
      })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        const today = new Date().toISOString().split('T')[0];
        console.log("today : ", today)
        console.log(new Date(data[0].date).toLocaleString())
        console.log(moment(data[0].date).format('YYYY-MM-DD'))
        const todayReminders = data.filter(item => moment(item.date).format('YYYY-MM-DD').startsWith(today));
        console.log("todoremiders", todayReminders)
        setReminders(todayReminders);
      }
      )
      .catch((error) => {
          console.error('Error:', error);
      }
      );




  }, []);


const leads_columns = [
  {
    key: 'client_name',
    label: 'Client Name',
    _props: { scope: 'col' },
  },
  {
    key: 'title',
    label: 'Title',
    _props: { scope: 'col' },
  },
    {
      key: 'company',
      label: 'Company Name',
      _props: { scope: 'col' },
    },
    // {
    //   key: 'emp_name',
    //   label: 'Added by',
    //   _props: { scope: 'col' },
    // },
    {
      key: 'added_at',
      label: 'Added At',
      _props: { scope: 'col' },
    },
        // {
        //   key: 'Action',
        //   label: 'Action',
        //   _props: { scope: 'col' },
        // }
  ]
  const create_Leads_items = (array) =>{
    var items = []
    array.forEach(element => {
        items.push({
          id: element["id"],
          emp_name: element["employee_name"],
          client_name: element["full_name"],
          title: element["title"],
          type: element["type"],
          emp_id: element["emp_id"],
          company: element["company_name"] + " ",
          added_at: element['created_at'] != null ? element['created_at'].substring(0, 10) : " ",
            // acceptance: element["state"] == null? "pending" : element["state"] == true? "accepted" : "denied" ,
            ...(element["state"] == null)? {acceptance : "pending" } : element["state"] == true? {acceptance : "accepted" } :{acceptance : "denied" } ,
            // Action: <CButton color="primary">Lead</CButton>,
        },)
    });
    return items
  }
  const leads_items = create_Leads_items(Leads)


  const venue_columns = [
    {
      key: 'Name',
      label: 'Name',
      _props: { scope: 'col' },
    },
    {
      key: 'Area',
      label: 'Area',
      _props: { scope: 'col' },
    },
    {
        key: 'Location',
        label: 'Location',
        _props: { scope: 'col' },
      },
      {
        key: 'type',
        label: 'type',
        _props: { scope: 'col' },
      },
      // {
      //   key: 'Action',
      //   label: 'Action',
      //   _props: { scope: 'col' },
      // }
  ]
  const create_venue_items = (array) =>{
    var items = []
    array.forEach(element => {
        items.push({
            Name: element["name"],
            type: element["type"],
            Area: element["area"],
            Location: element["location"],
            added_by: element["added_by"],
            Salary: element["salary"],
            // Action: <CButton color="primary">venue</CButton>,
        },)
    });
    return items
  }
  const venue_items = create_venue_items(Venues)


  const tasks_columns = [
    {
        key: 'Name',
        label: 'Name',
        _props: { scope: 'col' },
      },
      {
        key: 'Description',
        label: 'Description',
        _props: { scope: 'col' },
      },
        {
          key: 'start_date',
          label: 'Start Date',
          _props: { scope: 'col' },
        },
        {
          key: 'end_date',
          label: 'Deadline',
          _props: { scope: 'col' },
        },
        {
          key: 'Completion',
          label: 'Completion',
          _props: { scope: 'col' },
        },
        {
          key: 'Score',
          label: 'Score',
          _props: { scope: 'col' },
        },
        // {
        //   key: 'Action',
        //   label: 'Action',
        //   _props: { scope: 'col' },
        // },
  ]
  const create_tasks_items = (array) =>{
    var items = []
    array.forEach(element => {
        items.push({
            id: element["id"],
            Name: element["name"],
            Description: element["description"],
            ...(element["score"] == null)? {Score: "unscored"} : {Score: element["score"] + " "},
            ...(element["start_date"] == null)? {start_date: "-"} : {start_date: element["start_date"].substring(0,10)},
            ...(element["end_date"] == null)? {end_date: "-"} : {end_date: element["end_date"].substring(0,10)},
            Completion: element["completion"] + '',
            // Action: <CButton color="primary">Task</CButton>,
        },)
    });
    return items
  }
  const tasks_items = create_tasks_items(Tasks)


  // const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

  // const progressExample = [
  //   { title: 'Visits', value: '29.703 Users', percent: 40, color: 'success' },
  //   { title: 'Unique', value: '24.093 Users', percent: 20, color: 'info' },
  //   { title: 'Pageviews', value: '78.706 Views', percent: 60, color: 'warning' },
  //   { title: 'New Users', value: '22.123 Users', percent: 80, color: 'danger' },
  //   { title: 'Bounce Rate', value: 'Average Rate', percent: 40.15, color: 'primary' },
  // ]

  // const progressGroupExample1 = [
  //   { title: 'Monday', value1: 34, value2: 78 },
  //   { title: 'Tuesday', value1: 56, value2: 94 },
  //   { title: 'Wednesday', value1: 12, value2: 67 },
  //   { title: 'Thursday', value1: 43, value2: 91 },
  //   { title: 'Friday', value1: 22, value2: 73 },
  //   { title: 'Saturday', value1: 53, value2: 82 },
  //   { title: 'Sunday', value1: 9, value2: 69 },
  // ]

  // const progressGroupExample2 = [
  //   { title: 'Male', icon: cilUser, value: 53 },
  //   { title: 'Female', icon: cilUserFemale, value: 43 },
  // ]

  // const progressGroupExample3 = [
  //   { title: 'Organic Search', icon: cibGoogle, percent: 56, value: '191,235' },
  //   { title: 'Facebook', icon: cibFacebook, percent: 15, value: '51,223' },
  //   { title: 'Twitter', icon: cibTwitter, percent: 11, value: '37,564' },
  //   { title: 'LinkedIn', icon: cibLinkedin, percent: 8, value: '27,319' },
  // ]

  // const tableExample = [
  //   {
  //     avatar: { src: avatar1, status: 'success' },
  //     user: {
  //       name: 'Yiorgos Avraamu',
  //       new: true,
  //       registered: 'Jan 1, 2021',
  //     },
  //     country: { name: 'USA', flag: cifUs },
  //     usage: {
  //       value: 50,
  //       period: 'Jun 11, 2021 - Jul 10, 2021',
  //       color: 'success',
  //     },
  //     payment: { name: 'Mastercard', icon: cibCcMastercard },
  //     activity: '10 sec ago',
  //   },
  //   {
  //     avatar: { src: avatar2, status: 'danger' },
  //     user: {
  //       name: 'Avram Tarasios',
  //       new: false,
  //       registered: 'Jan 1, 2021',
  //     },
  //     country: { name: 'Brazil', flag: cifBr },
  //     usage: {
  //       value: 22,
  //       period: 'Jun 11, 2021 - Jul 10, 2021',
  //       color: 'info',
  //     },
  //     payment: { name: 'Visa', icon: cibCcVisa },
  //     activity: '5 minutes ago',
  //   },
  //   {
  //     avatar: { src: avatar3, status: 'warning' },
  //     user: { name: 'Quintin Ed', new: true, registered: 'Jan 1, 2021' },
  //     country: { name: 'India', flag: cifIn },
  //     usage: {
  //       value: 74,
  //       period: 'Jun 11, 2021 - Jul 10, 2021',
  //       color: 'warning',
  //     },
  //     payment: { name: 'Stripe', icon: cibCcStripe },
  //     activity: '1 hour ago',
  //   },
  //   {
  //     avatar: { src: avatar4, status: 'secondary' },
  //     user: { name: 'Enéas Kwadwo', new: true, registered: 'Jan 1, 2021' },
  //     country: { name: 'France', flag: cifFr },
  //     usage: {
  //       value: 98,
  //       period: 'Jun 11, 2021 - Jul 10, 2021',
  //       color: 'danger',
  //     },
  //     payment: { name: 'PayPal', icon: cibCcPaypal },
  //     activity: 'Last month',
  //   },
  //   {
  //     avatar: { src: avatar5, status: 'success' },
  //     user: {
  //       name: 'Agapetus Tadeáš',
  //       new: true,
  //       registered: 'Jan 1, 2021',
  //     },
  //     country: { name: 'Spain', flag: cifEs },
  //     usage: {
  //       value: 22,
  //       period: 'Jun 11, 2021 - Jul 10, 2021',
  //       color: 'primary',
  //     },
  //     payment: { name: 'Google Wallet', icon: cibCcApplePay },
  //     activity: 'Last week',
  //   },
  //   {
  //     avatar: { src: avatar6, status: 'danger' },
  //     user: {
  //       name: 'Friderik Dávid',
  //       new: true,
  //       registered: 'Jan 1, 2021',
  //     },
  //     country: { name: 'Poland', flag: cifPl },
  //     usage: {
  //       value: 43,
  //       period: 'Jun 11, 2021 - Jul 10, 2021',
  //       color: 'success',
  //     },
  //     payment: { name: 'Amex', icon: cibCcAmex },
  //     activity: 'Last week',
  //   },
  // ]

  return (
    <>
    <h1 style={{
  fontSize: '3rem',
  fontWeight: 'bold',
  textAlign: 'center',
  color: '#333',
  fontFamily: 'sans-serif',
  textTransform: 'uppercase',
  letterSpacing: '0.1em',
  textShadow: '2px 2px #ccc'
    }}>
        Dashboard
    </h1>
    {/* <div style={{display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}}> <img src={Flogo} width={200} /></div> */}
   
    {/* <h6 style={{
          color: '#1a1a1a',
          fontWeight: 'bold',
          fontFamily: 'Helvetica Neue, sans-serif',
          textTransform: 'uppercase',
           textAlign: 'center',
          margin: '0',
          padding: '0',


        }}>
          Welcome to Fusion System
        </h6> */}
    <br/>
    {/* <GifDisplay/> */}
    {/* <motion.div animate={{ x:[0, 3, 0]}} transition={{ duration: 4.5, repeat: Infinity }}> */}

        {
          Reminders.length > 0 && <div>
            <h3 style={{ 
            color: '#2f2f2f',
            marginBottom: '1rem',
            fontFamily: 'Poppins, sans-serif',
            textTransform: 'uppercase',
            letterSpacing: '0.05rem',
                  }}>Reminders*</h3>

      {Reminders.map((element, index) => (
        <CCallout color="success" key={index}>
          <CButton color="info" onClick={() => {localStorage.setItem("f_r_id", element['id']); navigate('/ReminderProfile')}} style={{float: "right"}}>View</CButton>

          <h5>{element.name}</h5>
          <h7>{element.description}</h7><br/>
          <h9 style={{fontSize: '13px'}}>{element.full_name}{"--"}{element.date? element.date.substring(0, 10) : null}</h9>
          
          </CCallout>
      ))}
          </div>
        }


        <h3 style={{ 
 color: '#2f2f2f',
 marginBottom: '1rem',
 fontFamily: 'Poppins, sans-serif',
 textTransform: 'uppercase',
 letterSpacing: '0.05rem',
      }}>Leads</h3>
    {/* </motion.div> */}
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                My Newest Leads
              </h4>
              <div className="small text-medium-emphasis">Latest Entries</div>
            </CCol>
            {/* <CCol sm={7} className="d-none d-md-block">
              <CButton color="primary" className="float-end">
                <CIcon icon={cilCloudDownload} />
              </CButton>
              <CButtonGroup className="float-end me-3">
                {['Day', 'Month', 'Year'].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === 'Month'}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>
            </CCol> */}
          </CRow>
          <div className="table table-responsive"><CTable columns={leads_columns} items={leads_items} className="table table-striped table-hover" /></div>
        </CCardBody>
        {/* <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            {progressExample.map((item, index) => (
              <CCol className="mb-sm-2 mb-0" key={index}>
                <div className="text-medium-emphasis">{item.title}</div>
                <strong>
                  {item.value} ({item.percent}%)
                </strong>
                <CProgress thin className="mt-2" color={item.color} value={item.percent} />
              </CCol>
            ))}
          </CRow>
        </CCardFooter> */}
      </CCard><br/>


      {/* <motion.div animate={{ x:[0, 3, 0]}} transition={{ type: 'tween', duration: 4.5, repeat: Infinity }}> */}
        <h3 style={{ 
            color: '#2f2f2f',
            marginBottom: '1rem',
            fontFamily: 'Poppins, sans-serif',
            textTransform: 'uppercase',
            letterSpacing: '0.05rem',
      }}>Venues</h3>
    {/* </motion.div> */}
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                My Newest Venues
              </h4>
              <div className="small text-medium-emphasis">Latest Entries</div>
            </CCol>
            {/* <CCol sm={7} className="d-none d-md-block">
              <CButton color="primary" className="float-end">
                <CIcon icon={cilCloudDownload} />
              </CButton>
              <CButtonGroup className="float-end me-3">
                {['Day', 'Month', 'Year'].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === 'Month'}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>
            </CCol> */}
          </CRow>
          <div className="table table-responsive"><CTable columns={venue_columns} items={venue_items} className="table table-striped table-hover"/></div>
        </CCardBody>
        {/* <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            {progressExample.map((item, index) => (
              <CCol className="mb-sm-2 mb-0" key={index}>
                <div className="text-medium-emphasis">{item.title}</div>
                <strong>
                  {item.value} ({item.percent}%)
                </strong>
                <CProgress thin className="mt-2" color={item.color} value={item.percent} />
              </CCol>
            ))}
          </CRow>
        </CCardFooter> */}
      </CCard><br/>


      {/* <motion.div animate={{ x:[0, 3, 0]}} transition={{ type: 'tween', duration: 4.5, repeat: Infinity }}> */}
        <h3 style={{ 
            color: '#2f2f2f',
            marginBottom: '1rem',
            fontFamily: 'Poppins, sans-serif',
            textTransform: 'uppercase',
            letterSpacing: '0.05rem',
      }}>Tasks</h3>
    {/* </motion.div> */}
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                My Newest Tasks
              </h4>
              <div className="small text-medium-emphasis">Latest Entries</div>
            </CCol>
            {/* <CCol sm={7} className="d-none d-md-block">
              <CButton color="primary" className="float-end">
                <CIcon icon={cilCloudDownload} />
              </CButton>
              <CButtonGroup className="float-end me-3">
                {['Day', 'Month', 'Year'].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === 'Month'}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>
            </CCol> */}
          </CRow>
          <div className="table table-responsive"><CTable columns={tasks_columns} items={tasks_items} className="table table-striped table-hover"/></div>
        </CCardBody>
        {/* <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            {progressExample.map((item, index) => (
              <CCol className="mb-sm-2 mb-0" key={index}>
                <div className="text-medium-emphasis">{item.title}</div>
                <strong>
                  {item.value} ({item.percent}%)
                </strong>
                <CProgress thin className="mt-2" color={item.color} value={item.percent} />
              </CCol>
            ))}
          </CRow>
        </CCardFooter> */}
      </CCard>

      
    </>
  )
}

export default UserDashboard
