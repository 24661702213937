import React from "react";
import { CCallout } from "@coreui/react";

const updates = [
    {
        id: 1,
        type: 'secondary',
        title: "Bug Fix",
        description: "Fixed issue with changing passwords, changing passwords now works as intended. if you still have issues logging in or changing passwords send me or come to me and i will fix it for you.",
        date: "2023-07-013",
        time: "11:40:00",
    },
    {
        id: 2,
        type: 'secondary',
        title: "Bug Fix",
        description: "Fixed user dashboard leads not showing properly.",
        date: "2023-07-013",
        time: "12:30:00",
    },
    {
        id: 3,
        type: 'success',
        title: "New Feature",
        description: "Added sorting in MyLeads page.",
        date: "2023-07-013",
        time: "1:00:00",
    },
    
]

const SystemUpdates = () => {
    return (
        <div>
            <h1>System Updates</h1><br/>
            <h5>any fixes or new features added will appear here.</h5><br/>
            {
                updates.reverse().map((update) => {
                    return (
                        <div key={update.id}>
                            <CCallout color={update.type}>
                                <small className="text-muted">{update.date} {update.time}</small>
                                <br />
                                <strong className="h5">{update.title}</strong>
                                <br />
                                <p className="text-muted">{update.description}</p>
                            </CCallout>

                        </div>
                    )
                }
                )
            }
        </div>
    );
}

export default SystemUpdates;