import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'

// routes config
import routes from '../routes'

const user_routes = [
  'Venues',
  'addVenue',
  'MyTasks',
  'AddLead',
  'MyLeads',
  'UserDashboard',
  'CustGpt',
  'LeaderBoard',
  'TaskProfile',
  'TrelloBoard',
  'LeadProfile',
  'VenueProfile',
  'EmployeeProfile',
  'EmployeeProf',
  'SystemUpdates',
  'SystemReports',
  'AddReminder',
  'MyReminders',
  'MyMeetings',
  'AddMeeting',
  'ReminderProfile',
  'Orders',
  'AddOrder',
  'Djs',
  'AddDj',
  'DjProfile',
  'Photographers',
  'AddPhotographer',
  'PhotographerProfile',
]

const manager_routes = [
  'ManagerDashboard',
  'TaskProfileAdmin',
  'Orders',
  'AddOrder',
  'OrderProfile',
  'Bonuses',
  "AddBonus",
  'Fines',
  'AddFine',
  'ManagerStatistics',
  'Venues',
  'addVenue',
  'Leads',
  'AddLeadAdmin',
  'Tasks',
  'AddLead',
  'AddTask',
  'UserDashboard',
  'CustGpt',
  'LeaderBoard',
  'TaskProfile',
  'TrelloBoard',
  'LeadProfile',
  'VenueProfile',
  'EmployeeProf',
  'SystemUpdates',
  'SystemReports',
  'AddReminder',
  'MyReminders',
  'MyMeetings',
  'AddMeeting',
  'Meetings',
  'ReminderProfile',
  'Djs',
  'AddDj',
  'DjProfile',
  'Photographers',
  'AddPhotographer',
  'PhotographerProfile',
]

const role = localStorage.getItem('f_role')

const AppContent = () => {
  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          {routes.map((route, idx) => {
            // console.log(route.name)
            if (role == 'employee' && !user_routes.includes(route.name)) {
              return null
            }
            if (role == 'manager' && !manager_routes.includes(route.name)) {
              return null
            }
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            )
          })}
          {role == 'employee' ? (
            <Route path="/" element={<Navigate to="UserDashboard" replace />} />
          ) : role == 'manager' ? (
            <Route path="/" element={<Navigate to="ManagerDashboard" replace />} />
          )
          
          : (
            <Route path="/" element={<Navigate to="UserDashboard" replace />} />
          )}
          {/* <Route path="/" element={<Navigate to="dashboard" replace />} /> */}
        </Routes>
      </Suspense>
    </CContainer>
  )
}

export default React.memo(AppContent)
