import React from 'react';
import './MyGPT.scss';

class CustGpt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText: '',
      outputText: '',
      cs: ''
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  

  handleInputChange(event) {
    this.setState({inputText: event.target.value});
  }

  handleSubmit(event) {
    // You can replace this with your own logic to handle the input text
    this.setState({outputText: 'you: ' + this.state.inputText});
    this.setState({cs: 'FusionGPT: coming soon...'});
    this.setState({inputText: ''});
    event.preventDefault();
  }

  render() {
    return (
      <div  style={{marginLeft: 'auto', marginRight: 'auto', width: '100%', height:"100%"}}>
        <div className="mygpt">
        <div className="mygpt-header">
          <h2>FusionGPT</h2>
        </div>
        <div className="mygpt-body">
          <div className="mygpt-output" style={{height: "55vh"}}>
            <p>{this.state.outputText}</p>
            <p>{this.state.cs}</p>
          </div>
          <form onSubmit={this.handleSubmit} className="mygpt-input">
            <input type="text" value={this.state.inputText} onChange={this.handleInputChange} placeholder="Type your message here"/>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
      </div>
    );
  }
}

export default CustGpt;