import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput, CForm, CFormLabel, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CDropdownDivider } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilStar, cilPencil } from '@coreui/icons'
import { api } from 'src/configs'
import moment from 'moment'


const TaskProfile = () => {
    const [item, setItem] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [deadline, setDealine] = useState("");
    const [end_time, setEndTime] = useState("");
    const [completion, setCompletion] = useState("");
    const [score, setScore] = useState(0);
    const [id, setID] = useState(null)
    const currentDate = moment();
    const formattedDate = currentDate.format('YYYY-MM-DD');
    const currentTime = new Date();

    const [editMode, setEditMode] = useState(false);
    const [newName, setNewName] = useState("");
    const [newDescription, setNewDescription] = useState("");



    const isAfter = (timeString) => {
      if (!timeString) {
        return false;
      } 
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();
      const currentSecond = currentTime.getSeconds();
  
      const [variableHour, variableMinute, variableSecond] = timeString.split(':');
  
      if (
        currentHour > parseInt(variableHour, 10) ||
        (currentHour === parseInt(variableHour, 10) && currentMinute > parseInt(variableMinute, 10)) ||
        (currentHour === parseInt(variableHour, 10) &&
          currentMinute === parseInt(variableMinute, 10) &&
          currentSecond > parseInt(variableSecond, 10))
      ) {
        return true;
      }
  
      return false;
    };

    useEffect(() => {
      console.log(localStorage.getItem('f_emp_id'))
       // make a get request to api to get tasks by id and send localstorage of f_emp_id as query using fetch
        fetch(api + 'getTaskById?id=' + localStorage.getItem('f_t_id'), {

            method: 'GET',
            headers: {  
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
          console.log(data)
            setItem(data);
            setName(data[0].name);
            setDescription(data[0].description);
            setDealine(data[0].end_date);
            setEndTime(data[0].end_time);
            setCompletion(data[0].completion);
            setScore(data[0].score);
            setID(data[0].id)
            setNewName(data[0].name);
            setNewDescription(data[0].description);
            console.log(deadline, currentDate.format('MMMM Do YYYY, h:mm:ss a'))

        }
        )
        .catch((error) => {
            console.error('Error:', error);
        }
        );


      }, []);
  
      const onSetTaskAsCompleteClicked = () => {
        // post request to set task as complete send id in body
        fetch(api + 'setTaskComplete', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                id: localStorage.getItem('f_t_id')
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data.res)
            if(data.res == "Task Added"){
              window.location.reload();
            }
        }
        ) 
      }

    const onScoreTaskClicked = (score) => {
        // post request to set task as complete send id in body
        fetch(api + 'scoreTask', {
    
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                id: localStorage.getItem('f_t_id'),
                score: score
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data.res)
            if(data.res == "Task Added"){
                window.location.reload();
            }
        }
        )
    }

    const onRemoveTaskClicked = () =>{
      fetch(api + 'deleteTask', {
    
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            id: id
        })
    })
    .then(response => response.json())
    .then(data => {
        console.log(data.res)
        if(data.res == "Task Deleted"){
            window.location.href="Tasks"
        }
    }
    )
    }

    function isDateAfter(dateString) {
      const currentDate = new Date();
      const givenDate = new Date(dateString);
    
      // Get the year, month, and day components of the dates
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();
      const givenYear = givenDate.getFullYear();
      const givenMonth = givenDate.getMonth();
      const givenDay = givenDate.getDate();
    
      // Compare the year, month, and day components
      if (currentYear > givenYear) {
        return true;
      } else if (currentYear === givenYear) {
        if (currentMonth > givenMonth) {
          return true;
        } else if (currentMonth === givenMonth) {
          if (currentDay > givenDay) {
            return true;
          }
        }
      }
    
      return false;
    }

    function formatDateToYearMonthDay(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
    
      return `${year}-${month}-${day}`;
    }

    const toggleEditMode = () => {
      setEditMode(!editMode);
    };

    const onSaveClicked = () => {
      fetch (api + 'updateTask', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body : JSON.stringify({
          id: id,
          name: newName,
          description: newDescription,
        })
      })
      .then(response => response.json())
      .then(data => {
        console.log(data.res)
        if(data.res == "Task Updated"){
          // window.location.reload();
        }
      }
      )

    }


    console.log(isAfter(end_time))
    console.log(currentDate.isAfter(deadline))
    console.log(deadline.substring(0, 10 == formattedDate) ? "true" : "false")
    console.log(deadline.substring(0, 10))
    console.log(formattedDate)

  return (
    <CRow>
      <CCol >
        <CCard>
          <CCardHeader className='display-6'>
            Task info
            <CButton color="secondary" onClick={toggleEditMode} className="ml-auto" style={{marginLeft: '10px'}}>
            <CIcon icon={cilPencil} />
          </CButton>
          {
            editMode && <CButton color='danger' style={{marginLeft: '15px'}} onClick={() =>{setEditMode(false)}}>cancel</CButton>
          }
          {
            editMode && <CButton color='success' onClick={onSaveClicked} style={{marginLeft: '5px'}}>save</CButton>
          }
          </CCardHeader>
          <CCardBody>
          <div className="form-group mb-4">
              <CFormLabel htmlFor="name" style={{fontWeight: "bold"}}>Task Name</CFormLabel>
              {/* <p id="name">{name}</p> */}
              {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                  />
                ) : (
                  <p id="name">{name ? name : 'N/A'}</p>
                )}
            </div><br/>
            <div className="form-group mb-4">
              <CFormLabel htmlFor="description" style={{fontWeight: "bold"}}>Task Description</CFormLabel> 
              {/* <p id="description">{description}</p> */}
              {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={newDescription}
                    onChange={(e) => setNewDescription(e.target.value)}
                  />
                ) : (
                  <p id="name">{description ? description : 'N/A'}</p>
                )}
            </div><br/>
            <div className="form-group mb-4">
              <CFormLabel htmlFor="deadline" style={{fontWeight: "bold"}}>Deadline{(isDateAfter(deadline) || (formatDateToYearMonthDay(currentTime) == formatDateToYearMonthDay(new Date(deadline)) && isAfter(end_time))) && completion == 0 ? (< > (overdue)</>) : null}</CFormLabel> 
              <p id="deadline">{deadline.substring(0, 10)}</p>
            </div><br/>
            <div className="form-group mb-4">
              <CFormLabel htmlFor="completion" style={{fontWeight: "bold"}}>Deadline Time</CFormLabel>
              <p id="completion">{end_time}</p>
              {/* <CButton size='sm' color="success" onClick={onSetTaskAsCompleteClicked}>set task as complete</CButton> */}
            </div><br/>
            <div className="form-group mb-4">
              <CFormLabel htmlFor="completion" style={{fontWeight: "bold"}}>Completion</CFormLabel>
              <p id="completion">{completion == 0? "Incomplete" : "Complete"}</p>
              {/* <CButton size='sm' color="success" onClick={onSetTaskAsCompleteClicked}>set task as complete</CButton> */}
            </div><br/>
            
            <div className="form-group mb-0">
              <CFormLabel htmlFor="score" style={{fontWeight: "bold"}}>Rating</CFormLabel>
              <p id="score">{score == null? "unscored" : <>{Array.from({length: score}, (_, index) => <CIcon key={index} icon={cilStar}/> )}</>}</p>
              <CDropdown variant="btn-group">
                <CDropdownToggle color={'primary'}>Rate Task</CDropdownToggle>
                <CDropdownMenu>
                    <CDropdownItem href="#"></CDropdownItem>
                    <CDropdownItem onClick={() =>{onScoreTaskClicked(1)}}><CIcon icon={cilStar}/></CDropdownItem>
                    <CDropdownItem onClick={() =>{onScoreTaskClicked(2)}}><CIcon icon={cilStar}/><CIcon icon={cilStar}/></CDropdownItem>
                    <CDropdownItem onClick={() =>{onScoreTaskClicked(3)}}><CIcon icon={cilStar}/><CIcon icon={cilStar}/><CIcon icon={cilStar}/></CDropdownItem>
                    <CDropdownItem onClick={() =>{onScoreTaskClicked(4)}}><CIcon icon={cilStar}/><CIcon icon={cilStar}/><CIcon icon={cilStar}/><CIcon icon={cilStar}/></CDropdownItem>
                    <CDropdownItem onClick={() =>{onScoreTaskClicked(5)}}><CIcon icon={cilStar}/><CIcon icon={cilStar}/><CIcon icon={cilStar}/><CIcon icon={cilStar}/><CIcon icon={cilStar}/></CDropdownItem>
                    <CDropdownDivider />
                    <CDropdownItem href="#">remove score (coming soon)</CDropdownItem>
                </CDropdownMenu>
                </CDropdown>
                <CButton color="danger" style={{color: "white", float: 'right'}} onClick={onRemoveTaskClicked}>Remove task</CButton>
            </div><br/>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default TaskProfile
