import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput, CForm, CFormLabel, CModal, CModalHeader, CModalTitle, CModalBody, CListGroup, CListGroupItem  } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import { api } from 'src/configs'

const columns = [
    {
      key: 'id',
      label: 'id',
      _props: { scope: 'col' },
    },
    {
      key: 'Full_Name',
      label: 'Full Name',
      _props: { scope: 'col' },
    },
    {
      key: 'Email',
      label: 'Email',
      _props: { scope: 'col' },
    },
    {
        key: 'Phone',
        label: 'Phone',
        _props: { scope: 'col' },
      },
      {
        key: 'Job_Position',
        label: 'Job Position',
        _props: { scope: 'col' },
      },
      {
        key: 'Salary',
        label: 'Salary',
        _props: { scope: 'col' },
      },
    //   {
    //     key: 'Action',
    //     label: 'Action',
    //     _props: { scope: 'col' },
    //   },
  ]



const AddTask = () => {
    const [name, setName] = useState("");
    const [description, setDesciption] = useState("");
    const [id, setId] = useState("");
    const [selectedDate, setSelectedDate] = useState(moment());
    const [value, onChange] = useState('10:00');
    const [added_by, setAdded_by] = useState(localStorage.getItem('f_emp_id'));

    const [emps, setEmps] = useState([])
    const [items, setItems] = useState([])
    const [visible, setVisible] = useState(false)

    // console.log(selectedDate.format('YYYY-MM-DD'))

    useEffect (() => {
      fetch(`${api}getUsers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log("data : ", data)
          setEmps(data)
          const temp = []
          data.forEach((element) => {
            // console.log('element', element)
            if(element.emp_id){
              temp.push({ username: element.username, id: element.emp_id })
            }
          })
          setItems(temp)
          // console.log('items', items)
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }, [])

    function handleDateChange(date) {
      setSelectedDate(moment(date));
      console.log(selectedDate.format('YYYY-MM-DD'))
    }
    

    // console.log(name, description, id)

    const onAddEmployeeClicked = async() =>{
            await fetch(api + 'addTask', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({name:name, description:description, emp_id:id, end_date:selectedDate.format('YYYY-MM-DD'), start_date:moment().format('YYYY-MM-DD'), end_time:value, added_by: added_by})
        }).then(() => {
        console.log('Data uploaded successfully!');
        alert("Added successfully")
        window.location.reload();
        }).catch((error) => {
        console.log('Error: ', error);
        });

    }

    const onSelectEmp = (item) => {
      // console.log(item)
      setId(item.id)
  }


  return (
    <CRow>
<CModal alignment="center" scrollable visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Select an employee to add lead to ...</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CListGroup>
                {items.map((item, index) => (
                  <CListGroupItem key={index} onClick={() => onSelectEmp(item)} component="a">
                  {item.username}
                </CListGroupItem> 
                ))}
              </CListGroup>
            </CModalBody>

          </CModal>

      <CCol xs={12}>
        <h1>Add Task</h1><br/>
        <CForm id="add-employee-form">
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Name</CFormLabel>
                    <CFormInput type="text" id="fn" placeholder="" onChange={(event)=>{setName(event.target.value)}}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Description</CFormLabel>
                    <CFormInput type="text" id="phone" placeholder="" onChange={(event)=>{setDesciption(event.target.value)}}/>
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Employee</CFormLabel><br/>
                    <CButton size='sm' onClick={() =>{setVisible(!visible)}}>select employee</CButton><br/>
                    <CFormInput value={id} type="text" id="ssn" placeholder="" onChange={(event)=>{setId(event.target.value)}}/>
                </div>
                <div>
                <CFormLabel>Deadline: {selectedDate.format('MMMM Do YYYY')}</CFormLabel>
                  <Calendar value={selectedDate.toDate()} onChange={handleDateChange} />
                  <br/>
                  Deadline Time : <TimePicker onChange={onChange} value={value} />
                </div> <br></br>


                <CButton color="primary" variant="outline" onClick={onAddEmployeeClicked}>Add Task</CButton>
                </CForm>
                <br></br>
      </CCol>
    </CRow>
  )
}

export default AddTask
