import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput, CForm, CFormLabel, CModal, CModalBody, CModalHeader, CModalFooter, CModalTitle, CListGroup, CListGroupItem } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'
import moment from 'moment'


const MeetingProfile = () => {
    const [item, setItem] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [location, setLocation] = useState("");
    const [completion, setCompletion] = useState("");
    const [score, setScore] = useState(0);

    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);

    const [selectedEmps, setSelectedEmps] = useState([]);
    const [emps, setEmps] = useState([]);
    const [participants, setParticipants] = useState([]);



    useEffect(() => {
       // make a get request to api to get tasks by id and send localstorage of f_emp_id as query using fetch
        fetch(api + 'getMeetingById?id=' + localStorage.getItem('f_m_id'), {

            method: 'GET',
            headers: {  
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
          console.log(data)
            setItem(data);
            setName(data[0].name);
            setDescription(data[0].description);
            setDate(data[0].date);
            setTime(data[0].time);
            setLocation(data[0].location);

        }
        )
        .catch((error) => {
            console.error('Error:', error);
        }
        );

        fetch(api + 'getMeetingEmployees?id=' + localStorage.getItem('f_m_id'), {

          method: 'GET',
          headers: {  
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
      })
      .then(response => response.json())
      .then(data => {
        console.log("loading participants : ", data)
        setParticipants(data);

      }
      )
      .catch((error) => {
          console.error('Error:', error);
      }
      );

      fetch(api + 'getUsers', {

        method: 'GET',
        headers: {  
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    })
    .then(response => response.json())
    .then(data => {
      console.log(data)
      setEmps(data);

    }
    )
    .catch((error) => {
        console.error('Error:', error);
    }
    );


      }, []);
  
      const onSetTaskAsCompleteClicked = () => {
        // post request to set task as complete send id in body
        fetch(api + 'setTaskComplete', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                id: localStorage.getItem('f_t_id')
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data.res)
            if(data.res == "Task Added"){
              window.location.reload();
            }
        }
        ) 
      }

      const addEmployeeToMeeting = (id) => {
        // post request to add employee to meeting send id in body
        fetch(api + 'addEmpToMeeting', {

            method: 'POST',
            headers: {

                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({

                emp_id: id,
                meeting_id: localStorage.getItem('f_m_id')
            })
        })
        .then(response => response.json())
        .then(data => {

            console.log(data.res)
            if(data.res == "Employee Added to Meeting"){
              console.log("added")
              window.location.reload();
            }
        }
        )

        }

        const removeEmployeeFromMeeting = (id) => {
          fetch(api + 'removeEmpFromMeeting', {

            method: 'DELETE',
            headers: {

                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                emp_id: id,
                meeting_id: localStorage.getItem('f_m_id')
            })
        })
        .then(response => response.json())
        .then(data => {

            console.log(data.res)
            if(data.res == "Employee Removed from Meeting"){
              console.log("removed")
              window.location.reload();
            }
        }
        )

        }


        console.log("partici",participants)
  return (
    <CRow>
        <CModal alignment="center" scrollable visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>View Participants</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CListGroup>
                {participants.map((item, index) => (
                  <CListGroupItem key={index}>
                  {item.username}
                  <CButton color="danger" onClick={() =>{ removeEmployeeFromMeeting(item.emp_id) }} style={{float: 'right'}}>Remove</CButton>
                  {/* {
                    selectedEmps.includes(item) ?
                    <CButton color="danger" onClick={() => {
                        setSelectedEmps(selectedEmps.filter((emp) => emp !== item))
                        }
                    }
                    style={{float: "right"}}>Remove</CButton>
                    :
                    <CButton color="primary" onClick={() => {
                        setSelectedEmps([...selectedEmps, item])
                        }
                    }
                    style={{float: "right"}}>Add</CButton>

                  } */}
                </CListGroupItem> 
                ))}
              </CListGroup>
            </CModalBody>
            <CModalFooter>
                <CButton color="danger" onClick={() => setVisible(false)}>Close</CButton>
                {/* <CButton color="success" onClick={() =>{ }}>Confirm</CButton> */}
            </CModalFooter>

          </CModal>

          <CModal alignment="center" scrollable visible={visible2} onClose={() => setVisible2(false)}>
            <CModalHeader>
              <CModalTitle>Edit Participants</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CListGroup>
              <CListGroup>
                {emps.map((item, index) => (
                  !participants.some(participant => participant.username.includes(item.username)) && (
                    <CListGroupItem key={index}>
                      {item.username}
                      <CButton
                        color="primary"
                        style={{ float: "right" }}
                        onClick={() => addEmployeeToMeeting(item.emp_id)}
                      >
                        add
                      </CButton>
                    </CListGroupItem>
                  )
                ))}
              </CListGroup>
              </CListGroup>
            </CModalBody>
            <CModalFooter>
                <CButton color="danger" onClick={() => setVisible2(false)}>Close</CButton>
                {/* <CButton color="success" onClick={() =>{ }}>Confirm</CButton> */}
            </CModalFooter>

          </CModal>
      <CCol >
        <CCard>
          <CCardHeader className='display-6'>
            Meeting Info
          </CCardHeader>
          <CCardBody>
            <div className="form-group mb-4">
              <CFormLabel htmlFor="name" style={{fontWeight: "bold"}}>Meeting Name</CFormLabel>
              <p id="name">{name}</p>
            </div><br/>
            <div className="form-group mb-4">
              <CFormLabel htmlFor="description" style={{fontWeight: "bold"}}>Meeting Description</CFormLabel> 
              <p id="description">{description}</p>
            </div><br/>
            <div className="form-group mb-4">
              <CFormLabel htmlFor="deadline" style={{fontWeight: "bold"}}>Date</CFormLabel> 
              <p id="deadline">{date.substring(0, 10)}</p>
              <p id="deadline">{time}</p>
            </div><br/>
            <div className="form-group mb-4">
              <CFormLabel htmlFor="deadline" style={{fontWeight: "bold"}}>Participants</CFormLabel><br/><br/>
              <CButton color="dark" className="px-4" onClick={() =>{setVisible(!visible)}}>View Participants</CButton><br/><br/>
              <CButton color="primary" className="px-4" onClick={() =>{setVisible2(!visible2)}}>Edit Participants</CButton>
            </div>

            
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default MeetingProfile
