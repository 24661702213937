import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput, CForm, CFormLabel } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'
import moment from 'moment'


const TaskProfile = () => {
    const [item, setItem] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [deadline, setDealine] = useState("");
    const [end_time, setEndTime] = useState("");
    const [completion, setCompletion] = useState("");
    const [score, setScore] = useState(0);
    const currentDate = moment();
    const currentTime = new Date();

    useEffect(() => {
      console.log(localStorage.getItem('f_emp_id'))
       // make a get request to api to get tasks by id and send localstorage of f_emp_id as query using fetch
        fetch(api + 'getTaskById?id=' + localStorage.getItem('f_t_id'), {

            method: 'GET',
            headers: {  
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
          console.log(data)
            setItem(data);
            setName(data[0].name);
            setDescription(data[0].description);
            setDealine(data[0].end_date);
            setEndTime(data[0].end_time);
            setCompletion(data[0].completion);
            setScore(data[0].score);
            console.log(deadline, currentDate.format('MMMM Do YYYY, h:mm:ss a'))

        }
        )
        .catch((error) => {
            console.error('Error:', error);
        }
        );


      }, []);
  
      const onSetTaskAsCompleteClicked = () => {
        // post request to set task as complete send id in body
        fetch(api + 'setTaskComplete', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                id: localStorage.getItem('f_t_id')
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data.res)
            if(data.res == "Task Added"){
              window.location.reload();
            }
        }
        ) 
      }

      const currentFormattedTime = currentTime.toLocaleTimeString('en-US', {
        hour12: false,
      });

      function isDateAfter(dateString) {
        const currentDate = new Date();
        const givenDate = new Date(dateString);

        console.log("isDateAfter summary: ", currentDate, givenDate, currentDate > givenDate)
      
        // Get the year, month, and day components of the dates
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();
        const givenYear = givenDate.getFullYear();
        const givenMonth = givenDate.getMonth();
        const givenDay = givenDate.getDate();
      
        // Compare the year, month, and day components
        if (currentYear > givenYear) {
          return true;
        } else if (currentYear === givenYear) {
          if (currentMonth > givenMonth) {
            return true;
          } else if (currentMonth === givenMonth) {
            if (currentDay > givenDay) {
              return true;
            }
          }
        }
      
        return false;
      }
  
      function formatDateToYearMonthDay(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
      
        return `${year}-${month}-${day}`;
      }

      const isAfter = (timeString) => {
        if (timeString === null){
          return false
        }
        console.log("timestring:",timeString)
        const currentHour = currentTime.getHours();
        const currentMinute = currentTime.getMinutes();
        const currentSecond = currentTime.getSeconds();
    
        const [variableHour, variableMinute, variableSecond] = timeString.split(':');
    
        if (
          currentHour > parseInt(variableHour, 10) ||
          (currentHour === parseInt(variableHour, 10) && currentMinute > parseInt(variableMinute, 10)) ||
          (currentHour === parseInt(variableHour, 10) &&
            currentMinute === parseInt(variableMinute, 10) &&
            currentSecond > parseInt(variableSecond, 10))
        ) {
          return true;
        }
    
        return false;
      };

      console.log("date == cur date : ", formatDateToYearMonthDay(currentTime) == formatDateToYearMonthDay(new Date(deadline)))

  return (
    <CRow>
      <CCol >
        <CCard>
          <CCardHeader className='display-6'>
            Task info
          </CCardHeader>
          <CCardBody>
            <div className="form-group mb-4">
              <CFormLabel htmlFor="name" style={{fontWeight: "bold"}}>Task Name</CFormLabel>
              <p id="name">{name}</p>
            </div><br/>
            <div className="form-group mb-4">
              <CFormLabel htmlFor="description" style={{fontWeight: "bold"}}>Task Description</CFormLabel> 
              <p id="description">{description}</p>
            </div><br/>
            <div className="form-group mb-4">
              <CFormLabel htmlFor="deadline" style={{fontWeight: "bold"}}>Deadline{(isDateAfter(deadline) || (formatDateToYearMonthDay(currentTime) == formatDateToYearMonthDay(new Date(deadline)) && isAfter(end_time))) && completion == 0 ? (< > (overdue)</>) : null}</CFormLabel> 
              <p id="deadline">{deadline.substring(0, 10)}</p>
              <p id="deadline">{end_time}</p>
            </div><br/>

            <div className="form-group mb-4">
              <CFormLabel htmlFor="completion" style={{fontWeight: "bold"}}>Completion</CFormLabel>
              <p id="completion">{completion == 0? "Incomplete" : "Complete"}</p>
              {/* <CButton size='sm' color="success" onClick={onSetTaskAsCompleteClicked}>set task as complete</CButton> */}
              {
                // completion == 0 && currentDate.isBefore(deadline) ? <CButton size='sm' color="success" onClick={onSetTaskAsCompleteClicked}>set task as complete</CButton> : null
                completion == 0 && !((isDateAfter(deadline) || (formatDateToYearMonthDay(currentTime) == formatDateToYearMonthDay(new Date(deadline)) && isAfter(end_time)))) ? <CButton size='sm' color="success" onClick={onSetTaskAsCompleteClicked}>set task as complete</CButton> : null
                // completion == 0 ? <CButton size='sm' color="success" onClick={onSetTaskAsCompleteClicked}>set task as complete</CButton> : null


              }
            </div><br/>
            <div className="form-group mb-0">
              <CFormLabel htmlFor="score" style={{fontWeight: "bold"}}>Score</CFormLabel>
              <p id="score">{score == null? "unscored" : score}</p>
            </div><br/>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default TaskProfile
