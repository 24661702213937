import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

import { logoNegative } from 'src/assets/brand/logo-negative'
import { sygnet } from 'src/assets/brand/sygnet'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'
import navigation2 from '../views/User/nav2/nav2'
import navigation3 from '../views/Manager/nav3/nav3'
import navigation4 from '../views/User/SecNav/Nav4'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const r = localStorage.getItem('f_role')

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      {/* <CSidebarBrand className="d-none d-md-flex" to="/">
        <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
        Fusion Logo "coming"
      </CSidebarBrand> */}
      <CSidebarNav>
        <SimpleBar style={{ maxHeight: '100%', overflowX: 'hidden' }}>
          {/* <AppSidebarNav items={navigation} /> */}
          {r == 'adm' ? (
            <>
              <AppSidebarNav items={navigation} />
            </>
          ) : r == 'manager' ? (
            <>
              <AppSidebarNav items={navigation3} />
            </>
          )
            : r == 'secratary' ? (
              <>
                <AppSidebarNav items={navigation4} />
              </>
            )
          
          
          :(
            <>
              <AppSidebarNav items={navigation2} />
            </>
          )}
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
