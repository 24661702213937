import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CFormInput, CForm, CFormLabel, CFormTextarea, CModal, CModalHeader, CModalTitle, CModalBody, CListGroup, CListGroupItem, CModalFooter  } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell } from '@coreui/icons'
import { DocsExample } from 'src/components'
import { api } from 'src/configs'
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';


const AddReminder = () => {
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [added_by, setAb] = useState("");
    const [details, setDetails] = useState("");
    const [selectedDate, setSelectedDate] = useState(moment());
    const [value, onChange] = useState('10:00');
    const [clients, setClients] = useState([]);
    const [visible, setVisible] = useState(false)
    const [items, setItems] = useState([])
    const [client, setClient] = useState(null)

    const [clients_filter, setClientsFilter] = useState("")

    useEffect (() => {
        fetch(`${api}getClientsNameId`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("data : ", data)
              setItems(data)

            })
            .catch((error) => {
              console.error('Error:', error)
            })
        }, [])


    const onAddReminderClicked = () =>{
      // if (client === ""){
      //   alert("Please select a client")
      //   return
      // }
            fetch(api + 'addReminder', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',

        },
        body: JSON.stringify({
            name: name,
            emp_id: localStorage.getItem('f_emp_id'),
            description: details,
            client_id: client,
            date:selectedDate.format('YYYY-MM-DD'), 
            time:value

        })
    }).then((res) => {
        console.log(res)
        if(res.status === 200){
            alert("Reminder Added Successfully")
            setName("")
            setType("")
            setDetails("")

        }
        else{
            alert("Reminder Was Not Added")
        }
    })
    }

    const onSelectClientClicked = (item) =>{
        setClient(item.id)
    }

    function handleDateChange(date) {
        setSelectedDate(moment(date));
        console.log(selectedDate.format('YYYY-MM-DD'))
      }

      console.log(selectedDate.format('YYYY-MM-DD'))

  return (
    <CRow>
      <CCol xs={12}>
        <h1>Add Reminder</h1><br/>

        <CModal alignment="center" scrollable visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Select a Client to make an order to ...</CModalTitle>
            </CModalHeader>
            <CFormInput type="text" placeholder='search clients' onChange={(e) => {setClientsFilter(e.target.value)}}/>
            <CModalBody>
              <CListGroup>
                {items.map((item, index) => (
                  (item.full_name.toLowerCase().includes(clients_filter.toLowerCase()) || item.id.toString().includes(clients_filter)) &&
                  <CListGroupItem key={index} onClick={() => onSelectClientClicked(item)} component="a">
                  {'ID:' + item.id + ', Name: ' +  item.full_name}
                </CListGroupItem> 
                ))}
              </CListGroup>
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => {setVisible(false); setClientsFilter("")}}>Cancel</CButton>
                <CButton color="secondary" onClick={() => {setVisible(false); setClientsFilter("")}}>Add client</CButton>
            </CModalFooter>

          </CModal>
    
        <CForm id="add-employee-form">
        <CCard>
            <CCardHeader><h4>Reminder Details </h4></CCardHeader>
            <CCardBody>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Name</CFormLabel>
                    <CFormInput type="text" id="fn" placeholder="" onChange={(event)=>{setName(event.target.value)}} value={name}/>
                </div>
                {/* <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">type</CFormLabel>
                    <CFormInput type="text" id="jp" placeholder="" onChange={(event)=>{setType(event.target.value)}} value={type}/>
                </div> */}
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Client ID</CFormLabel>
                    <CButton style={{marginLeft: "20px"}} onClick={() =>{setVisible(!visible)}}>select client </CButton>
                    <CFormInput type="text" id="emaial" value={client}/>
                </div>
                <CFormTextarea
                    id="exampleFormControlTextarea1"
                    label="Details "
                    rows={6}
                    // text="Must be 8-20 words long."
                    onChange={(event)=>{setDetails(event.target.value)}}
                    value={details}
                ></CFormTextarea>
                <br/>
                <div >
                <CFormLabel>Deadline: {selectedDate.format('MMMM Do YYYY')}</CFormLabel>
                  <Calendar value={selectedDate.toDate()} onChange={handleDateChange} />
                  <br/>
                  Deadline Time : <TimePicker onChange={onChange} value={value} />
                </div> <br></br>


                </CCardBody>     
                </CCard><br/>
                


                <CButton color="primary" variant="outline" onClick={onAddReminderClicked}>Add Reminder</CButton>
                </CForm>
                
                <br></br>
      </CCol>
    </CRow>
  )
}

export default AddReminder
