import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormInput, CForm, CFormLabel, CFormTextarea, CModal, CModalBody, CModalHeader, CModalTitle, CListGroup, CListGroupItem, CModalFooter  } from '@coreui/react'
import { compareTwoStrings } from 'string-similarity';
import { api } from 'src/configs'
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';


const AddMeeting = () => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [location, setLocation] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [selectedDate, setSelectedDate] = useState(moment());
    const [value, onChange] = useState('10:00');

    const [visible, setVisible] = useState(false);
    const [emps, setEmps] = useState([]);
    const [empNames, setEmpNames] = useState([]);

    const [selectedEmps, setSelectedEmps] = useState([]);



    useEffect (() => {
        fetch(api + 'getEmployees', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            if(res.status === 200){
                const names = []
                res.json().then((data) => {
                    data.forEach(element => {
                        console.log(element)
                        names.push(element.full_name)
                    });
                    setEmpNames(names)
                    setEmps(data)
                })
            }
        })
    } , [])

    function handleDateChange(date) {
        setSelectedDate(moment(date));
        console.log(selectedDate.format('YYYY-MM-DD'))
      }

    const onAddMeetingClicked = async() => {
        if (name === "" ){
            alert("Please add a name to the meeting")
            return;
        }
        if (description === "" ){
            alert("Please add a description to the meeting")
            return;
        }
        fetch(api + 'addMeeting', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                description: description,
                location: location,
                date: selectedDate.format('YYYY-MM-DD'),
                time: value
            })
        }).then((res) => {
            if(res.status === 200){
               window.location.reload();
            }
        })

        // let meeting_id = null

        // await fetch(api + 'getMeetingByDateAndName', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({
        //         name: name,
        //         date: selectedDate.format('YYYY-MM-DD'),
        //     })
        // }).then((res) => {
        //     if(res.status === 200){
        //         res.json().then((data) => {
        //             console.log("meeting id : ", data[0].id)
        //             meeting_id = data[0].id
        //             if (meeting_id !== null){
        //                 console.log("started adding emps to meeting")
        //                 selectedEmps.forEach(element => {
        //                     fetch(api + 'addEmpToMeeting', {
        //                         method: 'POST',
        //                         headers: {
        //                             'Content-Type': 'application/json',
        //                         },
        //                         body: JSON.stringify({
        //                             meeting_id: meeting_id,
        //                             emp_id: element.id
        //                         })
        //                     }).then((res) => {
        //                         if(res.status === 200){
        //                             alert("employee " + element.full_name + " added to meeting " + name)
        //                         }
        //                     })
        //                 });
                
        //             }
        //         })
        //     }
        // })





        // if (meeting_id !== null){
        //     selectedEmps.forEach(element => {
        //         fetch(api + 'addEmpToMeeting', {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': 'application/json',
        //             },
        //             body: JSON.stringify({
        //                 meeting_id: name,
        //                 emp_id: element.id
        //             })
        //         }).then((res) => {
        //             if(res.status === 200){
        //                 alert("employee " + element.full_name + " added to meeting " + name)
        //             }
        //         })
        //     });
    
        // }
    }

    console.log(empNames)
    console.log(selectedEmps)

  return (
    <CRow>
      <CCol xs={12}>
        <h1>Add Meeting</h1><br/>

        <CModal alignment="center" scrollable visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Add Participants</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CListGroup>
                {emps.map((item, index) => (
                  <CListGroupItem key={index}>
                  {item.full_name}
                  {
                    selectedEmps.includes(item) ?
                    <CButton color="danger" onClick={() => {
                        setSelectedEmps(selectedEmps.filter((emp) => emp !== item))
                        }
                    }
                    style={{float: "right"}}>Remove</CButton>
                    :
                    <CButton color="primary" onClick={() => {
                        setSelectedEmps([...selectedEmps, item])
                        }
                    }
                    style={{float: "right"}}>Add</CButton>

                  }
                </CListGroupItem> 
                ))}
              </CListGroup>
            </CModalBody>
            <CModalFooter>
                <CButton color="dark" onClick={() => setVisible(false)}>Close</CButton>
                <CButton color="dark" onClick={() =>{ }}>Complete</CButton>
            </CModalFooter>

          </CModal>
    
        <CForm id="add-employee-form">
        <CCard>
            <CCardHeader><h3>Meeting Details </h3></CCardHeader>
            <CCardBody>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Name<span className="text-danger">*</span></CFormLabel>
                    <CFormInput type="text" id="fn" onChange={(event)=>{setName(event.target.value)}} value={name} required/>
                </div>
                <CFormTextarea
                    id="exampleFormControlTextarea1"
                    label="More info "
                    rows={6}
                    // text="Must be 8-20 words long."
                    onChange={(event)=>{setDescription(event.target.value)}}
                    value={description}
                ></CFormTextarea>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Location</CFormLabel>
                    <CFormInput type="text" id="ssn" onChange={(event)=>{setLocation(event.target.value)}} value={location}/>
                </div>
                
                <CFormLabel>Date: {selectedDate.format('MMMM Do YYYY')}</CFormLabel>
                  <Calendar value={selectedDate.toDate()} onChange={handleDateChange} />
                  <br/>
                  Time : <TimePicker onChange={onChange} value={value} />
                  <br></br><br></br>
                {/* <CButton color='primary' onClick={() =>{setVisible(!visible)}}>Add Participants</CButton> */}
                </CCardBody>     
                </CCard><br/>
                


                <CButton color="primary" variant="outline" onClick={onAddMeetingClicked}>Add Meeting</CButton>
                </CForm>
                
                <br></br>
      </CCol>
    </CRow>
  )
}

export default AddMeeting
