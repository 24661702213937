import React, { useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CFormTextarea,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CListGroup,
  CListGroupItem,
  CFormLabel,
} from '@coreui/react'
import { api } from 'src/configs'
import moment from 'moment'
import CIcon from '@coreui/icons-react'
import { cilBell, cilPencil } from '@coreui/icons';

const LeadProfile = () => {
  const [info, setInfo] = useState([
    {
      full_name: '',
      email: '',
      phone: '',
      address: '',
      lead_name: '',
      lead_type: '',
      lead_price: '',
      lead_date: '',
      company_name: '',
      title: '',
      description: '',
      linked_in: '',
      id: '',

    },
  ])
  const currentDate = moment()
  const [visible, setVisible] = useState(false)
  const [emps, setEmps] = useState([])
  const [items, setItems] = useState([])
  const [usrToPass, setUsrToPass] = useState(' ')

  const [editMode, setEditMode] = useState(false);
  const [UpdatedFullName, setUpdatedFullName] = useState('');
  const [UpdatedEmail, setUpdatedEmail] = useState('');
  const [UpdatedPhone, setUpdatedPhone] = useState('');
  const [UpdatedAddress, setUpdatedAddress] = useState('');
  const [UpdatedLinkedIn, setUpdatedLinkedIn] = useState('');
  const [UpdatedCompanyName, setUpdatedCompanyName] = useState('');
  const [UpdatedTitle, setUpdatedTitle] = useState('');
  const [UpdatedComments, setUpdatedComments] = useState('');


  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const updateLead = () => {
    // Create a payload object with the updated data
    const payload = {
      id: info[0].id,
      full_name: UpdatedFullName,
      email: UpdatedEmail,
      phone: UpdatedPhone,
      linked_in: UpdatedLinkedIn,
      company_name: UpdatedCompanyName,
      title: UpdatedTitle,
      comments: UpdatedComments,

    };

  
    fetch(api + 'updateClientById', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.res === 'Successfully updated lead') {
          alert('Successfully updated lead');
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  

  useEffect(() => {
    // make a GET request to the API to get client and lead info by id
    // console.log(
    //   'emp_id',
    //   localStorage.getItem('f_l_emp_id'),
    //   'client_id',
    //   localStorage.getItem('f_client_id'),
    // )
    fetch(
      `${api}getClientById?id=${localStorage.getItem('f_client_id')}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        console.log('fetched data : ', data)
        // setClient(data.client);
        // setLead(data.lead);
        setInfo(data)
        setUpdatedFullName(data[0].full_name);
        setUpdatedEmail(data[0].email);
        setUpdatedPhone(data[0].phone);
        setUpdatedAddress(data[0].address);
        setUpdatedLinkedIn(data[0].linked_in);
        setUpdatedCompanyName(data[0].company_name);
        setUpdatedTitle(data[0].title);
        setUpdatedComments(data[0].comments);

      })
      .catch((error) => {
        console.error('Error:', error)
      })

    //get users
    fetch(`${api}getUsers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        setEmps(data)
        const temp = []
        data.forEach((element) => {
          // console.log('element', element)
          if(element.emp_id){
            temp.push({ username: element.username })
          }
        })
        setItems(temp)
        // console.log('items', items)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }, [])

  const changeState = (state) => {
    fetch(
      `${api}changeLeadState?emp_id=${localStorage.getItem(
        'f_l_emp_id',
      )}&client_id=${localStorage.getItem('f_client_id')}&state=${state}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        window.location.reload(true)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const onPassLeadToSomeoneElse = () => {
    // put request to passLead pass localStorage('emp_id') as emp_id, emps.is where username = usrToPass.username as new_emp_id,  and client Id  as client_id
    console.log('usrToPass', usrToPass)
    const new_emp_id = emps.find((element) => element.username === usrToPass).emp_id
    console.log('new_emp_id', new_emp_id)
    console.log('client id', info)
    fetch(`${api}passLead`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        emp_id: localStorage.getItem('f_emp_id'),
        new_emp_id: new_emp_id,
        client_id: info[0].client_id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        window.location.href = '/Leads'
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const onSelectEmp = (row) => {
    console.log('row', row)
    setUsrToPass(row.username)
  }

  const onDeleteLead = () => {
    console.log("yes")
    fetch(`${api}deleteClient?client_id=${info[0].id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        alert("deleted")
        if(data.res == "Successfully deleted client"){
          if(localStorage.getItem('f_role') == 'manager'){
            window.location.href = '/ManagerDashboard'
          }
          if(localStorage.getItem('f_role') == 'employee'){
            window.location.href = '/UserDashboard'
          }

        }
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }


  return (
    <>
      {info && info.length > 0 ? (
        <CRow>
          <CModal alignment="center" scrollable visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
              <CModalTitle>Select an employee to pass lead to ...</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CListGroup>
                {items.map((item, index) => (
                  <CListGroupItem key={index} onClick={() => onSelectEmp(item)} component="a">
                  {item.username}
                </CListGroupItem> 
                ))}
              </CListGroup>
            </CModalBody>
            <CModalFooter>
              <div style={{ display: 'flex', alignItems: 'left' }}>
                <p style={{ marginRight: '100px' }}>Selected user: {usrToPass}</p>
              </div>
              {/* <CButton color="secondary" onClick={() => setVisible(false)}>
                              Close
                              </CButton> */}
              <CButton color="primary" onClick={onPassLeadToSomeoneElse}>
                Pass Lead
              </CButton>
            </CModalFooter>
          </CModal>
          <CCol>
          {/* <CCard>
              <CCardHeader className="display-6">
                Lead Info{' '}
                <CButton
                  size="sm"
                  style={{ position: 'absolute', right: 50, marginTop: 10 }}
                  onClick={() => setVisible(!visible)}
                >
                  pass lead to someone else
                </CButton>
              </CCardHeader>
              <CCardBody>
                <div className="form-group mb-4">
                  <label htmlFor="lead-name" style={{fontWeight: "bold"}}>Name</label>
                  <p id="lead-name">{info[0].name}</p>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="lead-type" style={{fontWeight: "bold"}}>Type</label>
                  <p id="lead-type">{info[0].type}</p>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="lead-price" style={{fontWeight: "bold"}}>Price</label>
                  <p id="lead-price">{info[0].price}</p>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="lead-price" style={{fontWeight: "bold"}}>Description</label>
                  <p id="lead-price">{info[0].description}</p>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="lead-price" style={{fontWeight: "bold"}}>State</label>
                  <p id="lead-price">
                    {info[0].state == null ? (
                      <div style={{ color: '#a1a102' }}>pending</div>
                    ) : info[0].state == false ? (
                      <div style={{ color: '#8a0000' }}>rejected</div>
                    ) : (
                      <div style={{ color: '#00ab0b' }}>accepted</div>
                    )}
                  </p>
                  <CDropdown variant="btn-group">
                    <CDropdownToggle color={'primary'}>change state</CDropdownToggle>
                    <CDropdownMenu>
                      <CDropdownItem
                        href="#"
                        onClick={() => {
                          changeState(true)
                        }}
                      >
                        Accepted (sold)
                      </CDropdownItem>
                      <CDropdownItem
                        href="#"
                        onClick={() => {
                          changeState(false)
                        }}
                      >
                        Rejected
                      </CDropdownItem>
                      <CDropdownDivider />
                      <CDropdownItem
                        href="#"
                        onClick={() => {
                          changeState(null)
                        }}
                      >
                        Pending
                      </CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>
                </div>
              </CCardBody>
            </CCard><br/> */}

            <CCard>
              <CCardHeader className="display-6">Client Info
              <CButton color="secondary" onClick={toggleEditMode} className="ml-auto" style={{marginLeft: '10px'}}>
            <CIcon icon={cilPencil} />
          </CButton>
          </CCardHeader>
              <CCardBody>
                {/* <div className="form-group mb-4">
                  <label htmlFor="client-name" style={{fontWeight: "bold"}}>Name</label>
                  <p id="client-name">{info[0].full_name}</p>
                </div> */}
                <div className="form-group mb-4">
                <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Name
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={UpdatedFullName}
                    onChange={(e) => setUpdatedFullName(e.target.value)}
                  />
                ) : (
                  <p id="name">{info[0].full_name}</p>
                )}
              </div>
              <div className="form-group mb-4">
                <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Company Name
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={UpdatedCompanyName}
                    onChange={(e) => setUpdatedCompanyName(e.target.value)}
                  />
                ) : (
                  <p id="name">{info[0].company_name}</p>
                )}
              </div>
              <div className="form-group mb-4">
                <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Title
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={UpdatedTitle}
                    onChange={(e) => setUpdatedTitle(e.target.value)}
                  />
                ) : (
                  <p id="name">{info[0].title}</p>
                )}
              </div>
              <div className="form-group mb-4">
                <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Phone
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={UpdatedPhone}
                    onChange={(e) => setUpdatedPhone(e.target.value)}
                  />
                ) : (
                  <p id="name">{info[0].phone}</p>
                )}
              </div>
              <div className="form-group mb-4">
                <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Email
                </CFormLabel>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={UpdatedEmail}
                    onChange={(e) => setUpdatedEmail(e.target.value)}
                  />
                ) : (
                  <p id="name">{info[0].email}</p>
                )}
              </div>
              <div className="form-group mb-4">
                <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Linked In
                </CFormLabel><br/>
                {editMode ? (
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={UpdatedLinkedIn}
                    onChange={(e) => setUpdatedLinkedIn(e.target.value)}
                  />
                ) : (
                  <a id="name" href={'http://' + info[0].linked_in}>{info[0].linked_in}</a>
                )}
              </div>
              <div className="form-group mb-4">
                <CFormLabel htmlFor="name" style={{ fontWeight: "bold" }}>
                  Comments on Client
                </CFormLabel>
                {editMode ? (
                  <CFormTextarea
                  id="exampleFormControlTextarea1"
                  value={UpdatedComments}
                  rows={6}
                  contentEditable={true}
                  onChange={(e) => setUpdatedComments(e.target.value)}
                  // text="Must be 8-20 words long."
                  
              ></CFormTextarea>
                ) : (
                  <CFormTextarea
                  id="exampleFormControlTextarea1"
                  value={info[0].comments}
                  rows={6}
                  contentEditable={false}
                  // text="Must be 8-20 words long."
                  
              ></CFormTextarea>
                )}
              </div>
              {editMode && (
                <>
                  <CButton color="primary" onClick={updateLead}>
                    Update Lead
                  </CButton>
                  <br />
                  <br />
                </>
              )}
                {/* <div className="form-group mb-4">
                  <label htmlFor="client-name" style={{fontWeight: "bold"}}>Title</label>
                  <p id="client-name">{info[0].title}</p>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="client-name" style={{fontWeight: "bold"}}>Company Name</label>
                  <p id="client-name">{info[0].company_name}</p>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="client-phone" style={{fontWeight: "bold"}}>Phone</label>
                  <p id="client-phone">{info[0].phone}</p>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="client-email" style={{fontWeight: "bold"}}>Email</label>
                  <p id="client-email">{info[0].email}</p>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="client-email" style={{fontWeight: "bold"}}>Linked in</label><br/>
                  <a id="client-email" href={info[0].linked_in}>{info[0].linked_in}</a>
                </div> */}
                {/* <label htmlFor="client-email" style={{fontWeight: "bold"}}>Comments on client</label><br/>
                <CFormTextarea
                    id="exampleFormControlTextarea1"
                    value={info[0].comments}
                    rows={6}
                    contentEditable={false}
                    // text="Must be 8-20 words long."
                    
                ></CFormTextarea><br/> */}
                <CButton color='success' onClick={() =>{window.location.href = `/AddOrder`}}>Add order for client</CButton><br/>
              </CCardBody>
            </CCard>{' '}
            <br />
              <CButton color='danger' onClick={onDeleteLead}>Delete Lead</CButton><br/><br/><br/>
              

          
          </CCol>
        </CRow>
      ) : (
        <div>Error: Unable to retrieve client and lead info</div>
      )}
    </>
  )
}

export default LeadProfile
