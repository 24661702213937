import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilCursor,
  cilSpeedometer,
  cilPhone,
  cilListRich,
  cilMoney,
  cilPeople,
  cilBuilding,
  cilLibrary,
  cilChatBubble,
  cilBarChart,
  cilFax,
  cilFeaturedPlaylist,
  cilBug, 
  cilReportSlash
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const _nav3 = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/ManagerDashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
//   {
//     component: CNavTitle,
//     name: 'Employees',
//   },
//   {
//     component: CNavGroup,
//     name: 'Employees',
//     to: '/Employees',
//     icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
//     items: [
//       {
//         component: CNavItem,
//         name: 'View Employees',
//         to: '/Employees',
//       },
//       {
//         component: CNavItem,
//         name: 'Add Employee',
//         to: '/AddEmployee',
//       },

//     ],
//   },
  // {
  //   component: CNavItem,
  //   name: 'View Employees',
  //   to: '/Employees',
  //   icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'Add Employee',
  //   to: '/AddEmployee',
  //   icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  // },
  {
    component: CNavTitle,
    name: 'Venues',
  },
  {
    component: CNavGroup,
    name: 'Venues',
    to: '/',
    icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'View Venues',
        to: '/Venues',
      },
      {
        component: CNavItem,
        name: 'Add Venue',
        to: '/AddVenue',
      },

    ],
  },

  {
    component: CNavTitle,
    name: 'Entertainers',
  },
  {
    component: CNavGroup,
    name: 'Entertainers',
    to: '/',
    icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Entertainers',
        to: '/Djs',
      },
      {
        component: CNavItem,
        name: 'Add Entertainer',
        to: '/AddDj',
      },

    ],
  },
  {
    component: CNavTitle,
    name: 'Photographers',
  },
  {
    component: CNavGroup,
    name: 'Photographers',
    to: '/',
    icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Photographers',
        to: '/Photographers',
      },
      {
        component: CNavItem,
        name: 'Add Photographer',
        to: '/AddPhotographer',
      },

    ],
  },
  // {
  //   component: CNavItem,
  //   name: 'Venues',
  //   to: '/Venues',
  //   icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'Add venue',
  //   to: '/addVenue',
  //   icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
  // },
  {
    component: CNavTitle,
    name: 'Leads',
  },
  {
    component: CNavGroup,
    name: 'Leads',
    to: '/',
    icon: <CIcon icon={cilPhone} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'View Leads',
        to: '/Leads',
      },
      {
        component: CNavItem,
        name: 'Add Lead',
        to: '/AddLeadAdmin',
      },

    ],
  },
  {
    component: CNavTitle,
    name: 'Orders',
  },
  {
    component: CNavGroup,
    name: 'Orders',
    to: '/',
    icon: <CIcon icon={cilFax} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'View Orders',
        to: '/Orders',
      },
      {
        component: CNavItem,
        name: 'Add Order',
        to: '/AddOrder',
      },

    ],
  },
  
      // {
      //   component: CNavItem,
      //   name: 'Leads',
      //   to: '/Leads',
      //   icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
      // },
      // {
      //   component: CNavItem,
      //   name: 'Add Lead',
      //   to: '/AddLeadAdmin',
      //   icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
      // },


  {
    component: CNavTitle,
    name: 'Tasks',
  },
  {
    component: CNavGroup,
    name: 'Tasks',
    to: '/Tasks',
    icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Tasks',
        to: '/Tasks',
      },
      {
        component: CNavItem,
        name: 'Add Task',
        to: '/AddTask',
      },
      // {
      //   component: CNavItem,
      //   name: 'Task Board',
      //   to: '/TrelloBoard',
      //   // icon: <CIcon icon={cilLibrary} customClassName="nav-icon" />,
      // },
      // {
      //   component: CNavItem,
      //   name: 'Buttons groups',
      //   to: '/buttons/button-groups',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Dropdowns',
      //   to: '/buttons/dropdowns',
      // },
    ],
  },
  {
    component: CNavTitle,
    name: 'Reminders',
  },
  {
    component: CNavGroup,
    name: 'Reminders',
    to: '/',
    icon: <CIcon icon={cilFax} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'My Reminders',
        to: '/MyReminders',
      },
      {
        component: CNavItem,
        name: 'Add Reminder',
        to: '/AddReminder',
      },

    ],
  },

  {
    component: CNavTitle,
    name: 'Meetings',
  },
  {
    component: CNavGroup,
    name: 'Meetings',
    to: '/Meetings',
    icon: <CIcon icon={cilCursor} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Meetings',
        to: '/Meetings',
      },
      {
        component: CNavItem,
        name: 'My Meetings',
        to: '/MyMeetings',
      },
      {
        component: CNavItem,
        name: 'Add Meeting',
        to: '/AddMeeting',
      },
    ],
  },
  {
    component: CNavTitle,
    name: 'Bonuses and Fines',
  }, 
  {
    component: CNavGroup,
    name: 'Bonuses and Fines',
    to: '/Tasks',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Bonuses',
        to: '/Bonuses',
      },
      {
        component: CNavItem,
        name: 'Fines',
        to: '/Fines',
      },

    ],
  },
  {
    component: CNavTitle,
    name: 'Stats',
  },  {
    component: CNavItem,
    name: 'Statistics',
    to: '/ManagerStatistics',
    icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Extras',
  },  
  {
    component: CNavItem,
    name: 'FusionGPT',
    to: '/CustGpt',
    icon: <CIcon icon={cilChatBubble} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'LeaderBoard',
    to: '/LeaderBoard',
    icon: <CIcon icon={cilFeaturedPlaylist} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'System Specific',
  },
  {
    component: CNavItem,
    name: 'System Update Notes',
    to: '/SystemUpdates',
    icon: <CIcon icon={cilBug} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Report bugs or issues',
    to: '/SystemReports',
    icon: <CIcon icon={cilReportSlash} customClassName="nav-icon" />,
  },
//   {
//     component: CNavTitle,
//     name: 'Finances',
//   },  {
//     component: CNavItem,
//     name: 'Finances',
//     to: '/Finances',
//     icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
//   },


  // {
  //   component: CNavItem,
  //   name: 'Typography',
  //   to: '/theme/typography',
  //   icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavTitle,
  //   name: 'Components',
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Base',
  //   to: '/base',
  //   icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Accordion',
  //       to: '/base/accordion',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Breadcrumb',
  //       to: '/base/breadcrumbs',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Cards',
  //       to: '/base/cards',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Carousel',
  //       to: '/base/carousels',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Collapse',
  //       to: '/base/collapses',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'List group',
  //       to: '/base/list-groups',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Navs & Tabs',
  //       to: '/base/navs',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Pagination',
  //       to: '/base/paginations',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Placeholders',
  //       to: '/base/placeholders',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Popovers',
  //       to: '/base/popovers',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Progress',
  //       to: '/base/progress',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Spinners',
  //       to: '/base/spinners',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Tables',
  //       to: '/base/tables',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Tooltips',
  //       to: '/base/tooltips',
  //     },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Buttons',
  //   to: '/buttons',
  //   icon: <CIcon icon={cilCursor} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Buttons',
  //       to: '/buttons/buttons',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Buttons groups',
  //       to: '/buttons/button-groups',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Dropdowns',
  //       to: '/buttons/dropdowns',
  //     },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Forms',
  //   icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Form Control',
  //       to: '/forms/form-control',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Select',
  //       to: '/forms/select',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Checks & Radios',
  //       to: '/forms/checks-radios',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Range',
  //       to: '/forms/range',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Input Group',
  //       to: '/forms/input-group',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Floating Labels',
  //       to: '/forms/floating-labels',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Layout',
  //       to: '/forms/layout',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Validation',
  //       to: '/forms/validation',
  //     },
  //   ],
  // },
  // {
  //   component: CNavItem,
  //   name: 'Charts',
  //   to: '/charts',
  //   icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Icons',
  //   icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'CoreUI Free',
  //       to: '/icons/coreui-icons',
  //       badge: {
  //         color: 'success',
  //         text: 'NEW',
  //       },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'CoreUI Flags',
  //       to: '/icons/flags',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'CoreUI Brands',
  //       to: '/icons/brands',
  //     },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Notifications',
  //   icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Alerts',
  //       to: '/notifications/alerts',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Badges',
  //       to: '/notifications/badges',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Modal',
  //       to: '/notifications/modals',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Toasts',
  //       to: '/notifications/toasts',
  //     },
  //   ],
  // },
  // {
  //   component: CNavItem,
  //   name: 'Widgets',
  //   to: '/widgets',
  //   icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  //   badge: {
  //     color: 'info',
  //     text: 'NEW',
  //   },
  // },
  // {
  //   component: CNavTitle,
  //   name: 'Extras',
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Pages',
  //   icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Login',
  //       to: '/login',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Register',
  //       to: '/register',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Error 404',
  //       to: '/404',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Error 500',
  //       to: '/500',
  //     },
  //   ],
  // },
  // {
  //   component: CNavItem,
  //   name: 'Docs',
  //   href: 'https://coreui.io/react/docs/templates/installation/',
  //   icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  // },
]

export default _nav3
